import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";

interface appStateTypes {
  title: string;
  selectedView: SelectedViewModel;
  isFilterDrawerOpen: boolean;
}

export const initialState = {
  title: "",
  selectedView: AnnotatableEnum.Media,
  isFilterDrawerOpen: false,
} as appStateTypes;

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedView: (state, action: PayloadAction<SelectedViewModel>) => {
      state.selectedView = action?.payload;
      sessionStorage.setItem("selectedView", action?.payload);
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action?.payload;
    },
    setIsFilterDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterDrawerOpen = action?.payload;
    },
    resetAppSlice: () => initialState,
  },
});

export const {
  setSelectedView,
  setTitle,
  setIsFilterDrawerOpen,
  resetAppSlice,
} = appSlice.actions;

export default appSlice.reducer;
