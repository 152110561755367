import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import snackbarHelper from "components/Helpers/snackbarHelperFn";
import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import _ from "lodash";
import { SendFilterComparisonModel } from "models/filter.model";

import {
  TrainingSetAttribute,
  TrainingSetModel,
  TrainingSetModelCreate,
} from "models/trainingSet.model";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";

/**
 * An endpoint to fetch all training sets
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 * @returns The training sets list
 */
export const fetchTrainingSets = async (
  dispatch?: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<TrainingSetModel[]> => {
  setIsLoading && setIsLoading(true);
  const response = await APIFetchAxios("/trainingSets").catch((error) => {
    const errorDetail = error?.response?.data?.detail;
    dispatch &&
      snackbarHelper(
        errorDetail
          ? `Training sets fetching failed: ${errorDetail}`
          : "Training sets fetching failed!",
        "error",
      );
    setIsLoading && setIsLoading(false);

    return Promise.reject(error);
  });
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to fetch a training set
 * @param params.training_set_id The training set ID
 * @param dispatch
 * @returns The training set
 */
export const fetchTrainingSet = async (
  params: { training_set_id: string },
  dispatch?: Dispatch,
): Promise<TrainingSetModel> => {
  const response = await APIFetchAxios(
    `/trainingSets/${params?.training_set_id}`,
  ).catch((error) => {
    const errorDetail = error?.response?.data?.detail;
    dispatch &&
      snackbarHelper(
        errorDetail
          ? `Training set fetching: ${errorDetail}`
          : "Training set fetching failed!",
        "error",
      );

    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to create a training set
 * @param body The body of the request for creating a training set
 * @param dispatch
 * @param setIsLoading
 * @returns A successful response
 */
export const postCreateTrainingSet = async (
  body: TrainingSetModelCreate,
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);

  const response = await APIPostWithBodyAxios("/trainingSets", body).catch(
    (error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        errorDetail
          ? `Training set creation: ${errorDetail}`
          : "Training set creation failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject(error);
    },
  );
  //Todo: rename to training set once external users can see TrainingSets
  snackbarHelper("Training data created successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to update a training set (name, user_group)
 * @param trainingSetUD The training set ID
 * @param body.name The new name of the training set
 * @param body.user_group The new user group of the training set
 * @param dispatch
 * @param setIsLoading
 * @returns The updated training set
 */
export const patchTrainingSet = async (
  trainingSetUD: string,
  body: {
    name?: string;
    user_group?: string;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<TrainingSetModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/trainingSets/${trainingSetUD}`,
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      `Training set updated failed: ${errorDetail}` ||
        "Training set updated failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper("Training set updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

export const deleteTrainingSet = async (
  params: { trainingSetID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/trainingSets/${params.trainingSetID}`)
    .then(() => {
      snackbarHelper("Training set archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Training set archive: ${errorDetail}` ||
          "Training set archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

// Helper function to get the subset_ids query from a training set training_attributes
export const getTrainingSetSubsetQuery = (
  trainingAttribute: TrainingSetAttribute,
): SendFilterComparisonModel => {
  const subsetIDsAttribute = _.find(trainingAttribute.query, [
    "attribute",
    "subset_ids",
  ]) as SendFilterComparisonModel;
  return subsetIDsAttribute;
};
