import { useAppDispatch } from "store/hooks";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import { TrainingSetAttribute } from "models/trainingSet.model";

import { ReactComponent as RemoveIcon } from "assets/close.svg";
import { removeNewTrainingSetTrainingAttributeSubset } from "store/aiManagerSlice";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import { getTrainingSetSubsetQuery } from "helpers/apis/trainingSet";

type Props = {
  trainingSetAttribute: TrainingSetAttribute;
  allSubsets: DatasetModel[];
  onlyOverview?: boolean;
};

const NewTrainingSetAttributeSubsetTable = ({
  trainingSetAttribute,
  allSubsets,
  onlyOverview = false,
}: Props) => {
  const dispatch = useAppDispatch();

  const subsetIDsQuery = getTrainingSetSubsetQuery(trainingSetAttribute);
  const subsetIDs = subsetIDsQuery?.value as string[];
  if (
    _.isUndefined(trainingSetAttribute.query) ||
    (subsetIDsQuery && subsetIDs?.length === 0)
  ) {
    return null;
  }

  const renderSubsets = () => {
    if (_.isUndefined(subsetIDsQuery)) return;

    return _.map(subsetIDs, (subsetID) => {
      const subset = allSubsets.find((subset) => subset.id === subsetID);
      if (_.isUndefined(subset)) return;

      return (
        <div key={subset.id} className="w-full flex justify-between">
          <div className="w-2/4">
            <TooltipTruncateEllipsis className="">
              {subset.name}
            </TooltipTruncateEllipsis>
          </div>
          <div className="text-sm text-paletteGray-9">
            {renderObjectCount(subsetID)} Obj.
          </div>
          {!onlyOverview && renderRemoveButton(subsetID)}
        </div>
      );
    });
  };

  const renderObjectCount = (subsetID: string) => {
    const subset = _.find(allSubsets, (subset) => subset.id === subsetID);
    if (_.isUndefined(subset)) return;

    return subset?.num_media_objects;
  };

  const renderRemoveButton = (subsetID: string) => {
    return (
      <button
        className="button-layer p-1 text-paletteGray-9 "
        onClick={() =>
          dispatch(
            removeNewTrainingSetTrainingAttributeSubset({
              attributeID: trainingSetAttribute.attribute_id,
              subsetID: subsetID,
            }),
          )
        }
      >
        <RemoveIcon className="w-2 h-2" strokeWidth={2.5} />
      </button>
    );
  };

  return (
    <div
      className="w-full p-2 bg-white rounded
    flex flex-col gap-y-1
  "
    >
      {renderSubsets()}
    </div>
  );
};

export default NewTrainingSetAttributeSubsetTable;
