import { TrainingSetModel } from "models/trainingSet.model";

import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as ArrowIcon } from "assets/arrow_right.svg";

import {
  TrainingSetManagerOverviewDialogState,
  TrainingSetManagerPopoverConfirmDialogState,
} from "Pages/AIManager/TrainingSetManager";

type Props = {
  trainingSet: TrainingSetModel;
  setRenameInputID: (id: string | null) => void;
  setConfirmDialogState: (
    newState: TrainingSetManagerPopoverConfirmDialogState,
  ) => void;
  setOverviewDialogState: (
    newState: TrainingSetManagerOverviewDialogState,
  ) => void;
};

const TrainingSetManagerTableActions = ({
  trainingSet,
  setRenameInputID,
  setConfirmDialogState,
  setOverviewDialogState,
}: Props) => {
  return (
    <div className="flex flex-row-reverse gap-x-1 items-center">
      <div
        className="p-1 cursor-pointer"
        onClick={() =>
          setOverviewDialogState({
            show: true,
            trainingSet: trainingSet,
          })
        }
      >
        <ArrowIcon className="text-paletteGray-9 w-[18px]" />
      </div>

      <div
        className="button-select-layer p-1"
        onClick={() => setRenameInputID(trainingSet?.id)}
      >
        <EditIcon className="text-paletteGray-9 w-[18px]" />
      </div>

      <div
        className="p-1 cursor-pointer"
        onClick={() =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            trainingSetID: trainingSet?.id,
          })
        }
      >
        <DeleteIcon className="text-paletteGray-9 w-[18px]" />
      </div>
    </div>
  );
};

export default TrainingSetManagerTableActions;
