import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchOneMediaAPI } from "helpers/apis";
import _, { add } from "lodash";
import { APIFetchAxios } from "../components/UtilComponents/Auth";
import {
  ExplorationAPIRequestModel,
  MediaModel,
} from "../models/exploration.model";
import { SendFilterModel } from "../models/filter.model";
import subsetQuery from "helpers/functions/subsetQuery";

export const fetchMediaData = createAsyncThunk(
  "explorationMedia/fetchMediaData",
  async (meta?: {
    runId: string;
    reqBody: ExplorationAPIRequestModel;
    subSetId: string;
    reset?: boolean;
    skipLoading?: boolean;
  }) => {
    let imgReqBody = { ...meta?.reqBody, ["dataset_id"]: meta?.runId };

    // Inject subset id as a categorical filter if "subSetId" paramter is passed
    if (meta?.subSetId !== "main_dataset") {
      const filters: SendFilterModel[] = imgReqBody?.query
        ? [...imgReqBody?.query]
        : [];
      imgReqBody = {
        ...imgReqBody,
        query: [...filters, subsetQuery(meta?.subSetId || "")],
      };
    }
    if (_.isUndefined(imgReqBody?.projection)) {
      imgReqBody = { ...imgReqBody, projection: { media_objects: false } };
    }
    const response = await APIFetchAxios(
      `/datasets/${meta?.runId}/medias`,
      imgReqBody,
    );
    return { data: response?.data, meta: meta };
  },
);

export const fetchOneMedia = createAsyncThunk(
  "explorationMedia/fetchOneMedia",
  async (meta: {
    query: { mediaId: string; datasetId: string };
    options?: { replaceCurrentMedia: boolean };
  }) => {
    const response = await fetchOneMediaAPI(
      meta?.query?.mediaId,
      meta?.query?.datasetId,
    );
    return { data: response, meta: meta };
  },
);

export interface explorationMediaStateTypes {
  data: MediaModel[];
  loading: boolean;
  error: { message: string };
  hasMore: boolean;
  skip: number;
  currentMedia: MediaModel | null;
  selectedMediaIDs: string[];
  lastSelectedMediaID: string | null;
}

const initialState = {
  data: [],
  loading: false,
  error: { message: "" },
  hasMore: true,
  skip: 0,
  currentMedia: null,
  selectedMediaIDs: [],
  lastSelectedMediaID: null,
} as explorationMediaStateTypes;

export const explorationMediaSlice = createSlice({
  name: "explorationMedia",
  initialState,
  reducers: {
    updateMediaExplorationHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action?.payload;
    },
    updateMediaExplorationSkip: (state, action: PayloadAction<number>) => {
      state.skip = action?.payload;
    },
    setMediaExplorationCurrentMedia: (
      state,
      action: PayloadAction<MediaModel>,
    ) => {
      state.currentMedia = action?.payload;
    },
    addMediaIDToSelectedMediaIDs: (state, action: PayloadAction<string>) => {
      state.selectedMediaIDs = [...state.selectedMediaIDs, action?.payload];
      state.lastSelectedMediaID = action?.payload;
    },
    removeMediaIDFromSelectedMediaIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedMediaIDs = _.filter(
        state.selectedMediaIDs,
        (id) => id !== action?.payload,
      );
      // Get the media before the deleted one
      const lastSelectedMediaID =
        state.selectedMediaIDs[state.selectedMediaIDs.length - 1];
      state.lastSelectedMediaID = lastSelectedMediaID || null;
    },
    addMediaIDsToSelectedMediaIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
        clickedID: string;
      }>,
    ) => {
      const newMediaIDsSet = new Set(state.selectedMediaIDs);
      action?.payload?.newIDs.map((id) => newMediaIDsSet.add(id));
      state.selectedMediaIDs = [...Array.from(newMediaIDsSet)];
      state.lastSelectedMediaID = action?.payload?.clickedID;
    },
    removeMediaIDsFromSelectedMediaIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
      }>,
    ) => {
      const newMediaIDsSet = new Set(state.selectedMediaIDs);
      action?.payload?.newIDs.map((id) => newMediaIDsSet.delete(id));
      state.selectedMediaIDs = [...Array.from(newMediaIDsSet)];
    },
    unSelectAllSelectedMediaIDs: (state) => {
      state.selectedMediaIDs = [];
      state.lastSelectedMediaID = null;
    },
    resetExplorationMediaSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchMediaData
    builder.addCase(
      fetchMediaData.pending,
      (state: explorationMediaStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchMediaData.fulfilled,
      (state: explorationMediaStateTypes, action) => {
        if (action?.meta?.arg?.reset) {
          state.data = [...action.payload?.data];
          state.hasMore = true;
          state.skip = 0;
        } else {
          // Check if any media duplication found
          let foundDuplicate = false;
          let numberOfDuplicates = 0;
          _.map(action.payload.data, (i) => {
            if (_.find(state.data, (t) => t?.id === i?.id)) {
              foundDuplicate = true;
              numberOfDuplicates++;
            }
          });
          if (foundDuplicate) {
            alert(
              `Found ${numberOfDuplicates} duplicate media! Please refresh the page. If this keeps happening, please contact us!`,
            );
          }
          state.data = [...state.data, ...action.payload?.data];
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchMediaData.rejected,
      (state: explorationMediaStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchOneMedia
    builder.addCase(
      fetchOneMedia.fulfilled,
      (state: explorationMediaStateTypes, action) => {
        const newMedia = action.payload?.data;
        const mediaIndex = _.findIndex(
          state.data,
          (media) => media?.id === action.payload?.meta?.query?.mediaId,
        );
        if (_.isNumber(mediaIndex)) {
          state.data[mediaIndex] = newMedia;
        }
        if (action.payload.meta?.options?.replaceCurrentMedia) {
          state.currentMedia = newMedia;
        }
      },
    );
    builder.addCase(
      fetchOneMedia.rejected,
      (state: explorationMediaStateTypes, action) => {
        state.error.message = action.error.message || "No error provided";
      },
    );
  },
});

export const {
  updateMediaExplorationHasMore,
  updateMediaExplorationSkip,
  setMediaExplorationCurrentMedia,
  addMediaIDToSelectedMediaIDs,
  removeMediaIDFromSelectedMediaIDs,
  addMediaIDsToSelectedMediaIDs,
  removeMediaIDsFromSelectedMediaIDs,
  unSelectAllSelectedMediaIDs,
  resetExplorationMediaSlice,
} = explorationMediaSlice.actions;
export default explorationMediaSlice.reducer;
