import React, { useState, Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import Switch from "components/Internal/Filters/Switch";
import {
  APIDelete,
  APIPatchWithBodyAxios,
} from "components/UtilComponents/Auth";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BasicTable from "components/Internal/Table/BasicTable";
import reset_filter from "assets/reset_filter.svg";
import delete_icon from "assets/delete.svg";

import Tooltip from "@mui/material/Tooltip";
import { Save, Close } from "@mui/icons-material";
import { snakeCaseToText } from "components/utilFunctions";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { SavedFilterModel } from "models/filter.model";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

const classes = {
  dialogTitle: {
    fontSize: "18px",
    lineHeight: "25.2px",
    fontWeight: 700,
    marginLeft: "auto",
  },
};

interface Props {
  updateFilters: () => void;
}

const EditFiltersComponent = ({ updateFilters }: Props) => {
  const dispatch = useAppDispatch();

  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const filterSlice = useAppSelector((state) => state.filterDataSlice);

  // Filter dialog
  const [openEditFiltersDialog, setOpenEditFiltersDialog] = useState(false);
  const [filterData, setFilterData] = useState<any>({});
  const [initFilterData, setInitFilterData] = useState<any>({});

  // Delete Dialog
  const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<any>({});
  const [deleteFilterLoading, setDeleteFilterLoading] =
    useState<boolean>(false);

  const handleChange = (e: any, column: string, row: any) => {
    const idx = _.findIndex(initFilterData, { id: row?.id });
    if (column === "name") row = { ...row, [column]: e?.target?.value };
    else if (column === "is_global")
      row = { ...row, [column]: e?.target?.checked };
    else console.log("Unexpexted column in EditFilter");
    const tempData = [...filterData];
    tempData[idx] = row;
    setFilterData(tempData);
  };

  const handleReset = (row: any) => {
    const idx = _.findIndex(initFilterData, { id: row?.id });
    const tempData = [...filterData];
    tempData[idx] = initFilterData[idx];
    setFilterData(tempData);
  };

  const checkDifference = (cellParam: any) => {
    const idx = _.findIndex(initFilterData, { id: cellParam?.id });
    return (
      filterData[idx].name === initFilterData[idx].name &&
      filterData[idx].is_global === initFilterData[idx].is_global
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Filter Name",
      span: 35,
      className: "font-bold",
      sortable: false,
      cell: (cellParam: any) => (
        <input
          className="input-text p-2 w-5/6"
          type="text"
          value={cellParam?.name}
          onChange={(e: any) => handleChange(e, "name", cellParam)}
        />
      ),
    },
    {
      field: "filter_type",
      headerName: "Filter Type",
      span: 30,
      className: "font-normal",
      sortable: false,
      cell: (cellParam: any) => (
        <div>{snakeCaseToText(cellParam?.filter_type)}</div>
      ),
    },
    {
      field: "is_global",
      headerName: "Availability",
      span: 30,
      className: "font-normal",
      sortable: false,
      cell: (cellParam: any) => (
        <div className="flex items-center">
          Global &nbsp;
          <Switch
            name="Global"
            checked={cellParam?.is_global}
            onChange={(e: any) => handleChange(e, "is_global", cellParam)}
          />
        </div>
      ),
    },
    {
      field: "delete",
      span: 3,
      className: "font-bold",
      sortable: false,
      onClick: (params: any) => {
        if (!params.subset_filter) {
          handleOpenDeleteFilter(params);
        }
      },
      cell: (params: any) => (
        <Tooltip title="Delete Filter">
          <div className="w-full h-full flex justify-center">
            <img
              alt=""
              className={`${
                params.subset_filter && "opacity-30 cursor-default"
              }`}
              src={delete_icon}
              width="15rem"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      field: "reset",
      headerName: "",
      span: 3,
      className: "font-bold",
      sortable: false,
      onClick: (cellParam: any) => {
        if (!checkDifference(cellParam)) {
          handleReset(cellParam);
        }
      },
      cell: (cellParam: any) => (
        <Tooltip title="Reset Changes">
          <div className="w-full h-full flex justify-center">
            <img
              alt=""
              className={`${
                checkDifference(cellParam) && "opacity-30 cursor-default"
              }`}
              src={reset_filter}
              width="16rem"
            />
          </div>
        </Tooltip>
      ),
    },
  ];
  const handleOpenDeleteFilter = (filter: any) => {
    setOpenDeleteFilter(true);
    setFilterToDelete(filter);
  };

  const handleDeleteFilter = () => {
    setDeleteFilterLoading(true);
    APIDelete(
      `/datasets/${filterToDelete.dataset_id}/savedFilters/${filterToDelete?.id}`,
    )
      .then((response) => {
        setDeleteFilterLoading(false);
        setOpenDeleteFilter(false);
        handleGetFilters();
        snackbarHelper("Deleting filter was successful!", "success");

        return response.json();
      })
      .catch(() => {
        snackbarHelper(
          `Deleting filter "${filterToDelete?.name}" failed!`,
          "error",
        );
        setDeleteFilterLoading(false);
        setOpenDeleteFilter(false);
      });
  };

  const handleSubmit = () => {
    for (const row of filterData) {
      APIPatchWithBodyAxios(
        `/datasets/${row?.dataset_id}/savedFilters/${row?.id}`,
        { name: row?.name, is_global: row?.is_global },
      )
        .then((response) => {
          if (response?.status) {
            snackbarHelper("Saved changes to filters successfuly!");
          } else if (response.status === 409) {
            /* do noting */
          }
        })
        .then(() => {
          if (row == filterData[filterData.length - 1]) handleGetFilters();
        })
        .catch((err) =>
          snackbarHelper(err || "Saving changes to filters failed!", "error"),
        );
    }
  };

  const handleGetFilters = () => {
    let subsetID = null;
    if (
      !_.isNull(dataSetData?.activeDataSet?.parent_dataset) ||
      !_.isNull(dataSetData?.activeDataSet?.id)
    ) {
      if (dataSetData?.activeDataSet?.parent_dataset) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        subsetID = dataSetData.activeDataSet.id;
      }
    }
    updateFilters();
  };

  useEffect(() => {
    const savedFilters = [
      ...(filterSlice.savedMediaFilterData || []),
      ...(filterSlice.savedInstanceFilterData || []),
      ...(filterSlice.savedAnnotationFilterData || []),
    ];
    setFilterData(savedFilters);
    setInitFilterData(savedFilters);
  }, [
    filterSlice.savedMediaFilterData,
    filterSlice.savedInstanceFilterData,
    filterSlice.savedAnnotationFilterData,
  ]);

  const handleOpenEditFiltersDialog = () => {
    setOpenEditFiltersDialog(true);
  };

  // ------------------------------------------- Loss Prevention ------------------------------------

  window.onbeforeunload = confirmExit;

  function confirmExit() {
    if (!_.isEqual(filterData, initFilterData) && openEditFiltersDialog)
      return true;
    else {
      return null;
    }
  }

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const handCloseEditFilters = () => {
    if (!_.isEqual(filterData, initFilterData)) setOpenCancelDialog(true);
    else setOpenEditFiltersDialog(false);
  };

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <Fragment>
      <button
        className="button-select-layer w-auto p-[10px] justify-center"
        onClick={handleOpenEditFiltersDialog}
      >
        <EditIcon width={20} height={20} />
      </button>

      {/* EditFilters Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openEditFiltersDialog}
        onClose={handCloseEditFilters}
      >
        <DialogContent>
          <div className="m-4">
            <div className="flex">
              <div className="text-2xl font-semibold mb-4">
                Edit Filter Presets
              </div>
            </div>
            <div className="flex mb-4 font-semibold items-center">
              Available Filters{" "}
              <div
                className={
                  "ml-auto flex items-center bg-black text-white rounded-md cursor-pointer px-4 justify-center font-normal h-10"
                }
                onClick={handleSubmit}
              >
                {" "}
                Save Filters
                <Save className="ml-3" />
              </div>
            </div>

            <BasicTable
              rows={filterData}
              columns={columns}
              rowUniqueKey="id"
              showHeader={true}
              fontSize="1"
              hover={false}
            />
          </div>
        </DialogContent>
      </Dialog>

      {/* Delete Filter dialog */}
      <Dialog
        fullWidth={true}
        open={openDeleteFilter}
        onClose={() => setOpenDeleteFilter(false)}
      >
        <DialogContent className="w-full p-4">
          {deleteFilterLoading ? (
            <div className="text-2xl text-paletteRed font-bold flex justify-center animate-pulse my-2">
              Deleting filter...
            </div>
          ) : (
            <div>
              <div className="flex m-4">
                <div style={classes.dialogTitle}>
                  Are you sure you want to delete the filter "
                  {filterToDelete?.name}"?
                </div>
                <Close
                  onClick={() => setOpenDeleteFilter(false)}
                  className="ml-auto cursor-pointer"
                />
              </div>

              <button
                className="w-full my-2"
                onClick={() => setOpenDeleteFilter(false)}
              >
                Cancel
              </button>
              <button
                className="w-full my-2 bg-paletteRed hover:bg-paletteRed-dark"
                onClick={handleDeleteFilter}
              >
                Confirm delete
              </button>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* Warning on close dialog */}
      <Dialog
        fullWidth={true}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogContent className="w-full p-4">
          <div>
            <div className="flex m-4">
              <div style={classes.dialogTitle}>
                Your unsaved changes will be lost. Are you sure you want to
                leave?
              </div>
              <Close
                onClick={() => setOpenCancelDialog(false)}
                className="ml-auto cursor-pointer"
              />
            </div>

            <button
              className="w-full my-2"
              onClick={() => setOpenCancelDialog(false)}
            >
              Cancel
            </button>
            <button
              className="w-full my-2 bg-paletteRed hover:bg-paletteRed-dark"
              onClick={() => {
                setOpenCancelDialog(false);
                setOpenEditFiltersDialog(false);
              }}
            >
              Discard Changes
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default EditFiltersComponent;
