import { AlertColor } from "@mui/material";

export enum AnnotatableEnum {
  Media = "Media",
  MediaObject = "MediaObject",
  Instance = "Instance",
  Attribute = "Attribute",
}
export type SelectedViewModel =
  | AnnotatableEnum.Media
  | AnnotatableEnum.MediaObject
  | AnnotatableEnum.Instance;

export enum SelectedItemsTypeModel {
  all = "all",
  selected = "selected",
}

export type AnnotatableTypeModel = "Media" | "Instance" | "MediaObject";

export type QueryOperatorModel =
  | "=="
  | "!="
  | "<="
  | ">="
  | "<"
  | ">"
  | "in"
  | "not in"
  | "all";

export type LogicalQueryOperatorsModels = "and" | "or" | "not";

export type OrderSortModel = "asc" | "desc";

export const tagsColors = [
  "#9D2DF6",
  "#7E2052",
  "#17B43D",
  "#1566C6",
  "#6A6A6A",
  "#F5B940",
  "#F669C6",
  "#1DD3B3",
  "#008BEF",
  "#2B362E",
  "#BE8871",
  "#2A8B1A",
  "#4BD928",
  "#4D86A5",
  "#CF0BF1",
  "#3E517A",
  "#98DA1F",
  "#FC9F5B",
  "#D60B2D",
  "#C3C4E9",
  "#B83700",
  "#7EA3F1",
  "#FAB129",
  "#911A51",
];

export enum SubsetTypeModel {
  Media = "media",
  MediaObject = "media_object",
  Instance = "instance",
  Attribute = "attribute",
  Annotation = "annotation",
}

export enum TagTypeModel {
  Media = "Media",
  MediaObject = "MediaObject",
  Instance = "Instance",
  Attribute = "Attribute",
}

export enum DefaultVisualisationNames {
  Crop = "default_crop_config",
}

// TODO: add the other thumbnails sizes

export interface TagModel {
  dataset_id?: string;
  database_object_type?: TagTypeModel;
  id: string;
  name: string;
  color: string;
  // The count of the media/media object we get from filter buckets
  count?: number;
}

export interface SelectedTagsFrequenciesModel {
  [key: string]: SelectedTagFrequencyModel;
}

export interface SelectedTagFrequencyModel
  extends SelectedTagFrequencyWithoutFreqencyModel {
  frequency: number;
  frequencyPercentage: number;
}

export interface SelectedTagFrequencyWithoutFreqencyModel {
  id: string;
  name: string;
  color: string;
}

export interface ManagerFiltersModel {
  createdAt: {
    [key: string]: ManagerCreatedDateFilterModel;
  } | null;
}
export interface ManagerCreatedDateFilterModel {
  from: Date;
  to: Date;
  label: string;
}

export enum VisibilityStatus {
  Importing = "importing",
  SplitAiSubset = "split_ai_subset",
  Visible = "visible",
  CreatingSubset = "creating_subset",
}
