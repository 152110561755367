import _ from "lodash";
import toaster, {
  CustomToasterSeverity,
} from "components/UtilComponents/CustomToaster";

const snackbarHelper = (
  msg: string,
  severity: CustomToasterSeverity = "success",
  duration?: number | false,
  title?: string,
) => {
  let message = "Something went wrong!";
  if (_.isString(msg)) {
    message = msg;
  }

  toaster[severity](
    { title: title || "", text: message },
    { autoClose: duration },
  );
};

export default snackbarHelper;
