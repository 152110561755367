import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as CloseIcon } from "assets/close.svg";

type Props = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
};

const SearchBar = ({
  searchValue,
  setSearchValue,
  placeholder = "Search",
  autoFocus = false,
}: Props) => {
  const renderClearIcon = () => {
    if (searchValue) {
      return (
        <button
          className="hover:bg-transparent"
          onClick={() => setSearchValue("")}
        >
          <CloseIcon className="w-3 h-3 text-paletteGray-10" strokeWidth={2} />
        </button>
      );
    }
  };

  return (
    <div
      className="w-full flex gap-x-2 items-center rounded-[14px]
      border-[1px] border-paletteGray-5"
    >
      <input
        type="text"
        id="table_search_bar"
        data-test="search_bar"
        className="input-text flex-1 p-2 pl-3 border-0 text-base 
          !bg-transparent focus:border-transparent placeholder:text-paletteGray-11"
        placeholder={placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        autoFocus={autoFocus}
      />
      {renderClearIcon()}
      <div
        className="mr-1 p-2 rounded-[10px] bg-paletteGray-2"
        data-test="search_button"
      >
        <SearchIcon className="w-4 h-4 text-paletteGray-10 " strokeWidth={2} />
      </div>
    </div>
  );
};

export default SearchBar;
