import { useState } from "react";
import _ from "lodash";
import getAcronym from "helpers/acronym";
import renderAttributeLabel from "helpers/functions/renderAttributeLabel";
import { useAppDispatch } from "store/hooks";
import TooltipCopy from "../Tooltips/TooltipCopy";
import { transformString } from "components/utilFunctions";
import DescriptionComponent from "components/Internal/RJSF/DescriptionComponent";
import {
  mlPredictionsDescription,
  mlProbabilityDistributionsDescription,
} from "text/aiAttributesText";

type dataType =
  | string
  | number
  | boolean
  | null
  | (string | number | boolean | null)[]
  | { [key: string]: unknown };

type cellDataType =
  | string
  | number
  | boolean
  | null
  | (string | number | boolean | null)[];

interface Props {
  id: string;
  title?: string;
  data: {
    [key: string]: dataType;
  }[];
  detailsData?: {
    [key: string]: dataType;
  };
  children?: JSX.Element;
}

const InfoCard = ({ id, title, data, detailsData, children }: Props) => {
  const dispatch = useAppDispatch();
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const attritbuteDescriptions: Record<string, string> = {
    ml_predictions: mlPredictionsDescription,
    ml_probability_distributions: mlProbabilityDistributionsDescription,
  };

  const renderGroup = (group: { [key: string]: dataType }) => {
    return _.map(group, (value, key) => {
      const hasAValue = !_.isUndefined(value);
      return (
        <div
          key={key}
          className={`w-full
          ${
            hasAValue
              ? "border-t-[1px] border-b-paletteGray-5 border-opacity-40"
              : ""
          }
           first:border-t-0 last:border-b-0`}
        >
          {renderRow(value, key)}
        </div>
      );
    });
  };

  const renderRow = (value: dataType, key: string) => {
    // Normal case: string, number, null, boolean, array
    if (
      _.isString(value) ||
      _.isNumber(value) ||
      _.isNull(value) ||
      _.isBoolean(value) ||
      _.isArray(value)
    ) {
      return renderFullRow({ key: key, value: value });
    }
    // If value is an object
    else if (_.isObject(value)) {
      return (
        <div key={key} className="w-full py-[4.5px]">
          <div className="w-full text-paletteBlack-2 flex gap-x-1">
            {transformString(getAcronym(key))}
            <DescriptionComponent description={attritbuteDescriptions?.[key]} />
          </div>
          {_.map(value, (subValue, subKey) => {
            // Render a row if the subValue is normal case
            if (
              _.isString(subValue) ||
              _.isNumber(subValue) ||
              _.isNull(subValue) ||
              _.isBoolean(subValue) ||
              _.isArray(subValue)
            ) {
              return renderFullRow({
                key: subKey,
                value: subValue as any,

                styleAsSubRow: true,
              });
            }
            // Render {...} if the subValue is object as well
            else {
              return renderFullRow({
                key: subKey,
                value: subValue as any,

                showValueOnTooltipOnly: true,
                styleAsSubRow: true,
              });
            }
          })}
        </div>
      );
    }
  };

  const renderFullRow = (params: {
    key: string;
    value: cellDataType;
    showValueOnTooltipOnly?: boolean;
    styleAsSubRow?: boolean;
  }) => {
    return (
      <div
        key={params?.key}
        className={`w-full flex flex-row justify-between py-[4.5px]
        ${params?.styleAsSubRow && "pl-2"}`}
      >
        <div className="w-1/2 text-paletteBlack-2">
          {transformString(getAcronym(params?.key))}
        </div>
        <TooltipCopy
          title={params?.value === "MediaObject" ? "Object" : params?.value}
          arrow
          placement="left"
          dispatch={dispatch}
          leaveDelay={100}
        >
          <div className=" flex justify-end text-paletteGray-10">
            {params?.showValueOnTooltipOnly
              ? "{...}"
              : renderAttributeLabel(
                  params?.value === "MediaObject" ? "Object" : params?.value,
                )}
          </div>
        </TooltipCopy>
      </div>
    );
  };

  const renderDetailsButton = () => {
    if (!_.isUndefined(detailsData) && !_.isEmpty(detailsData)) {
      return (
        <div className="pb-2">
          {renderGroupBreakLine()}
          <button
            className={`button-layer px-2 py-1 ml-3 mt-2 bg-paletteGray-4
          ${isDetailsExpanded ? "bg-paletteGray-6" : ""}
          `}
            onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
          >
            More...
          </button>
        </div>
      );
    }
  };

  const renderGroupBreakLine = () => {
    return <div className="w-full my-[1px] h-1 bg-paletteGray-5 opacity-40" />;
  };

  return (
    <div className="relative w-full" key={id}>
      <div className="pb-2 pt-1 text-sm text-paletteGray-10 font-medium">
        {title}
      </div>

      <div
        className="py-[3.5px] flex flex-col gap-y-1 animate-fade relative bg-paletteGray-2 rounded-xl
       border-[1px] border-paletteGray-2"
        data-test="expl_info"
      >
        {_.map(data, (group, index) => {
          if (_.isEmpty(group)) return;
          return (
            <div key={index} className="w-full flex flex-col gap-y-1">
              {index !== data.length && index !== 0 && renderGroupBreakLine()}
              <div className="px-3">{renderGroup(group)}</div>
            </div>
          );
        })}
        {renderDetailsButton()}
        {detailsData && isDetailsExpanded && (
          <div className="px-3">{renderGroup(detailsData)}</div>
        )}
        {children}
      </div>
    </div>
  );
};

export default InfoCard;
