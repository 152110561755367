import { useEffect, useState } from "react";
import _ from "lodash";
import { fetchDatasets } from "helpers/apis/datasets";
import { DatasetModel } from "models/dataset.model";

import SortButton, {
  SortButtonModel,
} from "components/Internal/Buttons/SortButton";
import Loading from "components/UtilComponents/Loading";
import DatasetSelectionTableRow from "components/Internal/Table/DatasetSelectionTable/DatasetSelectionTableRow";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import generateDateString from "helpers/functions/generateDateString";
import { ReactComponent as BackIcon } from "assets/arrow_left.svg";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as AIIcon } from "assets/ai.svg";

import { useAppDispatch } from "store/hooks";
import RadioButton from "components/Internal/Inputs/RadioButton";
import { VisibilityStatus } from "models/global.model";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import { AttributeMetadataModel } from "models/attributes.model";
import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { SendFilterModel } from "models/filter.model";
import { TrainingSetModel } from "models/trainingSet.model";
import { fetchTrainingSets } from "helpers/apis/trainingSet";
import { isAttributeAIAnnotationAttributeOnly } from "helpers/functions/attributes/attributesHelpers";
import {
  MLAnnotationModel,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";
import { fetchMLAnnotationModels } from "helpers/apis/mlAnnotationModel";

type StepOptions =
  | "dataset"
  | "subset"
  | "attribute"
  | "training_set"
  | "ml_annotation_model";

type Props = {
  firstStep: StepOptions;
  secondStep?: "attribute" | "subset";
  firstStepItem:
    | DatasetModel
    | AttributeMetadataModel
    | TrainingSetModel
    | MLAnnotationModel
    | null;
  onFirstStepRowClick: (
    firstItem:
      | DatasetModel
      | AttributeMetadataModel
      | TrainingSetModel
      | MLAnnotationModel
      | null,
  ) => void;
  firstStepSelectedIDs?: string[];
  secondStepItem?: DatasetModel | AttributeMetadataModel | null;
  onSecondStepRowClick?: (
    secondItem: DatasetModel | AttributeMetadataModel | null,
  ) => void;
  secondStepSelectedIDs?: string[];
  onSubRowClick?: (params: {
    clickedFirstStepItem:
      | DatasetModel
      | AttributeMetadataModel
      | TrainingSetModel
      | MLAnnotationModel
      | null;
    clickedSecondStepItem: DatasetModel | AttributeMetadataModel | null;
  }) => void;
  selectedDatasetID?: string | null;
  enableSearch?: boolean;
  extraKeysToSearch?: string[];
  disableReason?: string;
  attributesFilter?: SendFilterModel[];
};

interface DatasetSelectionTableColumn {
  field: string;
  headerName: string;
  span?: number;
  sortable?: boolean;
  className?: string;
  cell?: (row: unknown) => JSX.Element;
}

const DatasetSelectionTable = ({
  firstStep,
  secondStep,
  firstStepItem,
  onFirstStepRowClick,
  firstStepSelectedIDs,
  secondStepItem,
  onSecondStepRowClick,
  secondStepSelectedIDs,
  onSubRowClick,
  selectedDatasetID,
  enableSearch = true,
  extraKeysToSearch,
  disableReason,
  attributesFilter,
}: Props) => {
  const dispatch = useAppDispatch();

  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [datasets, setDatasets] = useState<DatasetModel[]>([]);
  const [subsets, setSubsets] = useState<DatasetModel[]>([]);
  const [attributes, setAttributes] = useState<AttributeMetadataModel[]>([]);
  const [trainingSets, setTrainingSets] = useState<TrainingSetModel[]>([]);
  const [mlAnnotationModels, setMLAnnotationModels] = useState<
    MLAnnotationModel[]
  >([]);

  const [localSearchValue, setLocalSearchValue] = useState<string>("");

  const [sortBy, setSortBy] = useState<SortButtonModel>({
    name: "name",
    direction: "asc",
  });

  useEffect(() => {
    switch (firstStep) {
      case "dataset":
        getDatasets();
        break;
      case "subset":
        selectedDatasetID && getSubsets(selectedDatasetID);
        break;
      case "attribute":
        selectedDatasetID && getAttributes(selectedDatasetID);
        break;
      case "training_set":
        getTrainingSet();
        break;
      case "ml_annotation_model":
        getMLAnnotationModels();
        break;
    }
  }, []);

  const getDatasets = () => {
    fetchDatasets(false, [VisibilityStatus.Visible], setIsLoading).then(
      (data) => {
        setDatasets(data);
      },
    );
  };

  const getSubsets = (datasetID: string) => {
    getSubsetsForDataset(datasetID, dispatch, setIsLoading).then((data) => {
      if (data !== null) {
        setSubsets(data);
      }
    });
  };

  const getAttributes = (datasetID: string) => {
    fetchAttributesMetaData(
      {
        datasetID: datasetID,
        query: attributesFilter || [],
      },
      dispatch,
      setIsLoading,
    ).then((data) => {
      if (data !== null) {
        setAttributes(data);
      }
    });
  };

  const getTrainingSet = () => {
    fetchTrainingSets(dispatch, setIsLoading).then((data) =>
      setTrainingSets(data),
    );
  };

  const getMLAnnotationModels = () => {
    fetchMLAnnotationModels(dispatch, setIsLoading).then((data) =>
      setMLAnnotationModels(data),
    );
  };

  const baseColumns: DatasetSelectionTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 40,
    },
    {
      field: "num_medias",
      headerName: "Medias",
    },
    {
      field: "num_media_objects",
      headerName: "Objects",
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 20,
      cell: (file) => {
        const dataset = file as DatasetModel;
        return (
          <div className="text-sm">
            {!_?.isNull(dataset?.creation_timestamp)
              ? generateDateString(new Date(dataset?.creation_timestamp), false)
              : "-"}
          </div>
        );
      },
    },
  ];

  const datasetsColumns: DatasetSelectionTableColumn[] = [...baseColumns];

  const subsetsColumns: DatasetSelectionTableColumn[] = [
    ...baseColumns,
    {
      field: "subset_type",
      headerName: "Subset type",
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <TooltipTruncateEllipsis>
            {subset.subset_type === "media_object"
              ? "Object"
              : _.upperFirst(subset.subset_type)}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "selected",
      headerName: "",
      sortable: false,
      span: 4,
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div className="flex justify-end">
            <RadioButton
              checked={subset?.id === secondStepItem?.id}
              onChange={() => handleRowClick(subset)}
            />
          </div>
        );
      },
    },
  ];

  const attributeColumns: DatasetSelectionTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 80,
      cell: (row) => {
        const attribute = row as AttributeMetadataModel;
        return (
          <div className="flex gap-x-1 items-center">
            <TooltipTruncateEllipsis className="pr-3">
              {attribute?.name}
            </TooltipTruncateEllipsis>
            {isAttributeAIAnnotationAttributeOnly(
              attribute?.attribute_group,
            ) && <AIIcon className="w-3 h-3 text-palettePurple" />}
          </div>
        );
      },
    },
    {
      field: "timestamp",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const attribute = row as AttributeMetadataModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_?.isNull(attribute?.timestamp)
              ? generateDateString(new Date(attribute?.timestamp), false)
              : "-"}
          </TooltipTruncateEllipsis>
        );
      },
    },
  ];

  const trainingSetColumns: DatasetSelectionTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 80,
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_?.isNull(trainingSet?.created_at)
              ? generateDateString(new Date(trainingSet?.created_at), false)
              : "-"}
          </TooltipTruncateEllipsis>
        );
      },
    },
  ];

  const mlAnnotationModelColumn: DatasetSelectionTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 80,
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const mlAnnotation = row as MLAnnotationModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_?.isNull(mlAnnotation?.created_at)
              ? generateDateString(new Date(mlAnnotation?.created_at), false)
              : "-"}
          </TooltipTruncateEllipsis>
        );
      },
    },
  ];

  const getTableInfoBasedOnStepType = () => {
    const datasetTable = {
      columns: datasetsColumns,
      data: datasets,
      type: "dataset",
    };
    const subsetTable = {
      columns: subsetsColumns,
      data: subsets,
      type: "subset",
    };
    const attributeTable = {
      columns: attributeColumns,
      data: attributes,
      type: "attribute",
    };
    const trainingSetTable = {
      columns: trainingSetColumns,
      data: trainingSets,
      type: "training set",
    };

    const filteredMLAnnotationModels = mlAnnotationModels.filter(
      (mlAnnotationModel) =>
        mlAnnotationModel.status === MLAnnotationModelStatus.TRAINING_DONE,
    );
    const mlAnnotationModelTable = {
      columns: mlAnnotationModelColumn,
      data: filteredMLAnnotationModels,
      type: "AI model",
    };

    if (secondStep && currentStep === 2) {
      switch (secondStep) {
        case "subset":
          return subsetTable;
        case "attribute":
          return attributeTable;
      }
    }

    switch (firstStep) {
      case "dataset":
        return datasetTable;
      case "subset":
        return subsetTable;
      case "attribute":
        return attributeTable;
      case "training_set":
        return trainingSetTable;
      case "ml_annotation_model":
        return mlAnnotationModelTable;
    }
  };

  // Render the header of the table (the column names) and the sort buttons
  const renderHeader = () => {
    const columns = getTableInfoBasedOnStepType()?.columns;

    return (
      <div
        className="w-auto flex mt-3 py-[6px] px-3 pr-6 text-paletteGray-8
         bg-paletteGray-1 rounded-lg border-b border-paletteGray-3"
      >
        {_.map(columns, (col, key) => {
          const columnSpan = col?.span || 100 / columns?.length;
          return (
            <div
              key={key}
              className="flex items-center gap-x-2"
              style={{ width: `${columnSpan}%` }}
            >
              {col?.headerName}
              {_.isUndefined(col?.sortable) && renderSortIcon(col)}
            </div>
          );
        })}
      </div>
    );
  };

  // Render the sort button
  const renderSortIcon = (col: DatasetSelectionTableColumn) => {
    return (
      <SortButton
        sortBy={{ name: col?.field, direction: sortBy?.direction }}
        setSortBy={setSortBy}
        selected={sortBy?.name === col?.field}
      />
    );
  };

  // Render the rows of the table (the data)
  const renderRows = () => {
    const rows = getTableInfoBasedOnStepType()?.data;

    const filteredRows = _.filter(
      rows,
      (row: DatasetModel | AttributeMetadataModel) => {
        const nameMatch = _.includes(
          _.toLower(row?.name as string),
          _.toLower(localSearchValue),
        );

        const extraKeysToSearchMatch = _.some(extraKeysToSearch, (key) => {
          return _.includes(
            _.toLower(_.get(row, key)),
            _.toLower(localSearchValue),
          );
        });

        return nameMatch || extraKeysToSearchMatch;
      },
    );

    let sortedRows = filteredRows;
    const sortName = sortBy?.name;
    if (!_.isNull(sortName) && !_.isNull(sortBy?.direction)) {
      sortedRows = _.orderBy(
        filteredRows,
        [
          (row) => {
            const sortKey = _.get(row, sortName);
            if (_.isString(sortKey)) {
              return _.toLower(sortKey);
            }
            return sortKey;
          },
        ],
        [sortBy?.direction],
      );
    }

    return _.map(sortedRows, (row: DatasetModel | AttributeMetadataModel) => {
      const isSelected =
        currentStep === 1
          ? row?.id === firstStepItem?.id
          : row?.id === secondStepItem?.id;

      const isAlreadySelected =
        _.includes(firstStepSelectedIDs, row?.id) ||
        _.includes(secondStepSelectedIDs, row?.id);
      const disableReasonText = disableReason || "Already selected";
      return (
        <DatasetSelectionTableRow
          key={row?.id}
          row={row}
          onRowClick={handleRowClick}
          renderRowCells={renderRowCells}
          isSelected={isSelected}
          isDisabled={isAlreadySelected}
          disableReason={disableReasonText}
        />
      );
    });
  };

  // Render the cells of the table (the data) for each row
  const renderRowCells = (row: DatasetModel | AttributeMetadataModel) => {
    const columns = getTableInfoBasedOnStepType()?.columns;

    return _.map(columns, (col, index) => {
      const columnSpan = col?.span || 100 / columns?.length;

      return (
        <div
          key={`${index}-${row.id}-${col?.field}`}
          className="flex items-center gap-x-2 text-ellipsis overflow-hidden"
          style={{
            width: `${columnSpan}%`,
          }}
        >
          {renderCellValue(row, col)}
        </div>
      );
    });
  };

  const renderCellValue = (
    row: DatasetModel | AttributeMetadataModel,
    col: DatasetSelectionTableColumn,
  ) => {
    // If the cell is a custom cell, use the cell function to render the cell
    // Else, use the field to render the cell
    const cellValue = col?.cell ? col?.cell(row) : _.get(row, col?.field);

    if (_.isElement(cellValue)) {
      return cellValue;
    }

    if (_.isString(cellValue) || _.isNumber(cellValue)) {
      return (
        <TooltipTruncateEllipsis className="pr-3">
          {cellValue}
        </TooltipTruncateEllipsis>
      );
    }

    return cellValue;
  };

  const renderSearchBar = () => {
    if (enableSearch) {
      return (
        <div className="w-1/2 flex gap-x-1 items-center">
          <SearchIcon
            className="w-4 h-4 text-paletteGray-10 "
            strokeWidth={2}
          />
          <input
            type="text"
            id="table_search_bar"
            key={"table_search_bar_step_" + currentStep}
            className="input-text flex-1 p-2 border-0 text-base 
              !bg-transparent focus:border-transparent placeholder:text-paletteGray-11"
            placeholder={`Search for ${getTableInfoBasedOnStepType()?.type}`}
            value={localSearchValue}
            onChange={(e) => setLocalSearchValue(e.target.value)}
            autoFocus
          />
        </div>
      );
    }
  };

  const renderCountNumber = () => {
    const count = getTableInfoBasedOnStepType().data?.length;
    const type = getTableInfoBasedOnStepType().type;
    return (
      <div className="flex items-center text-sm text-paletteGray-11">
        {count} {type}s found
      </div>
    );
  };

  const renderSelectedDataset = () => {
    if (firstStepItem === null || secondStep === undefined) {
      return null;
    }

    return (
      <div className="pt-3 flex gap-x-2 items-center">
        <BackIcon
          className="w-[18px] h-[18px] text-paletteGray-8 cursor-pointer hover:text-paletteGray-10"
          onClick={handleBackClick}
        />
        <div className="label-layer text-paletteBlack-1 bg-paletteGray-3">
          {firstStepItem?.name}
        </div>
        {renderDatasetCount()}
      </div>
    );
  };

  const renderDatasetCount = () => {
    if (currentStep === 2 && firstStep === "dataset") {
      const dataset = firstStepItem as DatasetModel;
      return (
        <div className="text-sm text-paletteGray-9 ">
          {dataset?.num_medias} media · {dataset?.num_media_objects} objects
        </div>
      );
    }
  };

  const handleBackClick = () => {
    setCurrentStep(1);
    onFirstStepRowClick(null);
    onSecondStepRowClick && onSecondStepRowClick(null);
    setLocalSearchValue("");
  };

  const handleRowClick = (row: DatasetModel | AttributeMetadataModel) => {
    // If there is only one step.
    if (_.isUndefined(secondStep)) {
      onFirstStepRowClick(row);
      return;
    }

    // If there are two steps.

    // If the first step row is clicked.
    if (currentStep === 1) {
      onFirstStepRowClick(row);
      onSecondStepRowClick && onSecondStepRowClick(null);
      setCurrentStep(2);
      setLocalSearchValue("");

      if (secondStep === "attribute") {
        getAttributes(row.id);
      }

      if (secondStep === "subset") {
        getSubsets(row.id);
      }
    }

    // If the second step row is clicked
    if (currentStep === 2) {
      onSecondStepRowClick && onSecondStepRowClick(row);
      onSubRowClick &&
        onSubRowClick({
          clickedFirstStepItem: firstStepItem,
          clickedSecondStepItem: row,
        });
    }
  };

  // Render the body of the table
  const renderBody = () => {
    // If the table is loading, show the loading component
    if (!_.isUndefined(isLoading) && isLoading) {
      return (
        <div className="h-1/2">
          <Loading />
        </div>
      );
    }
    // If the table is not loading and there is no data, show the no data found message
    else if (_.isEmpty(getTableInfoBasedOnStepType()?.data)) {
      return (
        <div className="h-full text-paletteGray-10 text-center flex items-center justify-center">
          No data found
        </div>
      );
    }
    // If the table is not loading and there is data, show the table
    else {
      return (
        <div className="h-full flex flex-col">
          <div className="w-full py-3 px-5 flex justify-between border-b-[1px] border-paletteGray-4">
            {renderSearchBar()}
            {renderCountNumber()}
          </div>
          <div className="px-4 flex flex-col flex-1 min-h-0">
            {renderSelectedDataset()}
            {renderHeader()}
            <div
              className="mt-3 flex-1 overflow-y-scroll flex flex-col gap-y-2"
              key={"table_body_step_" + currentStep}
            >
              {renderRows()}
            </div>
          </div>
        </div>
      );
    }
  };

  return renderBody();
};

export default DatasetSelectionTable;
