import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useHistory } from "react-router-dom";

interface QuickMenuItem {
  title: string;
  route: string;
}

const hotkeyMap: { [code: string]: number } = {
  KeyR: 0,
  KeyF: 1,
  KeyV: 2,
  KeyT: 3,
  KeyG: 4,
  KeyB: 5,
};

const getHotkeyString = (code: string): string =>
  `Alt+${code.replace("Key", "")}`;

const QuickMenu: React.FC = () => {
  const history = useHistory();
  const [quickList, setQuickList] = useState<QuickMenuItem[]>([]);
  const [isQuickMenuOpen, setIsQuickMenuOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const storedQuickList = sessionStorage.getItem("quickList");
    if (storedQuickList) {
      setQuickList(JSON.parse(storedQuickList));
    }
  }, []);

  const updateQuickListInStorage = (updatedList: QuickMenuItem[]) => {
    sessionStorage.setItem("quickList", JSON.stringify(updatedList));
    setQuickList(updatedList);
  };

  const handleMenuToggle = (e: KeyboardEvent) => {
    e.preventDefault();
    setIsQuickMenuOpen((prev) => !prev);
  };

  const handleAddNewItem = (e: KeyboardEvent) => {
    const newItem = {
      title: window.location.pathname || "New Item",
      route: window.location.pathname,
    };

    const exists = quickList.some((item) => item.route === newItem.route);
    if (!exists && quickList.length < 6) {
      const updatedList = [...quickList, newItem];
      updateQuickListInStorage(updatedList);
    }
  };

  const handleClearItems = (e: KeyboardEvent) => {
    updateQuickListInStorage([]);
  };

  const handleNavigation = (e: KeyboardEvent): boolean => {
    if (e.altKey) {
      const hotkeyIndex = hotkeyMap[e.code];
      if (hotkeyIndex !== undefined && quickList[hotkeyIndex]) {
        history.push(quickList[hotkeyIndex].route);
        return true;
      }
    }
    return false;
  };

  const handleMenuSelection = (e: KeyboardEvent) => {
    if (isQuickMenuOpen) {
      switch (e.code) {
        case "KeyJ":
        case "KeyS":
        case "ArrowDown":
          e.shiftKey
            ? moveItemDown()
            : setSelectedIndex((prev) => (prev + 1) % quickList.length);
          break;
        case "KeyK":
        case "KeyW":
        case "ArrowUp":
          e.shiftKey
            ? moveItemUp()
            : setSelectedIndex(
                (prev) => (prev - 1 + quickList.length) % quickList.length,
              );
          break;
        case "Enter":
        case "Space":
          history.push(quickList[selectedIndex].route);
          setIsQuickMenuOpen(false);
          break;
        case "KeyC": {
          const updatedList = quickList.filter(
            (_, index) => index !== selectedIndex,
          );
          updateQuickListInStorage(updatedList);
          setSelectedIndex(Math.max(updatedList.length - 1, 0));
          break;
        }
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === "g") {
      handleMenuToggle(e);
      return;
    } else if (e.code === "KeyA" && e.altKey) {
      handleAddNewItem(e);
      return;
    } else if (!isQuickMenuOpen && e.code === "KeyC" && e.altKey) {
      handleClearItems(e);
      return;
    } else if (handleNavigation(e)) {
      return;
    }
    handleMenuSelection(e);
  };

  // Helper functions to move items
  const moveItemUp = () => {
    if (selectedIndex > 0) {
      const newQuickList = [...quickList];
      [newQuickList[selectedIndex - 1], newQuickList[selectedIndex]] = [
        newQuickList[selectedIndex],
        newQuickList[selectedIndex - 1],
      ];
      setSelectedIndex((prev) => prev - 1);
      updateQuickListInStorage(newQuickList);
    }
  };

  const moveItemDown = () => {
    if (selectedIndex < quickList.length - 1) {
      const newQuickList = [...quickList];
      [newQuickList[selectedIndex + 1], newQuickList[selectedIndex]] = [
        newQuickList[selectedIndex],
        newQuickList[selectedIndex + 1],
      ];
      setSelectedIndex((prev) => prev + 1);
      updateQuickListInStorage(newQuickList);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [quickList, selectedIndex, isQuickMenuOpen]);

  if (!isQuickMenuOpen) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title="Quick Menu"
      open={isQuickMenuOpen}
      onClose={() => setIsQuickMenuOpen(false)}
    >
      <div className="flex justify-items-center p-4">
        <ul className="space-y-2 w-full">
          {quickList.map((item, index) => (
            <li
              key={index}
              onClick={() => history.push(item.route)}
              className={`p-2 rounded cursor-pointer transition-colors duration-150 ease-in-out ${
                index === selectedIndex
                  ? "bg-blue-400 text-white"
                  : "hover:bg-gray-100"
              }`}
            >
              <div className="flex justify-between">
                <span>{item.title}</span>
                <span className="text-gray-500">
                  {getHotkeyString(Object.keys(hotkeyMap)[index])}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};

export default QuickMenu;
