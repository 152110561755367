import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Loading from "components/UtilComponents/Loading";

/**
 * @component ConfirmDialog
 * @description A dialog to confirm the deletion of an item
 * @param isConfirmDialogOpen - Whether the dialog is open
 * @param setIsConfirmDialogOpen - A function to set whether the dialog is open
 * @param text - The text to display in the dialog
 * @param subText - The subtext to display in the dialog
 * @param confirmButtonText - The text to display in the confirm button
 * @param handleOnSuccess - A function to call when the confirm button is clicked
 * @param isLoading - Whether the dialog is loading
 * @returns {JSX.Element} - The dialog component
 */
type Props = {
  isConfirmDialogOpen: boolean;
  setIsConfirmDialogOpen: (isOpen: boolean) => void;
  text: string;
  subText?: string;
  title?: string;
  confirmButtonText?: string;
  confirmButtonClass?: string;
  handleOnSuccess: () => void;
  isLoading?: boolean;
};

const ConfirmDialog = ({
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  text,
  subText,
  title,
  confirmButtonText = "Confirm delete",
  confirmButtonClass = "",
  handleOnSuccess,
  isLoading,
}: Props) => {
  return (
    <Dialog
      maxWidth="sm"
      open={isConfirmDialogOpen}
      onClose={() => !isLoading && setIsConfirmDialogOpen(false)}
    >
      <DialogContent>
        <div className="min-w-[300px]">
          <div className="text-xl mb-4">{title}</div>
          <div className="mx-auto">
            {text}
            <div>{subText}</div>
          </div>
          <div className="w-full pt-4 flex flex-row-reverse">
            {isLoading && (
              <div className="m-1">
                <Loading size={25} />
              </div>
            )}
            <button
              className={`
                ${
                  confirmButtonClass
                    ? confirmButtonClass
                    : "button-layer bg-paletteRed hover:bg-paletteRed-dark text-white"
                }
              `}
              data-test="confirm_button"
              onClick={() => handleOnSuccess()}
              disabled={isLoading}
            >
              {confirmButtonText}
            </button>
            <button
              className="button-layer mr-2"
              data-test="cancel_button"
              onClick={() => setIsConfirmDialogOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
