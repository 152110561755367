import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { AttributeMetadataModel } from "models/attributes.model";
import { DatasetModel } from "models/dataset.model";
import { TrainingSetAttribute } from "models/trainingSet.model";
import NewTrainingSetAttributeSubsetTable from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetAttributeList/NewTrainingSetAttributeSubsetTable";
import {
  removeNewTrainingSetTrainingAttribute,
  setNewTrainingSetTrainingAttributes,
} from "store/aiManagerSlice";

import { ReactComponent as DragIcon } from "assets/drag.svg";
import { ReactComponent as RemoveIcon } from "assets/close.svg";
import { ReactComponent as OpenIcon } from "assets/open.svg";
import { ReactComponent as ArrowIcon } from "assets/arrow_left.svg";
import { ReactComponent as ArrowDownIcon } from "assets/sort_down.svg";

type Props = {
  trainingSetAttribute: TrainingSetAttribute;
  index: number;
  isDragging: boolean;
  setIsDragging: (newState: boolean) => void;
  draggedAttributeIndex: number | null;
  setDraggedAttributeIndex: (index: number | null) => void;
  draggedOverAttributeIndex: number | null;
  setDraggedOverAttributeIndex: (index: number | null) => void;
  setIsSubsetDialogOpen: (newState: boolean) => void;
  setSelectedAttribute: (newState: TrainingSetAttribute) => void;
  allAttributesMetaList: AttributeMetadataModel[] | undefined;
  allSubsets: DatasetModel[];
};

const NewTrainingAttribute = ({
  trainingSetAttribute,
  index,
  isDragging,
  setIsDragging,
  draggedAttributeIndex,
  setDraggedAttributeIndex,
  draggedOverAttributeIndex,
  setDraggedOverAttributeIndex,
  setIsSubsetDialogOpen,
  setSelectedAttribute,
  allAttributesMetaList,
  allSubsets,
}: Props) => {
  const dispatch = useAppDispatch();

  const newTrainingSet = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet,
  );

  const renderDragIcon = (index: number) => {
    return (
      <div
        className="cursor-pointer"
        onMouseDownCapture={() => {
          setIsDragging(true);
          setDraggedAttributeIndex(index);
        }}
        onMouseUpCapture={() => {
          setIsDragging(false);
          setDraggedAttributeIndex(null);
        }}
      >
        <DragIcon className="w-3 h-3 text-paletteGray-8 hover:text-paletteGray-9 " />
      </div>
    );
  };

  const getAttributeName = (attributeID: string) => {
    if (!allAttributesMetaList) return attributeID;

    const attribute = _.find(
      allAttributesMetaList,
      (attribute) => attribute.id === attributeID,
    );

    return attribute?.name || attributeID;
  };

  const renderActionButtons = (attribute: TrainingSetAttribute) => {
    return (
      <div className="flex gap-x-2">
        {/* Subset button */}
        <button
          className="button-layer text-sm"
          onClick={() => {
            setIsSubsetDialogOpen(true);
            setSelectedAttribute(attribute);
          }}
        >
          Filter by subset
        </button>

        {/* Explore button */}
        <button
          className="button-layer p-1 text-paletteGray-9 bg-transparent"
          onClick={() => {
            const exploreUrl = `main/mediaObject/${attribute.dataset_id}/main_dataset?sel_attr__attributes.${attribute.attribute_id}.dataset_id="${attribute.dataset_id}"`;
            const newWnd = window.open(
              exploreUrl,
              "_blank",
              "noopener,noreferrer",
            );
            if (newWnd) {
              newWnd.opener = null;
            }
          }}
        >
          <OpenIcon className="w-[18px] h-[18px]" />
        </button>

        {/* Remove button */}
        <button
          className="button-layer p-1 text-paletteGray-9 bg-transparent"
          onClick={() =>
            dispatch(removeNewTrainingSetTrainingAttribute(attribute))
          }
        >
          <RemoveIcon className="w-3 h-3" strokeWidth={2.5} />
        </button>
      </div>
    );
  };

  const renderUpAndDownArrows = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-[0px]">
        <div
          className="cursor-pointer p-0"
          onClick={() => {
            if (index === 0) return;
            const clone = _.clone(newTrainingSet.training_attributes);
            const temp = clone[index];
            clone[index] = clone[index - 1];
            clone[index - 1] = temp;
            dispatch(setNewTrainingSetTrainingAttributes(clone));
          }}
        >
          <ArrowIcon className="rotate-90 w-3 h-3 text-paletteGray-7 hover:text-paletteGray-10 active:text-paletteBlack-2" />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            if (index === newTrainingSet.training_attributes.length - 1) return;
            const clone = _.clone(newTrainingSet.training_attributes);
            const temp = clone[index];
            clone[index] = clone[index + 1];
            clone[index + 1] = temp;
            dispatch(setNewTrainingSetTrainingAttributes(clone));
          }}
        >
          <ArrowIcon className="-rotate-90 w-3 h-3 text-paletteGray-7 hover:text-paletteGray-10 active:text-paletteBlack-2" />
        </div>
      </div>
    );
  };

  return (
    <div
      key={`attribute-${index}`}
      className="py-2"
      onDragEnter={() => setDraggedOverAttributeIndex(index)}
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        className={`label-layer p-2 flex flex-col items-start justify-center gap-y-2 transition-all duration-300
       ${
         isDragging && draggedOverAttributeIndex == index
           ? "bg-paletteGray-9 text-white -translate-y-2 "
           : ""
       }
       ${
         isDragging &&
         draggedOverAttributeIndex !== null &&
         draggedOverAttributeIndex + 1 == index
           ? "bg-paletteGray-9 text-white translate-y-2"
           : ""
       }

        ${isDragging && draggedAttributeIndex == index ? "animate-pulse" : ""}
      `}

        // TODO: fix drag and drop. We can't move an item to the top of the list since
        //   we don't have a way to know if it should be inserted before or after the dragged over item
        // draggable={isDragging}
        // onDragStart={() => {
        //   setIsDragging(true);
        //   setDraggedAttributeIndex(index);
        // }}
        // onDragEndCapture={() => {
        //   if (
        //     draggedAttributeIndex === null ||
        //     draggedOverAttributeIndex === null
        //   ) {
        //     return;
        //   }

        //   if (draggedAttributeIndex === draggedOverAttributeIndex) {
        //     setIsDragging(false);
        //     setDraggedAttributeIndex(null);
        //     setDraggedOverAttributeIndex(null);
        //     return;
        //   }

        //   const clone = _.clone(newTrainingSet.training_attributes);

        //   // Insert the dragged attribute after the dragged over attribute
        //   const firstPart = _.slice(clone, 0, draggedOverAttributeIndex);
        //   const firstPartWithoutDraggedAttribute = _.filter(
        //     firstPart,
        //     (attribute) => attribute !== clone[draggedAttributeIndex]
        //   );
        //   const theDraggedAttribute = clone[draggedAttributeIndex];
        //   const theDraggedOverAttribute = clone[draggedOverAttributeIndex];
        //   const secondPart = _.slice(clone, draggedOverAttributeIndex + 1);
        //   const secondPartWithoutDraggedAttribute = _.filter(
        //     secondPart,
        //     (attribute) => attribute !== theDraggedAttribute
        //   );

        //   const newList = [
        //     ...firstPartWithoutDraggedAttribute,
        //     theDraggedOverAttribute,
        //     theDraggedAttribute,
        //     ...secondPartWithoutDraggedAttribute,
        //   ];

        //   dispatch(setNewTrainingSetTrainingAttributes(newList));
        //   setIsDragging(false);
        //   setDraggedAttributeIndex(null);
        //   setDraggedOverAttributeIndex(null);
        // }}
      >
        <div className="w-full flex justify-between items-center gap-x-2">
          {/* {renderDragIcon(index)} */}
          {renderUpAndDownArrows()}
          <div className="min-w-0 flex-1 flex justify-between items-center">
            {getAttributeName(trainingSetAttribute.attribute_id)}
            {renderActionButtons(trainingSetAttribute)}
          </div>
        </div>
        <NewTrainingSetAttributeSubsetTable
          trainingSetAttribute={trainingSetAttribute}
          allSubsets={allSubsets}
        />
      </div>
    </div>
  );
};

export default NewTrainingAttribute;
