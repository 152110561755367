import { Dialog } from "@mui/material";
import TrainingSetOverview from "components/UtilComponents/TrainingSetOverview";
import useTrainingSetOverview from "helpers/hooks/useTrainingSetOverview";
import { TrainingSetModel } from "models/trainingSet.model";
import { TrainingSetManagerOverviewDialogState } from "Pages/AIManager/TrainingSetManager";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useAppDispatch } from "store/hooks";
import {
  AIMangerViewEnum,
  setAIMangerView,
  setIsCreatingNewAIModelModalOpen,
  setNewAIModelTrainingSet,
} from "store/aiManagerSlice";

type Props = {
  isOpen: boolean;
  setIsOpen: (newState: TrainingSetManagerOverviewDialogState) => void;
  trainingSet: TrainingSetModel | null;
};

const TrainingSetOverviewDialog = ({
  isOpen,
  setIsOpen,
  trainingSet,
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    selectedTrainingSetAttributesMetaData,
    selectedTrainingSetDataset,
    selectedTrainingSetSubsets,
  } = useTrainingSetOverview(trainingSet);

  const handleCloseDialog = () => {
    setIsOpen({ show: false, trainingSet: null });
  };

  const handleCreateAIModelFromTrainingSet = () => {
    dispatch(setAIMangerView(AIMangerViewEnum.AIModels));
    dispatch(setIsCreatingNewAIModelModalOpen(true));
    dispatch(setNewAIModelTrainingSet(trainingSet));
    handleCloseDialog();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <div className="p-6 min-w-[500px]">
        {/* Header */}
        <div className="flex justify-between">
          <div className="text-xl text-paletteBlack-2">
            Training set overview
          </div>
          <div className="p-1 cursor-pointer" onClick={handleCloseDialog}>
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </div>
        </div>

        {trainingSet !== null && (
          <div className="pt-4">
            <TrainingSetOverview
              trainingSet={trainingSet}
              attributesMetaData={selectedTrainingSetAttributesMetaData}
              dataset={selectedTrainingSetDataset}
              subsets={selectedTrainingSetSubsets}
            />
          </div>
        )}

        <div className="w-full flex justify-end pt-6">
          <button
            className="button-layer"
            onClick={handleCreateAIModelFromTrainingSet}
          >
            Select this training set for a new AI model
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default TrainingSetOverviewDialog;
