import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Internal/Table/DatasetSelectionTable";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";

import { TrainingSetModel } from "models/trainingSet.model";
import {
  setNewAIAnnotationRunMLAnnotationModel,
  setNewAIModelTrainingSet,
} from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

type Props = {
  isSelectAIModelDialog: boolean;
  setIsSelectAIModelDialog: (newState: boolean) => void;
};

const SelectAIModelDialog = ({
  isSelectAIModelDialog,
  setIsSelectAIModelDialog,
}: Props) => {
  const dispatch = useAppDispatch();

  const newAIModel = useAppSelector((state) => state.aiManagerSlice.newAIModel);

  const handleOnAIModelRowClick = (AIModel: MLAnnotationModel) => {
    dispatch(setNewAIAnnotationRunMLAnnotationModel(AIModel));
    setIsSelectAIModelDialog(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isSelectAIModelDialog}
      onClose={() => setIsSelectAIModelDialog(false)}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="w-full h-[650px] pb-2">
        <DatasetSelectionTable
          firstStep="ml_annotation_model"
          firstStepItem={newAIModel.training_set}
          onFirstStepRowClick={(firstStepItem) =>
            handleOnAIModelRowClick(firstStepItem as MLAnnotationModel)
          }
        />
      </div>
    </Dialog>
  );
};

export default SelectAIModelDialog;
