import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchTrainingSetsStore,
  resetNewTrainingSet,
  setIsCreatingNewTrainingSetModalOpen,
} from "store/aiManagerSlice";
import { postCreateTrainingSet } from "helpers/apis/trainingSet";
import { ReactComponent as WarningIcon } from "assets/warning.svg";

interface Props {
  isCreatingNewTrainingSet: boolean;
  setIsCreatingNewTrainingSet: (value: boolean) => void;
}

const NewTrainingSetActions = ({
  isCreatingNewTrainingSet,
  setIsCreatingNewTrainingSet,
}: Props) => {
  const dispatch = useAppDispatch();

  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);
  const { newTrainingSet } = aiManagerSlice;

  const onClose = () => {
    dispatch(setIsCreatingNewTrainingSetModalOpen(false));
    dispatch(resetNewTrainingSet());
  };

  const handleCreateNewTrainingSet = () => {
    postCreateTrainingSet(
      {
        name: newTrainingSet.name.value,
        training_attributes: newTrainingSet.training_attributes,
      },
      dispatch,
      setIsCreatingNewTrainingSet,
    ).then(() => {
      onClose();
      dispatch(fetchTrainingSetsStore());
      dispatch(resetNewTrainingSet());
    });
  };

  const checkIfDisabled = () => {
    return (
      isCreatingNewTrainingSet ||
      newTrainingSet.name.value === "" ||
      newTrainingSet.training_attributes.length === 0
    );
  };

  return (
    <div className="w-full flex justify-end gap-x-2 pt-6">
      <div className="w-4/6 flex gap-x-2 items-center">
        <WarningIcon className="w-[24px] h-[24px] text-paletteYellow" />
        <div className="w-[90%] text-sm text-paletteGray-9">
          Selecting attributes that are too disparate (e.g., attributes that
          aren't closely related or have significantly different answers) can
          lead to inaccurate results.
        </div>
      </div>

      <button
        className="button-layer bg-transparent"
        onClick={onClose}
        disabled={isCreatingNewTrainingSet}
      >
        Close
      </button>
      <button
        className="button-dark-gray-layer w-fit bg-paletteBlack-2"
        onClick={handleCreateNewTrainingSet}
        disabled={checkIfDisabled()}
      >
        Create training set
      </button>
    </div>
  );
};

export default NewTrainingSetActions;
