import { Dispatch } from "@reduxjs/toolkit";
import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as OpenIcon } from "assets/open.svg";

import { getDataset } from "helpers/apis/datasets";

import {
  AIAnnotationRunModel,
  AIAnnotationRunStatusEnum,
} from "models/aiAnnotationRun.model";
import { SubsetTypeModel } from "models/global.model";
import { AIAnnotationRunManagerPopoverConfirmDialogState } from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunManagerTable";
import { useAppDispatch } from "store/hooks";

type Props = {
  aiAnnotationRun: AIAnnotationRunModel;
  setRenameInputID: (id: string | null) => void;
  setConfirmDialogState: (
    newState: AIAnnotationRunManagerPopoverConfirmDialogState,
  ) => void;
};

const AIAnnotationRunManagerTableActions = ({
  aiAnnotationRun,
  setRenameInputID,
  setConfirmDialogState,
}: Props) => {
  const dispatch = useAppDispatch();

  const renderExploreResultsButton = () => {
    if (aiAnnotationRun.status !== AIAnnotationRunStatusEnum.DONE) {
      return null;
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();

          getUrlPrefix(aiAnnotationRun?.subset_id, dispatch).then(
            (url_prefix) => {
              // AI attributes with no goliat project have the ann run node ID as attr ID
              const attributeID = aiAnnotationRun?.attribute_metadata_id;
              const datasetID = aiAnnotationRun?.dataset_id;
              const exploreUrl = `${url_prefix}/${datasetID}/main_dataset?sel_attr__attributes.${attributeID}.dataset_id="${datasetID}"`;
              const newWnd = window.open(
                exploreUrl,
                "_blank",
                "noopener,noreferrer",
              );
              if (newWnd) {
                newWnd.opener = null;
              }
            },
          );
        }}
        className="button-layer w-auto whitespace-nowrap mr-2"
      >
        <OpenIcon className="w-[18px] h-[18px]" />
        Explore results
      </button>
    );
  };

  const getUrlPrefix = async (subset_id: string, dispatch: Dispatch) => {
    const data = await getDataset({ datasetID: subset_id }, dispatch);
    let urlPrefix = "/main";
    switch (data.subset_type) {
      case SubsetTypeModel.Media:
        urlPrefix += "/media";
        break;
      case SubsetTypeModel.MediaObject:
        urlPrefix += "/mediaObject";
        break;
      case SubsetTypeModel.Instance:
        urlPrefix += "/instance";
        break;
    }
    return urlPrefix;
  };

  return (
    <div className="flex flex-row-reverse gap-x-1 items-center">
      <button
        className="button-select-layer p-1"
        onClick={() => setRenameInputID(aiAnnotationRun?.id)}
      >
        <EditIcon className="text-paletteGray-9 w-[18px]" />
      </button>

      <button
        className="p-1 button-select-layer"
        onClick={() =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            aiAnnotationRunID: aiAnnotationRun?.id,
          })
        }
      >
        <DeleteIcon className="text-paletteGray-9 w-[18px]" />
      </button>

      {renderExploreResultsButton()}
    </div>
  );
};

export default AIAnnotationRunManagerTableActions;
