import Form from "components/Internal/Forms/Form";
import FormLabel from "components/Internal/Inputs/Form/Label";
import { FormFieldModel, NumberFieldModel } from "models/form.model";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setConfigurationsFormField } from "store/pipelineDesignerSlice";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import keycloak from "keycloak";
import { getSubsetTypeCount } from "helpers/functions/datasets/datasetHelpers";
import { UIsEnum } from "schemas/annotationUIs.model";
import { showShouldWorkpackageIncludeTaskOutputsBasedOnGuiType } from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurations/PipelineConfigurationsForm";

const PipelineConfigurations = () => {
  const dispatch = useAppDispatch();
  const pipelineConfigurationsForm = useAppSelector(
    (state) => state.pipelineDesignerSlice.configurationsForm,
  );
  const subset = useAppSelector((state) => state.pipelineDesignerSlice.subset);

  const minRepeats = pipelineConfigurationsForm?.min_repeats.value as number;
  const maxRepeats = pipelineConfigurationsForm?.max_repeats.value as number;
  const gui_type: UIsEnum | null = useAppSelector(
    (state) => state.pipelineDesignerSlice?.uiType,
  );

  useEffect(() => {
    updateThreshold(minRepeats, maxRepeats);
    updateMinRepeatsMaxValue(minRepeats, maxRepeats);
    updateMaxRepeatsMinValue(minRepeats, maxRepeats);
  }, [minRepeats, maxRepeats]);

  useEffect(() => {
    updateTasksPerWorkPackageMaxValue();
  }, [subset]);

  const renderForm = () => {
    const showAutoAnnotate =
      gui_type === UIsEnum.binary_2d || gui_type === UIsEnum.discrete_2d;
    const showShouldWorkPackageIncludeTaskOutputs =
      gui_type &&
      showShouldWorkpackageIncludeTaskOutputsBasedOnGuiType(gui_type);

    return (
      <div
        className="p-2 flex flex-col gap-y-2"
        data-test="configurations_pipeline_designer"
      >
        {renderRowWithTwoFields(
          pipelineConfigurationsForm?.min_repeats,
          pipelineConfigurationsForm?.max_repeats,
        )}
        {renderRow(pipelineConfigurationsForm?.convergence_threshold)}
        {renderRow(pipelineConfigurationsForm?.wp_tasks)}
        {renderRow(pipelineConfigurationsForm?.wp_timeout)}
        {checkIfUserHavePermission(
          keycloak,
          "accessPipelineReferenceDataTasksFeature",
        ) && renderRow(pipelineConfigurationsForm?.wp_rd_tasks)}
        {showShouldWorkPackageIncludeTaskOutputs &&
          renderRow(
            pipelineConfigurationsForm?.should_workpackage_include_task_outputs,
          )}
        {showAutoAnnotate &&
          checkIfUserHavePermission(
            keycloak,
            "accessPipelineAutoAnnotateFeature",
          ) &&
          renderRow(pipelineConfigurationsForm?.auto_annotate)}
      </div>
    );
  };

  const renderRow = (field: FormFieldModel) => {
    return (
      <div
        className="w-full flex gap-x-1 justify-between items-center"
        data-test="value_config_pipeline_designer"
      >
        <div className="w-1/2 ">
          <div className="flex align-center gap-x-1">
            <FormLabel field={field} />
          </div>
        </div>
        <div className="w-1/2">{renderField(field)}</div>
      </div>
    );
  };

  const renderRowWithTwoFields = (
    field1: FormFieldModel,
    field2: FormFieldModel,
  ) => {
    const customLabel = field1?.label + " & " + field2?.label;
    return (
      <div className="w-full flex justify-between items-center">
        <div className="w-1/2">
          <FormLabel field={field1} customLabel={customLabel} />
        </div>
        <div className="w-1/2 flex">
          <div className="w-1/2">{renderField(field1)}</div>
          <div className="flex items-center">-</div>
          <div className="w-1/2">{renderField(field2)}</div>
        </div>
      </div>
    );
  };

  const renderField = (field: FormFieldModel) => {
    return (
      <div className="w-full">
        <Form
          formData={{
            [field?.key]: {
              ...field,
              label: undefined,
            },
          }}
          setFormData={(formData) => {
            if (field?.key === "min_repeats") {
              updateThreshold(
                formData["min_repeats"].value as number,
                pipelineConfigurationsForm?.max_repeats.value as number,
              );
            }
            if (field?.key === "max_repeats") {
              updateThreshold(
                pipelineConfigurationsForm?.min_repeats.value as number,
                formData["max_repeats"].value as number,
              );
            }

            dispatch(
              setConfigurationsFormField({
                ...formData[field?.key],
                label: field?.label,
              }),
            );
          }}
        />
      </div>
    );
  };

  const updateThreshold = (minRepeats: number, maxRepeats: number) => {
    if (minRepeats < maxRepeats) {
      dispatch(
        setConfigurationsFormField({
          ...pipelineConfigurationsForm?.convergence_threshold,
          required: true,
          disabled: false,
        }),
      );
    } else {
      dispatch(
        setConfigurationsFormField({
          ...pipelineConfigurationsForm?.convergence_threshold,
          required: false,
          disabled: true,
        }),
      );
    }
  };

  const updateMinRepeatsMaxValue = (minRepeats: number, maxRepeats: number) => {
    if (minRepeats <= maxRepeats) {
      const minRepeatsField =
        pipelineConfigurationsForm?.min_repeats as NumberFieldModel;
      dispatch(
        setConfigurationsFormField({
          ...minRepeatsField,
          settings: {
            ...minRepeatsField?.settings,
            maximumValue: maxRepeats,
          },
        }),
      );
    }
  };

  const updateMaxRepeatsMinValue = (minRepeats: number, maxRepeats: number) => {
    if (maxRepeats >= minRepeats) {
      const maxRepeatsField =
        pipelineConfigurationsForm?.max_repeats as NumberFieldModel;
      dispatch(
        setConfigurationsFormField({
          ...maxRepeatsField,
          settings: {
            ...maxRepeatsField?.settings,
            minimumValue: minRepeats,
          },
        }),
      );
    }
  };

  const updateTasksPerWorkPackageMaxValue = () => {
    if (subset === null) return;
    const maximumValue = getSubsetTypeCount(subset);
    const wpTasksField =
      pipelineConfigurationsForm?.wp_tasks as NumberFieldModel;
    dispatch(
      setConfigurationsFormField({
        ...wpTasksField,
        settings: {
          ...wpTasksField?.settings,
          maximumValue: maximumValue,
        },
      }),
    );
  };

  return renderForm();
};

export default PipelineConfigurations;
