import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchAIModelsStore,
  resetNewAIModel,
  setIsCreatingNewAIModelModalOpen,
} from "store/aiManagerSlice";
import { postCreateMLAnnotationModel } from "helpers/apis/mlAnnotationModel";

interface Props {
  isCreatingNewAIModel: boolean;
  setIsCreatingNewAIModel: (value: boolean) => void;
}

const NewAIModelAction = ({
  isCreatingNewAIModel,
  setIsCreatingNewAIModel,
}: Props) => {
  const dispatch = useAppDispatch();

  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);
  const { newAIModel } = aiManagerSlice;

  const onClose = () => {
    dispatch(setIsCreatingNewAIModelModalOpen(false));
  };

  const handleCreateNewAIModel = () => {
    if (newAIModel.training_set === null) return;

    postCreateMLAnnotationModel(
      {
        name: newAIModel.name.value,
        training_set_id: newAIModel.training_set.id,
      },
      dispatch,
      setIsCreatingNewAIModel,
    ).then(() => {
      onClose();
      dispatch(fetchAIModelsStore());
      dispatch(resetNewAIModel());
    });
  };

  const checkIfDisabled = () => {
    return (
      isCreatingNewAIModel ||
      newAIModel.name.value === "" ||
      newAIModel.training_set === null
    );
  };

  return (
    <div className="w-full flex justify-end gap-x-2 pt-6">
      <button
        className="button-layer bg-transparent"
        onClick={onClose}
        disabled={isCreatingNewAIModel}
      >
        Close
      </button>
      <button
        className="button-dark-gray-layer w-fit bg-paletteBlack-2"
        onClick={handleCreateNewAIModel}
        disabled={checkIfDisabled()}
      >
        Train AI model
      </button>
    </div>
  );
};

export default NewAIModelAction;
