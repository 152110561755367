import { ReactComponent as InfoIcon } from "assets/info.svg";

const NewTrainingSetHelperText = () => {
  return (
    <div
      className="h-full px-4 py-3 bg-palettePurple/5 text-palettePurple-dark rounded-2xl 
            flex flex-col gap-y-4 overflow-auto"
    >
      {/* Title */}
      <div className="flex gap-x-2 text-lg items-center text-palettePurple-dark">
        <InfoIcon className="w-4 h-4 text-palettePurple" />
        <span>Training sets</span>
      </div>

      {/* Section 1 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-thin">
          A training set is a collection of related attributes (entities in
          which the information/insights gathered from annotation runs are
          stored) specifically compiled for training a model. It allows you to
          tailor your data by combining relevant attributes and filtering out
          unnecessary information to meet your specific needs.
        </div>
      </div>

      {/* Section 2 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-thin">
          Here are some example scenarios where you might modify a training set:
          <ol>
            <li>
              - Removing ambiguous samples: Eliminate data points to boost the
              model's accuracy.
            </li>
            <li>
              - Expanding the dataset: Increase the dataset size to improve the
              model's learning.
            </li>

            <li>
              - Replacing data: Substitute less relevant data with more
              pertinent examples.
            </li>

            <li>
              - Focusing on specific subsets: Concentrate on certain data
              subsets to enhance the model's performance in specific areas.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default NewTrainingSetHelperText;
