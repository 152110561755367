import { AnnotationRunConfig } from "models/annotationRun.model";

export interface AIAnnotationRunModel {
  id: string;
  name: string;
  owner: string;
  status: AIAnnotationRunStatusEnum;
  config: AnnotationRunConfig;
  dataset_id: string;
  subset_id: string;
  created_at: string;
  updated_at: string;
  archived_at: string;
  ml_annotation_model_id: string;
  attribute_metadata_id: string;
  user_group: string;
}

export interface AIAnnotationRunCreate {
  name: string;
  dataset_id: string;
  subset_id: string;
  ml_annotation_model_id: string;
}

export enum AIAnnotationRunStatusEnum {
  CREATED = "created",
  STARTED = "started",
  ANNOTATING = "annotating",
  CREATION_FAILED = "creation_failed",
  ANNOTATION_FAILED = "annotation_failed",
  AI_ANNOTATION_FAILED = "ai_annotation_failed",
  DONE = "done",
}
