import StandardPopoverWrapper from "components/Internal/Popovers/StandardPopoverWrapper";
import { ReactComponent as SaveIcon } from "assets/save.svg";
import {
  patchPipeline,
  patchPipelineNode,
  postCreatePipeline,
} from "helpers/apis/pipelines";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setPipeline,
  setPipelineName,
  setPipelineNode,
} from "store/pipelineDesignerSlice";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";
import {
  getPipelineDesignerErrorMessages,
  getPipelineNode,
} from "helpers/functions/pipelines/pipelinesHelpers";
import WarningDialog from "components/Internal/Dialogs/WarningDialog";
import { useState } from "react";
import { validateForm } from "components/Internal/Forms/formHelper";
import { TextFieldModel } from "models/form.model";

type Props = {
  showOnlyIcon?: boolean;
};

const SavePipelinePopover = ({ showOnlyIcon = false }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const activePipeline = useAppSelector(
    (state) => state.pipelineDesignerSlice.pipeline,
  );
  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const getPipelineActionsList = () => {
    return [
      {
        label: "Save pipeline",
        onItemClick: () => handleSavePipeline(false),
        disabled:
          !pipelineDesignerData.pipeline && !pipelineDesignerData.pipelineNode,
      },
      {
        label: "Save pipeline as new",
        onItemClick: () => handleSavePipeline(true),
      },
    ];
  };

  const renderSavePipelineButton = () => {
    return (
      <button
        className={`button-layer text-paletteBlack-1
          ${showOnlyIcon ? "p-3" : ""}`}
      >
        <SaveIcon width={16} height={16} strokeWidth={2} />
        {!showOnlyIcon && "Save pipeline"}
      </button>
    );
  };

  const isValidPipelineName = (): boolean => {
    const nameValidationResult = validateForm({
      name: pipelineDesignerData.pipelineName,
    });
    if (!nameValidationResult.isFormValid) {
      setIsWarningDialogOpen(true);
      dispatch(
        setPipelineName(
          nameValidationResult.newFormState.name as TextFieldModel,
        ),
      );
      return false;
    }
    return true;
  };

  const handleSavePipeline = (saveNewPipeline: boolean) => {
    if (!isValidPipelineName()) {
      return;
    }

    // Save pipeline
    if (saveNewPipeline) {
      handleCreateNewPipeline();
    } else {
      handlePatchPipeline();
    }
  };

  const handleCreateNewPipeline = () => {
    postCreatePipeline({
      name: pipelineDesignerData.pipelineName.value,
      nodes: [getPipelineNode(pipelineDesignerData)],
    }).then((newPipeline) => {
      if (newPipeline) {
        dispatch(setPipeline(newPipeline));
        if (newPipeline.nodes && newPipeline.nodes.length > 0) {
          dispatch(setPipelineNode(newPipeline.nodes[0]));
        }
        history.push(
          getPipelineDesignerPageRoute({ pipeline_id: newPipeline.id }),
        );
      }
    });
  };

  const handlePatchPipeline = () => {
    const pipelineNode = pipelineDesignerData.pipelineNode;
    if (activePipeline && pipelineNode) {
      patchPipeline(
        activePipeline.id,
        { name: pipelineDesignerData.pipelineName.value },
        dispatch,
      ).then((response) => {
        if (response) {
          dispatch(setPipeline(response));
        }
      });
      patchPipelineNode(
        pipelineNode?.id,
        getPipelineNode(pipelineDesignerData),
      ).then((response) => {
        if (response) {
          dispatch(setPipelineNode(response));
        }
      });
    }
  };

  return (
    <div>
      <StandardPopoverWrapper
        id="save-pipeline-popover"
        buttonElement={renderSavePipelineButton()}
        itemsList={getPipelineActionsList()}
      />
      <WarningDialog
        title={"Pipeline name incomplete"}
        subtitle={"Please set a valid pipeline name."}
        errorMessageList={getPipelineDesignerErrorMessages(
          pipelineDesignerData,
        )}
        openDialog={isWarningDialogOpen}
        setOpenDialog={setIsWarningDialogOpen}
      />
    </div>
  );
};

export default SavePipelinePopover;
