import { useState } from "react";
import { useAppSelector } from "store/hooks";

import { AddCircle } from "@mui/icons-material";

import { AnnotatableEnum, SelectedItemsTypeModel } from "models/global.model";
import NewSubsetDialog, {
  NewSubsetOptionModel,
} from "components/Internal/Dialogs/CreateSubsetDialog/NewSubset";
import { useKeycloak } from "@react-keycloak/web";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

interface Props {
  selectedItemsType: SelectedItemsTypeModel;
  setIsSubsetDialogOpen: (value: boolean) => void;
}

const CreateSubsetDialog = ({
  selectedItemsType,
  setIsSubsetDialogOpen,
}: Props) => {
  const { keycloak } = useKeycloak();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const handleCloseSubsetsDialog = () => {
    setIsSubsetDialogOpen(false);
  };

  // Create new subset logic
  const [openCreateSubset, setOpenCreateSubset] = useState(false);
  const [subsetOption, setSubsetOption] =
    useState<NewSubsetOptionModel>("normal");

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <>
      <div className="">
        <div className="">
          <TooltipTruncateEllipsis className="text-2xl font-semibold mb-4">
            {dataSetData?.activeDataSet?.name ?? ""}
          </TooltipTruncateEllipsis>
          <div className="ml-auto p-4 ">
            {/* New subset button */}
            <button
              className="button-select-layer"
              data-test="normal_subset_button"
              onClick={() => {
                setSubsetOption("normal");
                setOpenCreateSubset(true);
              }}
            >
              <AddCircle className="mx-2" />
              New subset from current selection
            </button>

            {/* New random subset */}
            <button
              className="button-select-layer"
              data-test="random_subset_button"
              onClick={() => {
                setSubsetOption("random");
                setOpenCreateSubset(true);
              }}
            >
              <AddCircle className="mx-2" />
              New random subset from current selection
            </button>

            {/* New subset with categories */}
            {
              <button
                className="button-select-layer"
                data-test="filtered_objects_subset_button"
                onClick={() => {
                  setSubsetOption("specificObjects");
                  setOpenCreateSubset(true);
                }}
                disabled={selectedView !== AnnotatableEnum.Media}
              >
                <AddCircle className="mx-2" />
                New subset with filtered objects
              </button>
            }
          </div>
        </div>
      </div>

      {/* Create new subset dialog */}
      <NewSubsetDialog
        subsetOption={subsetOption}
        selectedItemsType={selectedItemsType}
        openCreateSubset={openCreateSubset}
        setOpenCreateSubset={setOpenCreateSubset}
        handleCloseSubsetsDialog={handleCloseSubsetsDialog}
      />
    </>
  );
};

export default CreateSubsetDialog;
