import DetailsDescriptionPurple from "components/Internal/Description/DetailsDescriptionPurple";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import AutomationCorrectnessCurve from "Pages/AnnotationRunManager/AIModelDialog/AutomationCorrectnessCurve";
import { aiModelDescription } from "text/aiModelText";

type Props = {
  aiModel: MLAnnotationModel;
};

const AIModelOverviewComponent = ({ aiModel }: Props) => {
  return (
    <div>
      <div className="pb-2">
        <label>Automation Correctness Curve</label>
        <DetailsDescriptionPurple text={aiModelDescription} />
      </div>
      <AutomationCorrectnessCurve
        curve={aiModel?.automation_correctness_curve}
      />
    </div>
  );
};

export default AIModelOverviewComponent;
