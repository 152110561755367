import { useContext, useEffect, useState } from "react";
import "./App.css";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

import * as Sentry from "@sentry/react";

import { useAppDispatch } from "store/hooks";
import {
  setAvailableUserGroups,
  setCurrentUserID,
} from "store/userManagementSlice";
import { useKeycloak } from "@react-keycloak/web";
import RoutesComponent from "routes";
import { postGetUser } from "helpers/apis/user";
import setupAnalytics from "helpers/analytics/setup";

import { useHistory } from "react-router-dom";
import { WebsocketContext } from "helpers/contexts/WebsocketContext";
import { openWebsocketConnection } from "helpers/websockets";

Sentry.init({
  dsn: String(
    process.env.REACT_APP_SENTRY_DSN ??
      alert("Config value 'sentry dsn' is not defined"),
  ),
  environment: String(
    process.env.REACT_APP_ENVIRONMENT ??
      alert("Config value 'envrionment' is not defined"),
  ),
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: Number(
    process.env.REACT_APP_TRACE_SAMPLE_RATE ??
      alert("Config value 'trace sample rate' is not defined"),
  ), // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: Number(
    process.env.REACT_APP_REPLAY_SAMPLE_RATE ??
      alert("Config value 'replaySessionSampleRate' is not defined"),
  ), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: Number(
    process.env.REACT_APP_REPLAY_ERROR_SAMPLE_RATE ??
      alert("Config value 'replaysOnErrorSampleRate' is not defined"),
  ), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      console.log(event);
      alert("Sentry event, check console");
      return null;
    } else {
      return event;
    }
  },
});

function App(): JSX.Element {
  const { keycloak } = useKeycloak();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  const title_suffix = String(
    process.env.REACT_APP_TITLE_SUFFIX ??
      alert("Config value 'title suffix' is not defined"),
  );
  document.title = `HARI ${title_suffix}`;
  const theme = createTheme();

  useEffect(() => {
    setupAnalytics().catch(console.error);
  }, []);

  useEffect(() => {
    const userID = keycloak?.idTokenParsed?.sub;
    if (userID) {
      postGetUser(userID, dispatch).then(() =>
        dispatch(setCurrentUserID(userID)),
      );

      openWebsocketConnection(userID, dispatch, history)
        .then((socket) => {
          setWebSocket(socket);
        })
        .catch(console.error);

      keycloak.loadUserInfo().then((userInfo) => {
        const userInfoTyped = userInfo as { user_groups: string[] };
        dispatch(setAvailableUserGroups(userInfoTyped?.user_groups));
      });

      return () => {
        if (webSocket !== null) {
          webSocket?.close();
        }
      };
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <WebsocketContext.Provider value={{ ws: webSocket }}>
          <RoutesComponent />
        </WebsocketContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
