import { useAppDispatch, useAppSelector } from "store/hooks";
import { Dialog } from "@mui/material";
import {
  resetNewAIAnnotationRun,
  setIsCreatingNewAIAnnotationRunModalOpen,
  setNewAIAnnotationRunName,
} from "store/aiManagerSlice";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";

import TextInput from "components/Internal/Inputs/Form/TextInput";
import { useState } from "react";

import SelectAIModelDialog from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog/SelectAIModelDialog";
import _ from "lodash";
import SelectDatasetAndSubsetDialog from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog/SelectDatasetAndSubsetDialog";
import NewAIAnnotationRunAction from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog/NewAIAnnotationRunAction";
import AsteriskLabel from "components/Internal/Labels/AsteriskLabel";
import AIModelOverviewComponent from "components/UtilComponents/AIModelOverviewComponent";
import NewAIAnnotationRunHelperText from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog/NewAIAnnotationRunHelperText";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

const CreateNewAIAnnotationRunDialog = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.aiManagerSlice.isCreateNewAIAnnotationRunModalOpen,
  );

  const newAIAnnotationRun = useAppSelector(
    (state) => state.aiManagerSlice.newAIAnnotationRun,
  );

  const [isSelectAIModelDialogOpen, setIsSelectAIModelDialogOpen] =
    useState(false);
  const [
    isSelectDatasetAndSubsetDialogOpen,
    setIsSelectDatasetAndSubsetDialogOpen,
  ] = useState(false);

  const [isCreatingNewAIAnnotationRun, setIsCreatingNewAIAnnotationRun] =
    useState(false);

  const renderSelectAIModelButton = () => {
    return (
      <button
        className="button-dark-gray-layer w-fit"
        onClick={() => setIsSelectAIModelDialogOpen(true)}
      >
        Select an AI model
      </button>
    );
  };

  const renderSelectedAIModel = () => {
    if (newAIAnnotationRun.ml_annotation_model === null) {
      return null;
    }

    return (
      <div className="flex justify-between items-center gap-x-2">
        <div>{newAIAnnotationRun.ml_annotation_model.name}</div>
        <div
          className="button-select-layer w-auto"
          onClick={() => setIsSelectAIModelDialogOpen(true)}
        >
          <EditIcon className="w-3 h-3" />
        </div>
      </div>
    );
  };

  const renderAIModelAutomationCorrectnessCurve = () => {
    if (
      newAIAnnotationRun?.ml_annotation_model === null ||
      _.isUndefined(
        newAIAnnotationRun?.ml_annotation_model?.automation_correctness_curve,
      )
    ) {
      return;
    }

    return (
      <AIModelOverviewComponent
        aiModel={newAIAnnotationRun.ml_annotation_model}
      />
    );
  };

  const renderSelectDatasetAndSubsetButton = () => {
    return (
      <button
        className="button-dark-gray-layer w-fit"
        onClick={() => setIsSelectDatasetAndSubsetDialogOpen(true)}
      >
        Select a dataset and subset
      </button>
    );
  };

  const renderSelectedDataset = () => {
    if (newAIAnnotationRun.dataset === null) return null;

    const dataset = newAIAnnotationRun.dataset;

    return (
      <TooltipTruncateEllipsis className="w-auto px-2 rounded italic text-paletteBlack-1 bg-paletteGray-3">
        {dataset?.name}
      </TooltipTruncateEllipsis>
    );
  };

  const renderSelectedSubset = () => {
    if (newAIAnnotationRun.subset === null) return null;

    const subset = newAIAnnotationRun.subset;

    return (
      <div className="flex gap-x-2 items-center">
        <TooltipTruncateEllipsis className="label-layer text-paletteBlack-1 bg-paletteGray-3">
          {subset?.name}
        </TooltipTruncateEllipsis>
        <div className="text-sm text-paletteGray-9 ">
          {subset?.num_medias} media · {subset?.num_media_objects} objects
        </div>

        <div
          className="button-select-layer w-auto"
          onClick={() => setIsSelectDatasetAndSubsetDialogOpen(true)}
        >
          <EditIcon className="w-3 h-3" />
        </div>
      </div>
    );
  };

  const onClose = () => {
    dispatch(setIsCreatingNewAIAnnotationRunModalOpen(false));
    dispatch(resetNewAIAnnotationRun());
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <div className="p-6 min-w-[600px] min-h-[600px] h-full flex gap-x-5">
        <div className="w-[300px]">
          <NewAIAnnotationRunHelperText />
        </div>
        <div className=" min-w-0 flex-1 flex flex-col justify-between">
          {/* Header */}
          <div className="flex justify-between">
            <div className="text-xl text-paletteBlack-2">
              New AI annotation run
            </div>
            <button className="p-1 cursor-pointer" onClick={onClose}>
              <CloseIcon className="w-3 h-3 text-paletteGray-9" />
            </button>
          </div>

          <div className="py-8 flex flex-col gap-y-2">
            <div className=" font-medium">AI annotation run information</div>
            <div className="flex flex-col gap-y-2">
              <TextInput
                field={newAIAnnotationRun.name}
                value={newAIAnnotationRun.name.value}
                handleOnChange={(e) =>
                  dispatch(setNewAIAnnotationRunName(e.target.value))
                }
                autoFocus
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-2 min-h-0 flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-2">
              <div className="w-full flex justify-between font-medium">
                <div>
                  Data
                  <AsteriskLabel />
                </div>
                {newAIAnnotationRun.subset === null &&
                  renderSelectDatasetAndSubsetButton()}
              </div>

              {renderSelectedDataset()}
              {renderSelectedSubset()}
            </div>

            <div className="flex justify-between items-center">
              <div className="font-medium">
                AI model
                <AsteriskLabel />
              </div>
              {newAIAnnotationRun.ml_annotation_model === null
                ? renderSelectAIModelButton()
                : renderSelectedAIModel()}
            </div>
          </div>

          {renderAIModelAutomationCorrectnessCurve()}

          <NewAIAnnotationRunAction
            isCreatingNewAIAnnotationRun={isCreatingNewAIAnnotationRun}
            setIsCreatingNewAIAnnotationRun={setIsCreatingNewAIAnnotationRun}
          />
        </div>
      </div>

      {isSelectAIModelDialogOpen && (
        <SelectAIModelDialog
          isSelectAIModelDialog={isSelectAIModelDialogOpen}
          setIsSelectAIModelDialog={setIsSelectAIModelDialogOpen}
        />
      )}
      {isSelectDatasetAndSubsetDialogOpen && (
        <SelectDatasetAndSubsetDialog
          isSelectDatasetAndSubsetDialogOpen={
            isSelectDatasetAndSubsetDialogOpen
          }
          setIsSelectDatasetAndSubsetDialogOpen={
            setIsSelectDatasetAndSubsetDialogOpen
          }
        />
      )}
    </Dialog>
  );
};

export default CreateNewAIAnnotationRunDialog;
