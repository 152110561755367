import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/hooks";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import _ from "lodash";
import { PipelineModel } from "models/pipelines.model";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";
import { useHistory } from "react-router-dom";
import {
  deletePipeline,
  fetchNodesOfPipeline,
  fetchPipelines,
  patchPipeline,
  postDuplicatePipeline,
} from "helpers/apis/pipelines";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import PipelineManagerTableActions, {
  PipelineManagerPopoverConfirmDialogState,
} from "Pages/PipelineManager/PipelineManagerTableActions";
import EditName from "components/Internal/Inputs/EditName";

type Props = {
  pipelines: PipelineModel[];
  setPipelines: (pipelines: PipelineModel[]) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  searchValue?: string;
};

const PipelineManagerTable = ({
  pipelines,
  setPipelines,
  isLoading,
  setIsLoading,
  searchValue,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [confirmDialogState, setConfirmDialogState] =
    useState<PipelineManagerPopoverConfirmDialogState>({
      show: false,
      action: "duplicate",
      pipelineID: null,
    });

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (renameInputID) {
      inputRefArray.current?.[renameInputID]?.focus();
    }
  }, [renameInputID]);

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Pipeline name",
      span: 30,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <div className="flex overflow-hidden pr-3">
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) => {
                handleRenamePipeline(pipeline?.id, newName);
              }}
              withToolTip={true}
              fontSize="text-normal"
            />
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        const date = new Date(pipeline?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 20,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <UserGroupSelection
            selectedUserGroup={pipeline?.user_group}
            onChanges={(newUserGroup: string) =>
              patchPipeline(
                pipeline?.id,
                { user_group: newUserGroup },
                dispatch,
                setIsLoading,
              ).then(() => updatePipelines())
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 25,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <div className="w-full">
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(
                  getPipelineDesignerPageRoute({ pipeline_id: pipeline?.id }),
                );
              }}
              href={getPipelineDesignerPageRoute({ pipeline_id: pipeline?.id })}
              className="button-layer float-right"
            >
              Go to pipeline
            </a>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse"
          >
            <PipelineManagerTableActions
              pipeline={pipeline}
              setConfirmDialogState={setConfirmDialogState}
              setRenameInputID={setRenameInputID}
            />
          </div>
        );
      },
    },
  ];

  const handleDialogConfirm = () => {
    if (confirmDialogState.action === "duplicate") {
      duplicatePipeline();
    } else if (confirmDialogState.action === "archive") {
      archivePipeline();
    } else {
      console.error("Action not found!");
    }
    setConfirmDialogState({ ...confirmDialogState, show: false });
  };

  // A function to call the duplicate pipeline endpoint
  const duplicatePipeline = () => {
    if (_.isNull(confirmDialogState.pipelineID)) return;
    const pipelineName = _.find(pipelines, [
      "id",
      confirmDialogState.pipelineID,
    ])?.name;
    if (!pipelineName) {
      snackbarHelper("Pipeline not found!", "error");
      return;
    }

    fetchNodesOfPipeline(
      {
        pipelineID: confirmDialogState.pipelineID,
      },
      dispatch,
    ).then((nodes) => {
      postDuplicatePipeline(
        {
          name: pipelineName,
          nodes: nodes,
        },
        dispatch,
        setIsLoading,
      ).then(() => updatePipelines());
    });
  };

  // A function to call the delete pipeline endpoint
  const archivePipeline = () => {
    if (_.isNull(confirmDialogState.pipelineID)) return;
    deletePipeline(
      { pipelineID: confirmDialogState.pipelineID },
      dispatch,
      setIsLoading,
    ).then(() => updatePipelines());
  };

  const updatePipelines = () => {
    fetchPipelines(setIsLoading).then((data) => {
      setPipelines(data);
    });
  };

  const renderConfirmationDialog = () => {
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to ${
          confirmDialogState.action
        } the pipeline 
                "${_.find(pipelines, ["id", confirmDialogState.pipelineID])
                  ?.name}"?`}
        confirmButtonText="Confirm"
        confirmButtonClass={
          confirmDialogState.action === "duplicate"
            ? "button-layer bg-paletteGreen hover:bg-paletteGreen-dark text-white"
            : ""
        }
        handleOnSuccess={() => handleDialogConfirm()}
      />
    );
  };

  const handleRenamePipeline = (pipelineID: string, newName: string) => {
    patchPipeline(pipelineID, { name: newName }, dispatch).then(() =>
      updatePipelines(),
    );
  };

  return (
    <>
      <DataTable
        rows={pipelines as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        searchPlaceholder="Search pipeline name"
        isLoading={isLoading}
        searchValue={searchValue}
      />
      {renderConfirmationDialog()}
    </>
  );
};

export default PipelineManagerTable;
