import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { DBBaseModel } from "models/exploration.model";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import {
  addInstanceIDsToSelectedMediaIDs,
  addInstanceIDToSelectedInstanceIDs,
  removeInstanceIDFromSelectedInstanceIDs,
  removeInstanceIDsFromSelectedMediaIDs,
} from "store/explorationInstancesSlice";
import {
  addMediaObjectIDsToSelectedMediaObjectIDs,
  addMediaObjectIDToSelectedMediaObjectIDs,
  removeMediaObjectIDFromSelectedMediaObjectIDs,
  removeMediaObjectIDsFromSelectedMediaObjectIDs,
} from "store/explorationMediaObjectsSlice";
import {
  addMediaIDsToSelectedMediaIDs,
  addMediaIDToSelectedMediaIDs,
  removeMediaIDFromSelectedMediaIDs,
  removeMediaIDsFromSelectedMediaIDs,
} from "store/explorationMediaSlice";

export const handleOnCheckboxClick = (
  event: React.ChangeEvent<HTMLInputElement>,
  params: {
    selectedView: SelectedViewModel;
    isSelectingMultiple: boolean;
    lastSelectedItemID: string | null;
    data: DBBaseModel[];
    clickedItem: DBBaseModel;
    alreadySelectedItems: string[];
  },
  dispatch: Dispatch,
) => {
  const newVal = event?.target?.checked;
  const {
    selectedView,
    isSelectingMultiple,
    lastSelectedItemID,
    data,
    clickedItem,
    alreadySelectedItems,
  } = params;

  let addItemIDToSelectedItemIDs;
  let removeItemIDFromSelectedItemIDs;
  let addItemIDsToSelectedItemIDs;
  let removeItemIDsFromSelectedItemIDs;

  switch (selectedView) {
    case AnnotatableEnum.Media:
      addItemIDToSelectedItemIDs = addMediaIDToSelectedMediaIDs;
      removeItemIDFromSelectedItemIDs = removeMediaIDFromSelectedMediaIDs;
      addItemIDsToSelectedItemIDs = addMediaIDsToSelectedMediaIDs;
      removeItemIDsFromSelectedItemIDs = removeMediaIDsFromSelectedMediaIDs;
      break;
    case AnnotatableEnum.Instance:
      addItemIDToSelectedItemIDs = addInstanceIDToSelectedInstanceIDs;
      removeItemIDFromSelectedItemIDs = removeInstanceIDFromSelectedInstanceIDs;
      addItemIDsToSelectedItemIDs = addInstanceIDsToSelectedMediaIDs;
      removeItemIDsFromSelectedItemIDs = removeInstanceIDsFromSelectedMediaIDs;
      break;
    case AnnotatableEnum.MediaObject:
      addItemIDToSelectedItemIDs = addMediaObjectIDToSelectedMediaObjectIDs;
      removeItemIDFromSelectedItemIDs =
        removeMediaObjectIDFromSelectedMediaObjectIDs;
      addItemIDsToSelectedItemIDs = addMediaObjectIDsToSelectedMediaObjectIDs;
      removeItemIDsFromSelectedItemIDs =
        removeMediaObjectIDsFromSelectedMediaObjectIDs;

      break;
    default:
      throw new Error("Invalid selectedView");
  }

  // Do nothing if is trying to select multiple items but no last selected item is found
  if (isSelectingMultiple && lastSelectedItemID === null) {
    return;
  }

  // If is trying to select multiple items with last selected item found
  if (isSelectingMultiple && lastSelectedItemID !== null) {
    const newItemDsToSelect = getItemIDsArrayFromLastClickedIDToClickedID(
      data,
      lastSelectedItemID,
      clickedItem.id,
    );

    // Do nothing if no items are found
    if (newItemDsToSelect.length === 0) {
      return;
    }

    // Check if all items are already selected,
    // if so, unselect them
    if (_.difference(newItemDsToSelect, alreadySelectedItems).length === 0) {
      dispatch(
        removeItemIDsFromSelectedItemIDs({
          newIDs: newItemDsToSelect,
        }),
      );

      return;
    }
    // if not, select them (or only the missing ones)
    else {
      dispatch(
        addItemIDsToSelectedItemIDs({
          newIDs: newItemDsToSelect,
          clickedID: clickedItem.id,
        }),
      );
      return;
    }
  }

  // Standard single item selection
  if (newVal) {
    dispatch(addItemIDToSelectedItemIDs(clickedItem?.id));
  } else {
    dispatch(removeItemIDFromSelectedItemIDs(clickedItem?.id));
  }
};

export const getItemIDsArrayFromLastClickedIDToClickedID = (
  data: DBBaseModel[],
  lastSelectedID: string,
  clickedID: string,
) => {
  const lastSelectedIndex = _.findIndex(data, {
    id: lastSelectedID,
  });
  const clickedIndex = _.findIndex(data, { id: clickedID });

  // Do nothing if no last selected item is found
  if (lastSelectedIndex === -1) {
    return [];
  }

  // Do nothing if no clicked item is found
  if (clickedIndex === -1) {
    return [];
  }

  // Do nothing if last selected item is the same as clicked item
  if (lastSelectedIndex === clickedIndex) {
    return [];
  }

  // If last selected item is after clicked item
  if (lastSelectedIndex < clickedIndex) {
    const newItemIDsToSelect = data
      .slice(lastSelectedIndex + 1, clickedIndex + 1)
      .map((media) => media.id);

    return newItemIDsToSelect;
  }

  // If last selected item is before clicked item
  if (lastSelectedIndex > clickedIndex) {
    const newItemIDsToSelect = data
      .slice(clickedIndex, lastSelectedIndex)
      .map((media) => media.id);

    return newItemIDsToSelect;
  }

  return [];
};
