import ActionsBar from "components/DetailsScreen/ActionBar";
import { MediaObjectDetailsScreenRouteModel } from "models/routes.model";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchOneMediaAPI,
  fetchMediaObjects,
  fetchOneMediaObjectAPI,
} from "helpers/apis";
import { MediaModel, MediaObjectModel } from "models/exploration.model";
import _ from "lodash";
import { AnnotatableEnum } from "models/global.model";
import ThumbnailsCarousel from "components/DetailsScreen/ThumbnailsCarousel";
import HermesMediaViewer from "components/DetailsScreen/HermesMediaViewer";
import InfoPanel from "components/UtilComponents/InfoPanel";
import {
  convertMediaObjectRawModelResponseToMediaObjectModel,
  convertMediaObjectsRawModelToMediaObjectModel,
} from "helpers/functions/convertMediaObjectsRawModelToMediaObjectModel";
import { setMediaObjectExplorationCurrentMediaObject } from "store/explorationMediaObjectsSlice";
import { fetchMediaObjectsDataScroll } from "helpers/apis/mediaObjects";
import { fetchTags } from "store/datasetSlice";
import isURLStillValid from "helpers/functions/isURLStillValid";
import {
  fetchActiveDataset,
  fetchDatasetSliceData,
} from "helpers/functions/datasets/datasetHelpers";
import getObjectGeometryOfCrop from "helpers/functions/hermes2dPainter/getObjectGeometryOfCrop";
import selectMediaObjectURL from "helpers/functions/hermes2dPainter/selectMediaObjectURL";
import { GeometriesEnum } from "models/geometries.model";
import LidarViewerContainer from "components/DetailsScreen/LidarViewerContainer";
import { fetchMedias } from "helpers/apis/medias";
import { Mediatype } from "models/dataset.model";
import { filterGeometriesBasedOnToggledCategories } from "helpers/functions/detailsScreenHelper";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

const MediaDetailsScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params: MediaObjectDetailsScreenRouteModel = useParams();

  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const subSets = useAppSelector((state) => state.datasetSlice.subSets);
  const detailsScreenData = useAppSelector((state) => state.detailsScreenSlice);

  const [showMediaOrMediaObject, setShowMediaOrMediaObject] = useState<
    AnnotatableEnum.Media | AnnotatableEnum.MediaObject
  >(AnnotatableEnum.MediaObject);

  const explorationMediaObjectsData = useAppSelector(
    (state) => state.explorationMediaObjectsSlice,
  );
  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.mediaObjectsCount?.total_count,
  );
  const mediaObjectsFilterData = useAppSelector(
    (state) => state.filterDataSlice.activeMediaObjectsFilter,
  );
  const mediaObjectsSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeMediaObjects,
  );
  const detailsScreenSlice = useAppSelector(
    (state) => state.detailsScreenSlice,
  );

  const [currentMediaObjects, setCurrentMediaObjects] = useState<
    MediaObjectModel[]
  >([]);
  const [currentMediaObject, setCurrentMediaObject] =
    useState<MediaObjectModel>();
  const [currentMediaObjectIndex, setCurrentMediaObjectIndex] = useState(
    _.findIndex(
      explorationMediaObjectsData?.data,
      (media) =>
        media?.id === explorationMediaObjectsData.currentMediaObject?.id,
    ) ?? 0,
  );

  const [currentMedia, setCurrentMedia] = useState<MediaModel>();
  const [currentPCMedia, setCurrentPCMedia] = useState<MediaModel>();

  const [mediaObjectsOfCurrentMedia, setMediaObjectsOfCurrentMedia] = useState<
    MediaObjectModel[]
  >([]);
  const [hoveredBBID, setHoveredBBID] = useState("");
  const [showLabel, setShowLabel] = useState(true);

  useEffect(() => {
    fetchActiveDataset(dispatch, {
      datasetId: params?.id,
      subset_id: params?.subset_id,
    });
  }, [params?.id, params?.subset_id]);

  useEffect(() => {
    fetchDatasetSliceData(dispatch, dataSetData);
  }, [dataSetData?.activeDataSet]);

  //Initialize tags
  useEffect(() => {
    dispatch(fetchTags({ query: { parentDataSetID: params?.id } }));
  }, []);

  // Set the new media object when currentMediaObject change
  // (when assign/unassign a tag)
  useEffect(() => {
    if (explorationMediaObjectsData.currentMediaObject) {
      setCurrentMediaObject(
        convertMediaObjectRawModelResponseToMediaObjectModel(
          explorationMediaObjectsData.currentMediaObject,
          subSets,
        ),
      );
    }
  }, [explorationMediaObjectsData.currentMediaObject]);

  // Set current media objects to the media objects in store
  // Fetch the media object if no currentMediaObject.
  // Fetch the parent media using the media name.
  // Fetch the media objects of the parent media
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (!_.isNull(explorationMediaObjectsData.currentMediaObject)) {
        const newMediaObject =
          convertMediaObjectRawModelResponseToMediaObjectModel(
            explorationMediaObjectsData.currentMediaObject,
            subSets,
          );
        setCurrentMediaObject(newMediaObject);
        fetchMediaAndMediaObjects(newMediaObject?.media_id);
      } else {
        fetchMediaObjectsByMediaObjectID();
      }
    }, 300);
    return () => clearTimeout(timeoutID);
  }, [params.id, params.mediaObjectID]);

  useEffect(() => {
    const newBBs = convertMediaObjectsRawModelToMediaObjectModel(
      explorationMediaObjectsData.data,
      subSets,
    );
    setCurrentMediaObjects(newBBs);
  }, [explorationMediaObjectsData.data]);

  useEffect(() => {
    if (showMediaOrMediaObject !== AnnotatableEnum.MediaObject) return;

    const url = selectURLtoView();
    if (url === undefined) {
      return;
    }

    const isURLValid = isURLStillValid(url);
    if (isURLValid === null || isURLValid === true) {
      return;
    } else {
      fetchMediaObjectsByMediaObjectID();
    }
  }, [showMediaOrMediaObject, params?.mediaObjectID]);

  useEffect(() => {
    if (detailsScreenData.geometries.three_d_view) {
      if (
        currentMediaObject?.media_object_type === GeometriesEnum.Point2D ||
        currentMediaObject?.media_object_type ===
          GeometriesEnum.Point2DAggregation
      ) {
        fetchMediaAndMediaObjects(currentMediaObject?.media_id);
      }
    }
  }, [detailsScreenData.geometries.three_d_view]);

  const fetchMediaObjectsByMediaObjectID = () => {
    fetchOneMediaObjectAPI(params.mediaObjectID, params?.id)
      .then((response) => {
        setCurrentMediaObject(
          convertMediaObjectRawModelResponseToMediaObjectModel(
            response,
            subSets,
          ),
        );
        fetchMediaAndMediaObjects(response?.media_id);
      })
      .catch((error) =>
        snackbarHelper(
          error?.detail ?? "Media object fetching failed!",
          "error",
        ),
      );
  };

  const fetchMediaAndMediaObjects = (mediaID: string) => {
    fetchOneMediaAPI(mediaID, params?.id)
      .then((newImage) => {
        setCurrentMedia(newImage);

        if (detailsScreenData.geometries.three_d_view) {
          fetchMedias({
            dataset_id: params?.id,
            query: [
              {
                attribute: "scene_id",
                query_operator: "==",
                value: newImage?.scene_id as string,
              },
              {
                attribute: "frame_idx",
                query_operator: "==",
                value: newImage?.frame_idx as number,
              },
              {
                attribute: "media_type",
                query_operator: "==",
                value: Mediatype.point_cloud,
              },
            ],
          }).then((pcMedias) => {
            setCurrentPCMedia(pcMedias?.[0]);
          });
        }
      })
      .catch((error) =>
        snackbarHelper(error?.detail ?? "Media fetching failed!", "error"),
      );

    // Get media objects for the media
    fetchMediaObjects({
      dataset_id: params?.id,
      query: [
        {
          attribute: "media_id",
          query_operator: "==",
          value: mediaID,
        },
      ],
    })
      .then((response) => {
        const newBBs = convertMediaObjectsRawModelToMediaObjectModel(
          response,
          subSets,
        );
        setMediaObjectsOfCurrentMedia(newBBs);
      })
      .catch((error) =>
        snackbarHelper(
          error?.detail ?? "Media object(s) fetching failed!",
          "error",
        ),
      );
  };

  // Update when currentMediaObjectIndex change.
  // Push a new url with the the next media object id.
  // Check if the is the last index, if so fetch the next batch of media objects.
  useEffect(() => {
    if (
      // If the current object is found in the list of objects
      // (when we refresh the page we don't want to fetch objects)
      !_.isUndefined(currentMediaObjectIndex) &&
      currentMediaObjectIndex !== -1 &&
      // If the current object is the last object in the list
      // Only in the case of objects.
      // (Using the carousel to navigate to the last object, should load the objects
      //  before this one, so this is not used when clicking the arrows but
      //  when using the keyboard arrows)
      currentMediaObjectIndex + 1 + 1 > explorationMediaObjectsData.data?.length
    ) {
      fetchNextBatchOfMediaObjects();
    }
    const newMediaObject =
      explorationMediaObjectsData.data?.[currentMediaObjectIndex];
    if (newMediaObject?.id) {
      setShowMediaOrMediaObject(AnnotatableEnum.MediaObject);

      dispatch(setMediaObjectExplorationCurrentMediaObject(newMediaObject));
      history.push(
        `/main/${params?.id}/${params?.subset_id}/mediaObject/${newMediaObject?.id}`,
      );
    }
  }, [currentMediaObjectIndex]);

  const selectURLtoView = () => {
    if (dataSetData?.activeDataSet === null) return;

    if (
      showMediaOrMediaObject === AnnotatableEnum.Media
      // !_.isUndefined(currentMedia)
    ) {
      return currentMedia?.media_url;
    }
    if (
      showMediaOrMediaObject === AnnotatableEnum.MediaObject &&
      currentMediaObject
    ) {
      return selectMediaObjectURL(
        currentMediaObject,
        dataSetData.VisualisationConfiguration,
        dataSetData?.activeDataSet.id,
      );
    }
  };

  const fetchNextBatchOfMediaObjects = () => {
    fetchMediaObjectsDataScroll(
      dispatch,
      explorationMediaObjectsData,
      totalCount,
      params?.id,
      params?.subset_id,
      mediaObjectsFilterData,
      mediaObjectsSortData,
    );
  };

  const geometriesInImageObjectView = () => {
    if (dataSetData?.activeDataSet === null) return [];
    if (
      showMediaOrMediaObject == AnnotatableEnum.MediaObject &&
      currentMediaObject &&
      filterGeometriesBasedOnToggledCategories(
        [currentMediaObject],
        detailsScreenSlice.objectCategories,
      ).length > 0
    ) {
      const cropGeometry = getObjectGeometryOfCrop(
        currentMediaObject,
        dataSetData.VisualisationConfiguration,
        dataSetData?.activeDataSet.id,
      );
      if (cropGeometry) {
        return [...cropGeometry];
      }
    }
    return [];
  };

  const renderSelectedObjectViewer = () => {
    // If the media object is a 2D object, render the 2D viewer

    if (
      _.includes(
        [
          GeometriesEnum.BoundingBox2D,
          GeometriesEnum.BoundingBox2DAggregation,
          GeometriesEnum.Polyline2DFlatCoordinates,
        ],
        currentMediaObject?.media_object_type,
      )
    ) {
      return render2DViewer();
    }

    // if the media object is a point, render the 2D or 3D viewer
    else if (
      _.includes(
        [GeometriesEnum.Point2D, GeometriesEnum.Point2DAggregation],
        currentMediaObject?.media_object_type,
      )
    ) {
      if (detailsScreenData.geometries.three_d_view) {
        return render2DKeypoint3DViewer();
      }

      return render2DViewer();
    }
    // If the media object is a 3D object, render the 3D viewer
    else if (
      _.includes(
        [
          GeometriesEnum.CuboidCenterPoint,
          GeometriesEnum.Point3dXYZAggregation,
          GeometriesEnum.Point3DXYZ,
        ],
        currentMediaObject?.media_object_type,
      )
    ) {
      return render3DViewer();
    }

    // Unsupported media type
    return (
      <div className="w-full h-full">
        Media type ${currentMediaObject?.media_object_type} is not supported!
      </div>
    );
  };

  const render2DViewer = () => {
    return (
      <HermesMediaViewer
        media_url={selectURLtoView() ?? ""}
        mediaObjectGeometries={
          showMediaOrMediaObject === AnnotatableEnum.Media &&
          mediaObjectsOfCurrentMedia
            ? filterGeometriesBasedOnToggledCategories(
                mediaObjectsOfCurrentMedia,
                detailsScreenSlice.objectCategories,
              )
            : []
        }
        allGeometries={mediaObjectsOfCurrentMedia}
        baseShapeGeometries={geometriesInImageObjectView()}
        hoveredBBID={hoveredBBID}
        showLabel={showMediaOrMediaObject === AnnotatableEnum.Media}
        setShowLabel={setShowLabel}
        setHoveredBBID={setHoveredBBID}
      />
    );
  };

  const render3DViewer = () => {
    if (!currentMedia) return null;
    return (
      <LidarViewerContainer
        pcMedia={currentMedia}
        showOnlyOneLabel={{
          show: showMediaOrMediaObject === AnnotatableEnum.MediaObject,
          labelId: currentMediaObject?.id ?? "",
        }}
      />
    );
  };

  const render2DKeypoint3DViewer = () => {
    if (!currentPCMedia) return null;
    return (
      <LidarViewerContainer
        pcMedia={currentPCMedia}
        showOnlyOneLabel={{
          show: showMediaOrMediaObject === AnnotatableEnum.MediaObject,
          labelId: currentMediaObject?.id || "",
        }}
        controls={{
          showFreeView: false,
        }}
      />
    );
  };

  return (
    <div className="w-full h-full pt-3 flex bg-white">
      {/* Main body */}
      <div className="h-full min-w-0 flex-1 flex flex-col">
        {/* Top action bar */}

        <ActionsBar
          currentItemIndex={currentMediaObjectIndex}
          setCurrentItemIndex={setCurrentMediaObjectIndex}
          currentMediaObjects={mediaObjectsOfCurrentMedia}
        />

        {/* Media viewer */}
        <div className="w-full min-h-0 flex-1">
          {renderSelectedObjectViewer()}
        </div>
        {/* Carousel */}
        <div className="h-auto w-[700px] mx-auto my-2">
          <ThumbnailsCarousel
            media={currentMedia}
            onMediaClick={() => {
              if (showMediaOrMediaObject === AnnotatableEnum.Media) {
                setShowMediaOrMediaObject(AnnotatableEnum.MediaObject);
              } else {
                setShowMediaOrMediaObject(AnnotatableEnum.Media);
              }
            }}
            mediaObjects={currentMediaObjects}
            currentMediaObjectIndex={currentMediaObjectIndex}
            setCurrentMediaObjectIndex={setCurrentMediaObjectIndex}
            explorationMediaObjectsLoading={explorationMediaObjectsData.loading}
            onMediaObjectHover={(hoveredMediaObject) => {
              setHoveredBBID(hoveredMediaObject?.id);
              setShowLabel(false);
            }}
            onMediaObjectClick={(clickedMediaObjectID: string) => {
              // Toggle between media and media object if clicked on the current media object
              if (currentMediaObject?.id === clickedMediaObjectID) {
                if (showMediaOrMediaObject === AnnotatableEnum.MediaObject) {
                  setShowMediaOrMediaObject(AnnotatableEnum.Media);
                } else {
                  setShowMediaOrMediaObject(AnnotatableEnum.MediaObject);
                }
              }
              // Select new media object (not the current media object)
              else {
                const clickedMediaObject = _.find(
                  currentMediaObjects,
                  (mediaObject) => mediaObject?.id === clickedMediaObjectID,
                );
                setCurrentMediaObjectIndex(
                  _.findIndex(
                    explorationMediaObjectsData.data,
                    (obj) => obj.id === clickedMediaObjectID,
                  ),
                );
                setCurrentMediaObject(clickedMediaObject);
                setShowMediaOrMediaObject(AnnotatableEnum.MediaObject);
              }
            }}
            selectedItemID={
              showMediaOrMediaObject === AnnotatableEnum.Media
                ? currentMedia?.id ?? ""
                : currentMediaObject?.id ?? ""
            }
            handleOnReachEnd={() => {
              // For when using the carousle arrows
              fetchNextBatchOfMediaObjects();
            }}
          />
        </div>
      </div>

      {/* Info Panel */}
      <InfoPanel
        showMediaOrMediaObject={showMediaOrMediaObject}
        currentMedia={currentMedia}
        currentMediaObject={currentMediaObject}
        fetchMediaAndMediaObjects={fetchMediaAndMediaObjects}
      />
    </div>
  );
};

export default MediaDetailsScreen;
