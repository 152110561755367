import {
  APIDelete,
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import { DatasetModel } from "models/dataset.model";
import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { VisibilityStatus } from "models/global.model";

export const fetchDatasets = async (
  subset: boolean | undefined,
  visibility_statuses: VisibilityStatus[] = [VisibilityStatus.Visible],
  setIsLoading?: (isLoading: boolean) => void,
): Promise<DatasetModel[]> => {
  setIsLoading && setIsLoading(true);
  // Here we ensure subset is a boolean (defaulting to true if undefined) and turn it into a string.
  const subsetStr = String(subset ?? false);
  const response = await APIFetchAxios("/datasets", {
    subset: subsetStr,
    visibility_statuses: visibility_statuses,
  })
    .then((response) => {
      setIsLoading && setIsLoading(false);
      return response;
    })
    .catch((error: AxiosError<any>) => {
      setIsLoading && setIsLoading(false);
      throw error;
    });
  return response?.data;
};

/**
 * An endpoint to fetch a dataset/subset using its id
 * @param datasetID The id of the dataset/subset to fetch
 * @param dispatch - The dispatch function
 * @returns The dataset/subset with the given id
 */
export const getDataset = async (
  params: {
    datasetID: string;
  },
  dispatch?: Dispatch,
): Promise<DatasetModel> => {
  const response = await APIFetchAxios(`/datasets/${params?.datasetID}`).catch(
    (error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      dispatch &&
        snackbarHelper(
          errorDetail
            ? `Dataset fetching failed: ${errorDetail}`
            : "Dataset fetching failed!",
          "error",
        );
    },
  );
  return response?.data;
};

/**
 * An endpoint to change the color of a subset
 * @param subsetID The id of the subset that will change color
 * @param newColor The new color in color hex
 * @returns The subset with the changed color
 */
export const postSubsetColorChange = async (
  subsetID: string,
  newColor: string,
): Promise<DatasetModel> => {
  const response = await APIPostWithBodyAxios(
    `/datasets/${subsetID}/color/${newColor?.replace("#", "")}`,
    {},
  );
  return response?.data;
};

/**
 * An endpoint to create task inputs for a subset
 * @param subsetId The subset ID of the subset to create task inputs for
 * @param body The body to post the subset task input
 * @param body.crop Whether to use the crop or the entire media
 * @param body.color_map The mapping from object_category to color
 * @param dispatch Dispatch function to dispatch actions
 * @param displaySnackbar Whether to display a snackbar on success or error
 * @param setIsLoading Set the loading state of the component
 */
export const postSubsetTaskInput = async (
  subsetId: string,
  body: { crop: boolean; color_map: Record<string, string> },
  dispatch: Dispatch,
  displaySnackbar: boolean = true,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  return APIPostWithBodyAxios(`/subsets/${subsetId}/taskInputs`, body)
    .then(() => {
      if (displaySnackbar) {
        snackbarHelper("Successfully created task inputs!", "success");
      }
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      if (displaySnackbar) {
        snackbarHelper(
          `Attributes archive: ${errorDetail}` ||
            "An error occurred during the creation of the task inputs.",
          "error",
        );
      }
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

export const patchDatasetMeta = async (
  query: {
    datasetID: string;
    dataset: { name?: string; color?: string; is_anonymized?: boolean };
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { datasetID, dataset } = query;
  setIsLoading && setIsLoading(true);

  await APIPatchWithBodyAxios(`/datasets/${datasetID}`, dataset)
    .then(() => {
      snackbarHelper("Successfully patched dataset/subset!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Dataset/subset patch error: ${errorDetail}` ||
          "An error occurred during the patching of the dataset/subset.",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to delete a dataset
 * @param query
 * @param query.datasetID The id of the dataset to be deleted
 * @param dispatch Dispatch function from redux
 * @param setIsLoading Set the loading state of the page
 */
export const deleteDataset = async (
  query: { datasetID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { datasetID } = query;
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/datasets/${datasetID}`)
    .then(() => {
      snackbarHelper("Dataset deleted successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Dataset delete: ${errorDetail}` || "Dataset deleting failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to create a threshold analysis to tag different attributes
 *  based on an ambigyity threshold and expected value (answer)
 * @param query
 * @param query.datasetID The id of the dataset to be deleted
 * @param query.base_name The tag base name to be used to create the tags
 * @param query.attribute_inputs The list of attributes to be tagged
 * @param query.attribute_inputs.attribute_id The id of the attribute to be tagged
 * @param query.attribute_inputs.threshold The threshold to be used to tag the attribute
 * @param query.attribute_inputs.expected_value The expected value (answer or value) to be used to tag the attribute
 * @param dispatch Dispatch function from redux
 * @param setIsLoading Set the loading state of the page
 */
export const postThresholdAnalysis = async (
  query: {
    datasetID: string;
    base_name: string;
    attribute_inputs: {
      attribute_id: string;
      threshold: number;
      expected_value: string;
    }[];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { datasetID } = query;
  setIsLoading && setIsLoading(true);
  snackbarHelper("Creating threshold analysis...", "info");
  await APIPostWithBodyAxios(`/datasets/${datasetID}/threshold_analysis`, query)
    .then(() => {
      snackbarHelper("Threshold analysis was done successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        `Threshold analysis: ${errorDetail}` || "Threshold analysis failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};
