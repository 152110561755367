import { toast } from "react-toastify";

import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as CheckMarkIcon } from "assets/check_mark.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";

import { ToastOptions } from "react-toastify/dist/types";

// This implementation of the custom toaster allows to pass an HTML string to the body of the toast message.
// It is a temporary workaround until we find a better solution for this.

const HtmlNotification = (message: string) => {
  const htmlMessage = `<p>${message}</p>`;
  // TODO: this must be replaced by proper HTML parsing, this is just a hotfix to unblock production deployment,
  //  since we are only passing our own backend messages here, we are not exposing the app to XSS attacks
  return <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />;
};

const userNotificationToaster = (message: string, toastProps: ToastOptions) =>
  toast(HtmlNotification(message), { ...toastProps });

userNotificationToaster.success = (
  message: string,
  toastProps?: ToastOptions,
) =>
  toast.success(HtmlNotification(message), {
    ...toastProps,
    icon: (
      <CheckMarkIcon className="w-3 h-3 text-paletteGreen" strokeWidth={2} />
    ),
  });

userNotificationToaster.info = (message: string, toastProps?: ToastOptions) =>
  toast.info(HtmlNotification(message), {
    ...toastProps,
    icon: <InfoIcon className="w-3 h-3 text-paletteBlue" strokeWidth={2} />,
  });

userNotificationToaster.warning = (
  message: string,
  toastProps?: ToastOptions,
) =>
  toast.warning(HtmlNotification(message), {
    ...toastProps,
    icon: (
      <WarningIcon className="w-3 h-3 text-paletteYellow" strokeWidth={2} />
    ),
  });

userNotificationToaster.error = (message: string, toastProps?: ToastOptions) =>
  toast.error(HtmlNotification(message), {
    ...toastProps,
    icon: <InfoIcon className="w-3 h-3 text-paletteRed" strokeWidth={2} />,
  });

export default userNotificationToaster;
