import SimpleSelect from "components/Internal/Inputs/Form/SimpleSelect";
import _ from "lodash";
import { annotationUIs, UICategories } from "schemas/annotationUIs";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setConfigurationsFormField,
  setGUIFormData,
  setIsEditingUIConfig,
  setSubsetTypeWhitelist,
  setUIcategory,
  setUIType,
} from "store/pipelineDesignerSlice";
import initialFormData from "schemas/UserInterfaceInitFormData";
import { SelectFieldModel, SelectFieldOptionModel } from "models/form.model";
import { Mediatype } from "models/dataset.model";
import {
  UICategoriesEnum,
  UICategoriesType,
  UIsEnum,
} from "schemas/annotationUIs.model";
import { VisualisationTypeEnum } from "models/pipelines.model";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";

const SelectGUI = () => {
  const { keycloak } = useKeycloak();

  const dispatch = useAppDispatch();
  const pipelineSlice = useAppSelector((state) => state.pipelineDesignerSlice);
  const uiCategory = pipelineSlice.uiCategory;
  const uiType = pipelineSlice.uiType;
  const mediaType = pipelineSlice.configurationsForm?.["media_type"];
  const visualisationType =
    pipelineSlice.configurationsForm?.["visualisation_type"];
  const isUiTypeSelected = pipelineSlice.validation.isUiTypeSelected;

  const renderUICategorySelect = () => {
    const options = _.map(UICategoriesEnum, (value, key) => {
      return {
        label: value,
        value: key,
      };
    });
    return (
      <SimpleSelect
        field={{
          type: "select",
          key: "gui_category",
          label: "Select a task category",
          placeholder: "Select a task category",
          value: uiCategory || "",
          options: options,
          required: true,
          error: isUiTypeSelected === false ? "Please select a category" : "",
        }}
        value={uiCategory || ""}
        handleOnChange={(e) => {
          dispatch(setUIcategory(e.target.value as UICategoriesType));
          changeUiTypeValue(null);
          changeMediaTypeValue("");
          changeVisualisationTypeValue(VisualisationTypeEnum.DEFAULT);
          dispatch(setSubsetTypeWhitelist(null));
        }}
      />
    );
  };

  const renderSelectUITypeSelect = () => {
    let options: SelectFieldOptionModel[] = [];
    if (uiCategory !== null) {
      const uisInCategory = UICategories[uiCategory];
      _.map(uisInCategory, (value) => {
        if (
          value === UIsEnum.video_frame_slider_2d &&
          !checkIfUserHavePermission(
            keycloak,
            "accessPipelineVideoFrameSlider2DUIFeature",
          )
        ) {
          return;
        }
        options = [
          ...options,
          {
            label: annotationUIs[value]?.name,
            value: annotationUIs[value]?.key,
          },
        ];
      });
    }
    return (
      <SimpleSelect
        field={{
          type: "select",
          key: "ui_type",
          label: "Select an annotation UI type",
          placeholder: "Select an annotation UI type",
          value: uiType || "",
          options: options,
          disabled: uiCategory === null,
          required: true,
          error:
            isUiTypeSelected === false ? "Please select an annotation UI" : "",
        }}
        value={uiType || ""}
        handleOnChange={(e) => {
          changeUiTypeValue(e.target.value as UIsEnum);
          changeMediaTypeValue("");
          changeVisualisationTypeValue(VisualisationTypeEnum.DEFAULT);
          dispatch(setSubsetTypeWhitelist(null));
        }}
      />
    );
  };

  const renderMediaTypeSelect = () => {
    let options: SelectFieldOptionModel[] = [];
    if (uiType !== null) {
      const mediaTypes = annotationUIs?.[uiType]?.mediaTypeOptions;
      options = _.map(mediaTypes, (value, key) => {
        return {
          label: _.upperFirst(key),
          value: key,
        };
      });
    }

    const value = mediaType?.value as string;
    return (
      <SimpleSelect
        field={
          {
            ...mediaType,
            options: options,
            disabled: pipelineSlice.uiType === null,
          } as SelectFieldModel
        }
        value={value}
        handleOnChange={(e) => {
          dispatch(
            setConfigurationsFormField({
              ...mediaType,
              value: e.target.value,
            } as SelectFieldModel),
          );
          changeVisualisationTypeValue(VisualisationTypeEnum.DEFAULT);
          changeSubsetTypeWhitelist(
            e.target.value as Mediatype,
            VisualisationTypeEnum.DEFAULT,
          );
        }}
      />
    );
  };

  const renderVisualisationTypeSelect = () => {
    let options: SelectFieldOptionModel[] = [];

    if (uiType !== null && !_.isEmpty(mediaType?.value)) {
      const visualisationTypes =
        annotationUIs[uiType].mediaTypeOptions?.[
          mediaType?.value as Mediatype
        ] || [];

      options = _.map(visualisationTypes, (value, key) => {
        return {
          label: key,
          value: key,
        };
      });
    }

    const value = (visualisationType?.value as string) || "";
    return (
      <SimpleSelect
        field={
          {
            ...visualisationType,
            options: options,
            disabled: _.isEmpty(mediaType?.value),
          } as SelectFieldModel
        }
        value={value}
        handleOnChange={(e) => {
          dispatch(
            setConfigurationsFormField({
              ...visualisationType,
              value: e.target.value,
            } as SelectFieldModel),
          );
          setInitialFormData();
          changeSubsetTypeWhitelist(
            mediaType.value as Mediatype,
            e.target.value as VisualisationTypeEnum,
          );
        }}
      />
    );
  };

  const setInitialFormData = () => {
    if (pipelineSlice.uiType === null) return;

    const initialSelectedFormData =
      annotationUIs[pipelineSlice.uiType]?.formData;

    const selectedFormData = {
      ...initialFormData,
      inputType: {
        ...initialFormData?.inputType,
        BoundingBox2DPainter: {
          ...initialFormData?.inputType?.BoundingBox2DPainter,
          ...initialSelectedFormData?.inputType?.BoundingBox2DPainter,
        },
        Viewer3D: {
          ...initialFormData?.inputType?.Viewer3D,
          ...initialSelectedFormData?.inputType?.Viewer3D,
        },
        VideoRenderer: {
          ...initialFormData?.inputType?.VideoRenderer,
          ...initialSelectedFormData?.inputType?.VideoRenderer,
        },
      },
      toolbars: initialSelectedFormData?.toolbars,
      uiSettings: {
        ...initialFormData?.uiSettings,
        ...initialSelectedFormData?.uiSettings,
      },
    };

    dispatch(setGUIFormData(selectedFormData));
  };

  const changeUiTypeValue = (value: UIsEnum | null) => {
    dispatch(setUIType(value));
  };

  const changeMediaTypeValue = (value: string) => {
    dispatch(
      setConfigurationsFormField({
        ...mediaType,
        value: value,
      } as SelectFieldModel),
    );
  };

  const changeVisualisationTypeValue = (value: string) => {
    dispatch(
      setConfigurationsFormField({
        ...visualisationType,
        value: value,
      } as SelectFieldModel),
    );
  };
  const changeSubsetTypeWhitelist = (
    mediaTypeProp: Mediatype,
    visualisationTypeProp: VisualisationTypeEnum,
  ) => {
    const subsetTypeWhitelist =
      annotationUIs[uiType as UIsEnum]?.mediaTypeOptions[mediaTypeProp]?.[
        visualisationTypeProp
      ] ?? [];

    dispatch(setSubsetTypeWhitelist(subsetTypeWhitelist));
  };

  const renderShowUIConfigButton = () => {
    return (
      <button
        className="button-layer float-right mt-4"
        onClick={() => {
          dispatch(setIsEditingUIConfig(false));
          setInitialFormData();
        }}
        disabled={
          uiCategory === null ||
          uiType === null ||
          _.isEmpty(mediaType?.value) ||
          _.isEmpty(visualisationType?.value)
        }
      >
        Next
      </button>
    );
  };

  return (
    <div
      className="flex px-2 flex-col gap-y-2"
      data-test="ui_config_pipeline_designer"
    >
      {renderUICategorySelect()}
      {renderSelectUITypeSelect()}
      {renderMediaTypeSelect()}
      {renderVisualisationTypeSelect()}
      <div>{renderShowUIConfigButton()}</div>
    </div>
  );
};

export default SelectGUI;
