import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import { setInitialAttribute } from "store/pipelineDesignerSlice";
import { Dialog } from "@mui/material";
import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { AttributeMetadataModel } from "models/attributes.model";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { getAnnotatableTypeFromSubsetType } from "helpers/functions/datasets/datasetHelpers";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
};

const SelectInitialAttributeDialog = ({ openDialog, setOpenDialog }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const datasetId = useAppSelector(
    (state) => state.pipelineDesignerSlice.dataset?.id,
  );
  const subset = useAppSelector((state) => state.pipelineDesignerSlice.subset);
  const [initialAttributes, setInitialAttributes] = useState<
    AttributeMetadataModel[] | null
  >(null);

  useEffect(() => {
    if (datasetId && subset) {
      setIsLoading(true);

      fetchAttributesMetaData({
        datasetID: datasetId,
        query: [
          {
            attribute: "attribute_group",
            query_operator: "==",
            value: "initial_attribute",
          },
          {
            attribute: "annotatable_type",
            query_operator: "==",
            value: getAnnotatableTypeFromSubsetType(subset.subset_type),
          },
        ],
      })
        .then((data) => {
          setInitialAttributes(data);
          dispatch(setInitialAttribute(null));
        })
        .catch((error) => {
          const errorMessage =
            error?.msg || `Failed to fetch initial attributes!`;
          snackbarHelper(errorMessage, "error");
        })
        .finally(() => setIsLoading(false));
    }
  }, [datasetId]);

  const initialAttributeColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 60,
      className: "font-bold",
    },
    {
      field: "annotatable_type",
      headerName: "Type",
      span: 30,
      cell: (row) => {
        const attr = row as AttributeMetadataModel;
        return (
          <TooltipTruncateEllipsis>
            {attr.annotatable_type === "MediaObject"
              ? "Object"
              : attr.annotatable_type}
          </TooltipTruncateEllipsis>
        );
      },
    },
  ];

  const renderInitialAttributeTable = () => {
    return (
      <DataTable
        key="initial-attribute-table"
        rows={initialAttributes as []}
        columns={initialAttributeColumns}
        onRowClick={(row) => {
          const attr = row as AttributeMetadataModel;
          handleSelectInitialAttribute(attr);
        }}
        defaultSort={{ name: "name", direction: "asc" }}
        enableSearch={true}
        searchPlaceholder={"Search initial attributes..."}
        isLoading={isLoading}
      />
    );
  };

  const handleSelectInitialAttribute = (selectedIA: AttributeMetadataModel) => {
    dispatch(
      setInitialAttribute({
        id: selectedIA?.id,
        name: selectedIA?.name,
      }),
    );

    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth={true}
      maxWidth="lg"
    >
      <div className="h-full w-full flex flex-col gap-y-2">
        <div className="p-6 text-xl text-paletteBlack-2">
          Select initial attribute
        </div>

        <div className="h-[550px] px-6">{renderInitialAttributeTable()}</div>
      </div>
    </Dialog>
  );
};

export default SelectInitialAttributeDialog;
