import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CurvePoints } from "models/mlAnnotationModel.model";
import _ from "lodash";

type Props = {
  curve?: CurvePoints | null;
};

const AutomationCorrectnessCurve = ({ curve }: Props) => {
  type Point = {
    x: number;
    y: number;
  };

  const convertToPoints = (curve: CurvePoints | null) => {
    if (!curve) {
      return [];
    }
    const { automation_degree, accuracy } = curve;
    if (automation_degree.length !== accuracy.length) {
      return [];
    }

    return automation_degree.map((degree, index) => ({
      x: _.round(degree * 100, 2),
      y: _.round(accuracy[index] * 100, 2),
    }));
  };

  const getAccuracyRange = () => {
    if (!curve) {
      return undefined;
    }

    const points: Point[] = convertToPoints(curve);
    const accuracyPoints = points.map((point) => point.y);
    const min = _.min(accuracyPoints);
    const max = _.max(accuracyPoints);
    if (!min || !max) {
      return undefined;
    }
    const minOffset = 5;
    return [min - minOffset, max];
  };

  const renderCurve = (points: Point[]) => {
    return (
      <div className="w-full h-[238px]">
        <ResponsiveContainer width="100%" height={238}>
          <AreaChart
            data={points}
            margin={{ top: 10, right: 15, left: 0, bottom: 20 }}
          >
            <Tooltip content={CustomTooltip} />
            <XAxis
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              dataKey="x"
              domain={[0, 100]}
              type="number"
              tick={{ fontSize: "12px" }}
            >
              <Label
                position="insideBottom"
                offset={-7}
                style={{ fill: "#333333", fontSize: "12px" }}
              >
                Automation degree [%]
              </Label>
            </XAxis>
            <YAxis
              dataKey="y"
              type="number"
              allowDataOverflow={true}
              tickCount={7}
              domain={getAccuracyRange()}
              tickFormatter={(value) => `${_.round(value, 0)}`}
              tick={{ fontSize: "12px" }}
            >
              <Label
                position="insideBottomLeft"
                offset={15}
                angle={-90}
                style={{ fill: "#333333", fontSize: "12px" }}
              >
                Model correctness [%]
              </Label>
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Area
              type="monotone"
              dataKey="y"
              stackId="1"
              stroke="#FB8A02"
              fill="#FB8A02"
              opacity={0.8}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="bg-white border-paletteGray-10 py-1 px-2
          rounded-[4px] shadow-md text-sm text-paletteGray-11
        "
        >
          <div className="text-sm">Automation: {`${label}%`}</div>
          <div className="text-sm">
            Correctness: {`${payload?.[0]?.value}%`}
          </div>
        </div>
      );
    }
  };

  // Create an array to hold the points
  if (!curve) {
    return (
      <div className="flex w-full h-[238px] justify-center items-center">
        Automation Correctness Curve not available
      </div>
    );
  } else {
    const points: Point[] = convertToPoints(curve);
    return renderCurve(points);
  }
};
export default AutomationCorrectnessCurve;
