import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  ExplorationAPIRequestModel,
  InstanceModel,
} from "models/exploration.model";
import { SendFilterModel } from "models/filter.model";
import subsetQuery from "helpers/functions/subsetQuery";
import { fetchInstances, fetchOneInstanceAPI } from "helpers/apis/instances";

export const fetchInstanceData = createAsyncThunk(
  "explorationInstance/fetchInstanceData",
  async (meta?: {
    runId: string;
    reqBody: ExplorationAPIRequestModel;
    subSetId: string;
    reset?: boolean;
    skipLoading?: boolean;
  }) => {
    let instReqBody: ExplorationAPIRequestModel = {
      ...meta?.reqBody,
      ["dataset_id"]: meta?.runId || "",
    };

    // Inject subset id as a categorical filter if "subSetId" parameter is passed
    if (meta?.subSetId !== "main_dataset") {
      const filters: SendFilterModel[] = instReqBody?.query
        ? [...instReqBody?.query]
        : [];
      instReqBody = {
        ...instReqBody,
        query: [...filters, subsetQuery(meta?.subSetId || "")],
      };
    }

    if (_.isUndefined(instReqBody?.projection)) {
      instReqBody = { ...instReqBody, projection: null };
    }

    const response = await fetchInstances(instReqBody);
    return { data: response, meta: meta };
  },
);

export const fetchOneInstance = createAsyncThunk(
  "explorationInstance/fetchOneInstance",
  async (meta: {
    query: { instanceId: string; datasetId: string };
    options?: { replaceCurrentInstance: boolean };
  }) => {
    const response = await fetchOneInstanceAPI(
      meta?.query?.instanceId,
      meta?.query?.datasetId,
    );
    return { data: response, meta: meta };
  },
);

export interface explorationInstanceStateTypes {
  data: InstanceModel[];
  loading: boolean;
  error: { message: string };
  hasMore: boolean;
  skip: number;
  currentInstance: InstanceModel | null;
  selectedInstanceIDs: string[];
  lastSelectedInstanceID: string | null;
}

const initialState = {
  data: [],
  loading: false,
  error: { message: "" },
  hasMore: true,
  skip: 0,
  currentInstance: null,
  selectedInstanceIDs: [],
  lastSelectedInstanceID: null,
} as explorationInstanceStateTypes;

export const explorationInstanceSlice = createSlice({
  name: "explorationInstance",
  initialState,
  reducers: {
    updateInstanceExplorationHasMore: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasMore = action?.payload;
    },
    updateInstanceExplorationSkip: (state, action: PayloadAction<number>) => {
      state.skip = action?.payload;
    },
    setInstanceExplorationCurrentInstance: (
      state,
      action: PayloadAction<InstanceModel>,
    ) => {
      state.currentInstance = action?.payload;
    },
    addInstanceIDToSelectedInstanceIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedInstanceIDs = [
        ...state.selectedInstanceIDs,
        action?.payload,
      ];
      state.lastSelectedInstanceID = action?.payload;
    },
    removeInstanceIDFromSelectedInstanceIDs: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedInstanceIDs = _.filter(
        state.selectedInstanceIDs,
        (id) => id !== action?.payload,
      );
      // Get the instance before the deleted one
      const lastSelectedInstanceID =
        state.selectedInstanceIDs[state.selectedInstanceIDs.length - 1];
      state.lastSelectedInstanceID = lastSelectedInstanceID || null;
    },
    addInstanceIDsToSelectedMediaIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
        clickedID: string;
      }>,
    ) => {
      const newInstanceSet = new Set(state.selectedInstanceIDs);
      action?.payload?.newIDs.map((id) => newInstanceSet.add(id));
      state.selectedInstanceIDs = [...Array.from(newInstanceSet)];
      state.lastSelectedInstanceID = action?.payload?.clickedID;
    },
    removeInstanceIDsFromSelectedMediaIDs: (
      state,
      action: PayloadAction<{
        newIDs: string[];
      }>,
    ) => {
      const newInstanceSet = new Set(state.selectedInstanceIDs);
      action?.payload?.newIDs.map((id) => newInstanceSet.delete(id));
      state.selectedInstanceIDs = [...Array.from(newInstanceSet)];
    },
    unSelectAllSelectedInstanceIDs: (state) => {
      state.selectedInstanceIDs = [];
      state.lastSelectedInstanceID = null;
    },
    resetExplorationInstanceSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchInstanceData reducer
    builder.addCase(
      fetchInstanceData.pending,
      (state: explorationInstanceStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchInstanceData.fulfilled,
      (state: explorationInstanceStateTypes, action) => {
        if (action?.meta?.arg?.reset) {
          state.data = [...action.payload?.data];
          state.hasMore = true;
          state.skip = 0;
        } else {
          // Check if any instance duplication found
          let foundDuplicate = false;
          let numberOfDuplicates = 0;
          _.map(action.payload.data, (i) => {
            if (_.find(state.data, (t) => t?.id === i?.id)) {
              foundDuplicate = true;
              numberOfDuplicates++;
            }
          });
          if (foundDuplicate) {
            alert(
              `Found ${numberOfDuplicates} duplicate instances! Please refresh the page. If this keeps happening, please contact us!`,
            );
          }
          state.data = [...state.data, ...action.payload?.data];
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchInstanceData.rejected,
      (state: explorationInstanceStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchOneInstance reducer
    builder.addCase(
      fetchOneInstance.fulfilled,
      (state: explorationInstanceStateTypes, action) => {
        const newInstance = action.payload?.data;
        const instanceIndex = _.findIndex(
          state.data,
          (instance) =>
            instance?.id === action.payload?.meta?.query?.instanceId,
        );
        if (_.isNumber(instanceIndex)) {
          state.data[instanceIndex] = newInstance;
        }
        if (action.payload.meta?.options?.replaceCurrentInstance) {
          state.currentInstance = newInstance;
        }
      },
    );
    builder.addCase(
      fetchOneInstance.rejected,
      (state: explorationInstanceStateTypes, action) => {
        state.error.message = action.error.message || "No error provided";
      },
    );
  },
});

export const {
  updateInstanceExplorationHasMore,
  updateInstanceExplorationSkip,
  setInstanceExplorationCurrentInstance,
  addInstanceIDToSelectedInstanceIDs,
  removeInstanceIDFromSelectedInstanceIDs,
  addInstanceIDsToSelectedMediaIDs,
  removeInstanceIDsFromSelectedMediaIDs,
  unSelectAllSelectedInstanceIDs,
  resetExplorationInstanceSlice,
} = explorationInstanceSlice.actions;
export default explorationInstanceSlice.reducer;
