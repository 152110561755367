import { AnnotatableEnum } from "models/global.model";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { explorationInstanceStateTypes } from "store/explorationInstancesSlice";
import { explorationMediaObjectsStateTypes } from "store/explorationMediaObjectsSlice";
import { explorationMediaStateTypes } from "store/explorationMediaSlice";
import { useAppSelector } from "store/hooks";

export const useSelectWithShiftKey = () => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const explorationMediaData = useAppSelector(
    (state) => state.explorationMediaSlice,
  );

  const explorationMediaObjectsSlice = useAppSelector(
    (state) => state.explorationMediaObjectsSlice,
  );

  const explorationInstanceSlice = useAppSelector(
    (state) => state.explorationInstanceSlice,
  );

  let data:
    | explorationMediaStateTypes
    | explorationMediaObjectsStateTypes
    | explorationInstanceStateTypes = explorationMediaData;
  let lastSelectedItemID = data.lastSelectedMediaID;
  let selectedItemIDs = data.selectedMediaIDs;
  if (selectedView === AnnotatableEnum.MediaObject) {
    data = explorationMediaObjectsSlice;
    lastSelectedItemID = data.lastSelectedMediaObjectID;
    selectedItemIDs = data.selectedMediaObjectsIDs;
  }
  if (selectedView === AnnotatableEnum.Instance) {
    data = explorationInstanceSlice;
    lastSelectedItemID = data.lastSelectedInstanceID;
    selectedItemIDs = data.selectedInstanceIDs;
  }

  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false);

  useHotkeys(
    "shift",
    () => lastSelectedItemID !== null && setIsSelectingMultiple(true),
    { keydown: true, enableOnFormTags: ["input"] },
    [selectedItemIDs],
  );
  useHotkeys("shift", () => setIsSelectingMultiple(false), {
    keyup: true,
    enableOnFormTags: ["input"],
  });

  return {
    isSelectingMultiple,
  };
};

export default useSelectWithShiftKey;
