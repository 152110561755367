import { useAppDispatch } from "store/hooks";
import { ReactComponent as Plus } from "assets/plus.svg";
import { setIsCreatingNewAIModelModalOpen } from "store/aiManagerSlice";

const CreateNewAIModelButton = () => {
  const dispatch = useAppDispatch();

  return (
    <button
      className="button-dark-gray-layer w-fit"
      onClick={() => dispatch(setIsCreatingNewAIModelModalOpen(true))}
    >
      <Plus className={"w-4 h-4"} />
      Create new AI model
    </button>
  );
};

export default CreateNewAIModelButton;
