import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { AttributeMetadataModel } from "models/attributes.model";
import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { DatasetModel } from "models/dataset.model";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import NewTrainingSetAttributeSubsetSelections from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetAttributeList/NewTrainingSetAttributeSubsetSelections";
import NewTrainingAttribute from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetAttributeList/NewTrainingAttribute";
import { TrainingSetAttribute } from "models/trainingSet.model";

interface Props {
  isCreatingNewTrainingSet: boolean;
}

const NewTrainingSetAttributeList = ({ isCreatingNewTrainingSet }: Props) => {
  const dispatch = useAppDispatch();

  const [allAttributesMetaList, setAllAttributesMetaList] =
    useState<AttributeMetadataModel[]>();
  const [isDragging, setIsDragging] = useState(false);
  const [draggedAttributeIndex, setDraggedAttributeIndex] = useState<
    null | number
  >(null);
  const [draggedOverAttributeIndex, setDraggedOverAttributeIndex] = useState<
    null | number
  >(null);

  const [allSubsets, setAllSubsets] = useState<DatasetModel[]>([]);
  const [isSubsetDialogOpen, setIsSubsetDialogOpen] = useState(false);
  const [selectedAttribute, setSelectedAttribute] =
    useState<TrainingSetAttribute | null>(null);

  const newTrainingSet = useAppSelector(
    (state) => state.aiManagerSlice.newTrainingSet,
  );
  const selectedDataset = newTrainingSet.dataset;

  useEffect(() => {
    if (selectedDataset === null) return;

    fetchAttributesMetaData({
      datasetID: selectedDataset?.id,
    }).then((newList) => {
      setAllAttributesMetaList(newList);
    });

    getSubsetsForDataset(selectedDataset?.id, dispatch).then((data) => {
      if (data !== null) {
        setAllSubsets(data);
      }
    });
  }, [selectedDataset]);

  const renderAttributeList = () => {
    return _.map(newTrainingSet.training_attributes, (attribute, index) => {
      return (
        <NewTrainingAttribute
          key={attribute.attribute_id}
          trainingSetAttribute={attribute}
          index={index}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          draggedAttributeIndex={draggedAttributeIndex}
          setDraggedAttributeIndex={setDraggedAttributeIndex}
          draggedOverAttributeIndex={draggedOverAttributeIndex}
          setDraggedOverAttributeIndex={setDraggedOverAttributeIndex}
          setIsSubsetDialogOpen={setIsSubsetDialogOpen}
          setSelectedAttribute={setSelectedAttribute}
          allAttributesMetaList={allAttributesMetaList}
          allSubsets={allSubsets}
        />
      );
    });
  };

  const renderDisableLayerOnLoading = () => {
    if (!isCreatingNewTrainingSet) return null;

    return (
      <div className="absolute top-0 left-0 w-full h-full bg-paletteGray-1 bg-opacity-90 flex items-center justify-center">
        <div className="spinner" />
      </div>
    );
  };

  return (
    <div className="flex flex-col relative">
      {renderAttributeList()}

      {renderDisableLayerOnLoading()}

      {/* Render attribute subset selection dialog */}
      {isSubsetDialogOpen && (
        <NewTrainingSetAttributeSubsetSelections
          isSubsetDialogOpen={isSubsetDialogOpen}
          setIsSubsetDialogOpen={setIsSubsetDialogOpen}
          selectedAttribute={selectedAttribute}
        />
      )}
    </div>
  );
};

export default NewTrainingSetAttributeList;
