import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchMediaData } from "store/explorationMediaSlice";
import { fetchMediaObjectsData } from "store/explorationMediaObjectsSlice";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import Drawer from "@mui/material/Drawer";
import { useHotkeys } from "react-hotkeys-hook";
import {
  FilterCategoryEnum,
  FilterModel,
  FiltersModel,
  SavedFilterModel,
} from "models/filter.model";
import convertSendFilterModelToFilterModel from "helpers/functions/filters/convertSendFilterModelToFilterModel";

import {
  determineFilterKeyFromParam,
  determineSavedFilterKeyFromParam,
  fetchFilterData,
  fetchSavedFilterData,
  filterDataStateTypes,
  resetActiveFilterDataSlice,
  setActiveFilters,
  setFiltersData,
} from "store/filterDataSlice";
import {
  determineActiveSortKeyFromParam,
  resetSortDataField,
  setActiveSortData,
  sortDataStateTypes,
} from "store/sortDataSlice";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import { setIsFilterDrawerOpen } from "store/appSlice";
import FiltersTab from "components/UtilComponents/SidebarMenu/FiltersDialog/FiltersTab";
import SortingTab from "components/UtilComponents/SidebarMenu/FiltersDialog/SortingTab";
import StandardTabs from "components/Internal/Tabs/StandardTabs";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { fetchInstanceData } from "store/explorationInstancesSlice";
import { getExplorationTabs } from "helpers/functions/selectedViewHelpers";
import { fetchSelectedViewCount } from "helpers/functions/datasets/datasetHelpers";
import convertFilterModelToSendFilterModel from "helpers/functions/filters/convertFilterModelToSendFilterModel";
import { ExplorationAPIRequestModel } from "models/exploration.model";
import convertFiltersModelToActiveFiltersModel from "helpers/functions/filters/convertFilterModelToActiveFilterModel";
import filtersToSearchParams from "helpers/functions/filters/filtersHelpers";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

interface Props {
  runID: string;
}

const FilterSorterDrawer = ({ runID }: Props) => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useAppDispatch();

  const isFilterDrawerOpen = useAppSelector(
    (state) => state.appSlice.isFilterDrawerOpen,
  );
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const activeDataSet = useAppSelector(
    (state) => state.datasetSlice.activeDataSet,
  );
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);

  const [drawerColumn, setDrawerColumn] = useState<"filters" | "sort">(
    "filters",
  );

  // method to build the filter params arr for UI and logic handling

  // ----------------------------------- SETTING IMAGE-VIEW PARAMS -------------------------------------

  // Fetch all filters on init
  useEffect(() => {
    fetchAllFilters();
  }, [params?.id, params?.subset_id, activeDataSet]);

  const fetchAllFilters = () => {
    if (!params?.id || !activeDataSet) return;
    // TODO: make one call per view, but need to check the statistics
    const explorationTabs = getExplorationTabs(activeDataSet);

    _.map(explorationTabs, (tab) => {
      if (filterData?.[determineFilterKeyFromParam(tab)] === null) {
        dispatch(
          fetchFilterData({
            query: {
              dataset_id: params?.id,
              subset_id: params?.subset_id,
            },
            type: tab,
          }),
        );
      }
      // Fetch saved filters for current tab
      if (filterData?.[determineSavedFilterKeyFromParam(tab)] === null) {
        dispatch(
          fetchSavedFilterData({
            query: { dataset_id: params?.id, type: tab },
          }),
        );
      }
    });
  };

  /**
   * A function to apply the filters and sort to the selected view
   * fetching the data from the API for the selected view
   * @param params
   * @param param.selectedView The selected view (Media, MediaObject, Instances)
   * @param param.filterData The filter data from redux
   * @param param.sortData The sort data from redux
   * @param param.runID The runID of the dataset
   * @param param.subset_id The subset_id of the dataset
   */
  const applyFilters = (params: {
    selectedView: SelectedViewModel;
    filterData: filterDataStateTypes;
    sortData: sortDataStateTypes;
    runID: string;
    subset_id: string;
  }) => {
    const { selectedView, filterData, sortData, runID, subset_id } = params;
    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
    const visibleFilters = _.filter(filters, (f) => f?.is_visible);
    const reqBody: ExplorationAPIRequestModel = {
      dataset_id: runID,
      query: convertFilterModelToSendFilterModel(visibleFilters),
      limit: scrollLoadAmount,
      skip: 0,
      sort: sortData?.[selectedView],
    };
    const APIBody = {
      runId: runID,
      reqBody: reqBody,
      subSetId: subset_id,
      reset: true,
    };

    fetchSelectedViewCount(
      {
        selectedView: selectedView,
        filterData: filterData,
        runID: runID,
        subset_id: subset_id,
      },
      dispatch,
    );

    switch (selectedView) {
      case AnnotatableEnum.Media:
        dispatch(fetchMediaData(APIBody));
        break;
      case AnnotatableEnum.MediaObject:
        dispatch(fetchMediaObjectsData(APIBody));
        break;
      case AnnotatableEnum.Instance:
        dispatch(fetchInstanceData(APIBody));
    }

    dispatch(
      setActiveFilters({
        data: visibleFilters,
        type: selectedView,
      }),
    );
    dispatch(
      setActiveSortData({
        selectedView: selectedView,
        data: sortData?.[selectedView],
      }),
    );

    dispatch(setIsFilterDrawerOpen(false)); // closing Sort/Filter Drawer

    if (history.location) {
      const activeFilters =
        convertFiltersModelToActiveFiltersModel(visibleFilters);
      const searchParams = filtersToSearchParams(
        _.map(activeFilters, (f) => f),
        sortData?.[selectedView],
      );
      history.push(history.location.pathname + "?" + searchParams);
    }
  };

  useHotkeys(
    "f",
    () => {
      dispatch(setIsFilterDrawerOpen(!isFilterDrawerOpen));
    },
    [isFilterDrawerOpen],
  );

  // method to Reset sort and filter changes:
  const handleResetChanges = () => {
    const reqBody = {
      runId: runID,
      reqBody: {
        dataset_id: runID,
        sort: [],
        limit: scrollLoadAmount,
        skip: 0,
        query: [],
      },
      subSetId: params?.subset_id,
      reset: true,
    };
    // Fetch data
    switch (selectedView) {
      case AnnotatableEnum.Media:
        dispatch(fetchMediaData(reqBody));
        break;
      case AnnotatableEnum.Instance:
        dispatch(fetchInstanceData(reqBody));
        break;
      case AnnotatableEnum.MediaObject:
        dispatch(fetchMediaObjectsData(reqBody));
    }
    // Fetch filter data
    dispatch(
      fetchFilterData({
        query: {
          dataset_id: params?.id,
          subset_id: params?.subset_id,
        },
        type: selectedView,
      }),
    );
    // Reset sort and active filters
    dispatch(resetSortDataField(selectedView));
    const activeSortKey = determineActiveSortKeyFromParam(selectedView);
    activeSortKey && dispatch(resetSortDataField(activeSortKey));
    dispatch(resetActiveFilterDataSlice({ type: selectedView }));
    // Fetch new counts
    fetchSelectedViewCount(
      {
        selectedView: selectedView,
        filterData: filterData,
        runID: runID,
        subset_id: params?.subset_id,
      },
      dispatch,
      true,
    );
    // Close drawer
    dispatch(setIsFilterDrawerOpen(false));
    const explorationRoute = getExplorationRouteFromSelectedView(selectedView, {
      id: params?.id,
      subset_id: params?.subset_id,
    });
    history.push(explorationRoute);
  };

  const handleFilterClick = (
    selectedFilter: FilterModel | SavedFilterModel,
    selectedFilterCategory?: FilterCategoryEnum,
  ) => {
    const oldFilterData =
      filterData?.[determineFilterKeyFromParam(selectedView)];

    if (oldFilterData === null) return;

    // Check if the selected filter is a saved filter
    if (selectedFilterCategory === FilterCategoryEnum.Saved_Filter) {
      const selectedSavedFilter = selectedFilter as SavedFilterModel;
      let newFilters: FiltersModel = { ...oldFilterData };
      _.map(selectedSavedFilter?.filter, (filter) => {
        const newFilter = convertSendFilterModelToFilterModel(
          filter,
          newFilters,
        );

        if (
          !_.isUndefined(newFilter) &&
          !_.isNull(newFilter) &&
          _.isString(newFilter?.key)
        ) {
          newFilters = {
            ...newFilters,
            [newFilter?.key]: {
              ...newFilter,
              is_visible: true,
            },
          };
        }
      });
      dispatch(setFiltersData({ data: newFilters, type: selectedView }));
    }

    // Check if selected filter is a attributes
    else if (
      selectedFilterCategory === FilterCategoryEnum.Annotation_Attribute ||
      selectedFilterCategory === FilterCategoryEnum.ML_Annotation_Attribute
    ) {
      const selectedAnnotationFilter = selectedFilter as FilterModel;
      let newFilters = { ...oldFilterData };
      _.map(oldFilterData, (filter) => {
        if (filter?.attribute_id === selectedAnnotationFilter?.attribute_id) {
          newFilters = {
            ...newFilters,
            [filter?.key]: {
              ...filter,
              ["is_visible"]: true,
            },
          };
        }
      });
      dispatch(setFiltersData({ data: newFilters, type: selectedView }));
    }

    // Check if the selected filter is not an attribute (normal filter)
    else if (
      selectedFilterCategory === FilterCategoryEnum.Auto_Attribute ||
      selectedFilterCategory === FilterCategoryEnum.Initial_Attribute ||
      selectedFilter
    ) {
      const newFilter = selectedFilter as FilterModel;
      dispatch(
        setFiltersData({
          data: {
            ...oldFilterData,
            [newFilter?.key]: {
              ...oldFilterData[newFilter?.key],
              ["is_visible"]: true,
            },
          },
          type: selectedView,
        }),
      );
    }
  };

  const renderTabsHeader = () => {
    return (
      <div className="w-full flex gap-x-2 p-4 border-b-[1px] border-paletteGray-3">
        <div className="flex-1">
          <StandardTabs
            tabs={[
              {
                label: "Filter",
                value: "filters",
                onClick: () => setDrawerColumn("filters"),
              },
              {
                label: "Sort",
                value: "sort",
                onClick: () => setDrawerColumn("sort"),
              },
            ]}
            selectedTab={drawerColumn}
            setSelectedTab={(tab) => setDrawerColumn(tab as "filters" | "sort")}
          />
        </div>
        <div
          className="p-[10px] cursor-pointer"
          onClick={() => dispatch(setIsFilterDrawerOpen(false))}
        >
          <CloseIcon width={20} height={20} />
        </div>
      </div>
    );
  };

  const renderModel = () => {
    // Render a div with screen size and left margin with opacity
    //  that will close the drawer when clicked
    return (
      <div
        className="fixed top-0 left-16 w-screen h-screen z-40 bg-black opacity-50"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setIsFilterDrawerOpen(false));
        }}
      />
    );
  };

  // ---------------------------------------------- HTML ------------------------------------------------
  return (
    <div>
      {isFilterDrawerOpen && renderModel()}
      <Drawer
        sx={{
          display: {
            width: "420px",
            height: "100%",
            flexShrink: 0,
          },
          "& .MuiDrawer-paper": {
            overflowX: "hidden",
            width: "420px",
          },
        }}
        PaperProps={{
          classes: {
            root: "ml-16",
          },
        }}
        anchor="left"
        open={isFilterDrawerOpen}
        onClose={() => dispatch(setIsFilterDrawerOpen(false))}
        variant="persistent"
      >
        <div className="h-full w-full flex flex-col">
          <div className="min-h-0 flex-1 flex flex-col">
            {renderTabsHeader()}
            {drawerColumn === "filters" ? (
              <FiltersTab
                handleFilterClick={handleFilterClick}
                updateFilters={fetchAllFilters}
                handleResetChanges={handleResetChanges}
              />
            ) : (
              <SortingTab />
            )}
          </div>

          <div className="h-auto flex justify-center p-4 border-t-[1px] border-paletteGray-3">
            <button
              className="button-orange-layer"
              data-test="apply_filters"
              onClick={() =>
                applyFilters({
                  selectedView: selectedView,
                  filterData: filterData,
                  sortData: sortData,
                  runID: runID,
                  subset_id: params?.subset_id,
                })
              }
            >
              Apply changes
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterSorterDrawer;
