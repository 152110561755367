import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  AIMangerViewEnum,
  fetchAIAnnotationRunsStore,
  fetchAIModelsStore,
  fetchTrainingSetsStore,
  setSearchValue,
} from "store/aiManagerSlice";

import GeneralHeader from "components/Internal/Headers/GeneralHeader";
import AIModelManager from "Pages/AIManager/AIModelManager";
import SearchBar from "components/Internal/Inputs/SearchBar";
import RefreshButton from "components/Internal/Buttons/refresh";

import { ReactComponent as AIManagerIcon } from "assets/ai.svg";
import AIManagerTabs from "Pages/AIManager/AIManagerTabs";
import TrainingSetManager from "Pages/AIManager/TrainingSetManager";
import CreateNewTrainingSetButton from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetButton";
import CreateNewAIModelButton from "Pages/AIManager/AIModelManager/CreateNewAIModelButton";
import CreateNewAIAnnotationRunButton from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunButton";
import AIAnnotationRunManager from "Pages/AIManager/AIAnnotationRunManager";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";

const AIManager = () => {
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  useEffect(() => {
    dispatch(fetchAIModelsStore());
    dispatch(fetchTrainingSetsStore());
    dispatch(fetchAIAnnotationRunsStore());
  }, []);

  const handleRefreshOnClick = () => {
    if (aiManagerSlice.view === AIMangerViewEnum.AIModels) {
      dispatch(fetchAIModelsStore());
    } else if (aiManagerSlice.view === AIMangerViewEnum.TrainingSets) {
      dispatch(fetchTrainingSetsStore());
    } else if (aiManagerSlice.view === AIMangerViewEnum.AIAnnotationRuns) {
      dispatch(fetchAIAnnotationRunsStore());
    } else {
      console.error("Invalid AI manager view");
    }
  };

  const renderTable = () => {
    if (aiManagerSlice.view === AIMangerViewEnum.AIModels) {
      return <AIModelManager />;
    } else if (aiManagerSlice.view === AIMangerViewEnum.TrainingSets) {
      return <TrainingSetManager />;
    } else if (aiManagerSlice.view === AIMangerViewEnum.AIAnnotationRuns) {
      return <AIAnnotationRunManager />;
    } else {
      return <div></div>;
    }
  };

  // TODO: remove once external users can see TrainingSets
  const canSeeTrainingSets = checkIfUserHavePermission(
    keycloak,
    "accessTrainingSets",
  );

  const renderCreateButton = () => {
    if (aiManagerSlice.view === AIMangerViewEnum.AIModels) {
      return canSeeTrainingSets && <CreateNewAIModelButton />;
    } else if (aiManagerSlice.view === AIMangerViewEnum.TrainingSets) {
      return <CreateNewTrainingSetButton />;
    } else if (aiManagerSlice.view === AIMangerViewEnum.AIAnnotationRuns) {
      return <CreateNewAIAnnotationRunButton />;
    } else {
      return null;
    }
  };

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <GeneralHeader
        title="AI manager"
        icon={<AIManagerIcon className="w-5 h-5 text-paletteOrange" />}
        iconBgcolor="bg-paletteOrange/10"
      >
        {renderCreateButton()}
      </GeneralHeader>

      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-[600px]">
            <AIManagerTabs />
          </div>
          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={aiManagerSlice.searchValue}
                setSearchValue={(value) => dispatch(setSearchValue(value))}
                placeholder="Search for name..."
              />
            </div>

            <RefreshButton callBackFunction={handleRefreshOnClick} />
          </div>
        </div>
        <div className="w-full flex-1 min-h-0 pt-4">{renderTable()}</div>
      </div>
    </div>
  );
};

export default AIManager;
