import { createContext, useContext, useEffect, useState } from "react";

const WebsocketContext = createContext<{
  ws: WebSocket | null;
}>({
  ws: null,
});

const WebsocketContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ws, setWs] = useState<WebSocket | null>(null);

  return (
    <WebsocketContext.Provider value={{ ws }}>
      {children}
    </WebsocketContext.Provider>
  );
};

const useWebsocket = () => {
  return useContext(WebsocketContext);
};

export { useWebsocket, WebsocketContextProvider, WebsocketContext };
