import { Tooltip } from "@mui/material";
import { ReactComponent as ShareIcon } from "assets/share.svg";

const SharedByTeamLabel = () => {
  return (
    <Tooltip title="Shared by team">
      <ShareIcon className="ml-2" />
    </Tooltip>
  );
};

export default SharedByTeamLabel;
