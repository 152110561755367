type Props = {
  summary?: string;
  text: string;
};

const DetailsDescriptionPurple = ({ summary = "Learn more", text }: Props) => {
  return (
    <details>
      <summary className="text-sm text-paletteGray-9 cursor-pointer">
        {summary}
      </summary>
      <div className="px-4 py-2 bg-palettePurple/5 text-sm font-normal text-palettePurple-dark rounded-2xl transition-all">
        {text}
      </div>
    </details>
  );
};

export default DetailsDescriptionPurple;
