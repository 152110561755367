import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useAppDispatch } from "store/hooks";
import { AnnotationRunModel } from "models/annotationRun.model";
import { AttributeMetadataModel } from "models/attributes.model";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import newAIModelForm from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog/NewAIModelForm";
import { TextFieldModel } from "models/form.model";
import { TrainingSetModel } from "models/trainingSet.model";
import { fetchAnnotationRun } from "helpers/apis/annotationRun";
import { postCreateMLAnnotationModel } from "helpers/apis/mlAnnotationModel";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

type Props = {
  open: boolean;
  onClose: () => void;
  annotationRun: AnnotationRunModel;
  updateAnnotationRuns: () => void;
};

// eslint-disable-next-line no-empty-pattern
const AiModelDialog = ({ open, onClose, annotationRun }: Props) => {
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [currentAnnotationRun, setCurrentAnnotationRun] =
    useState<AnnotationRunModel | null>(null);

  const [selectedAttribute, setSelectedAttribute] =
    useState<AttributeMetadataModel | null>(null);

  const [aiModelName, setAiModelName] = useState<TextFieldModel>(
    newAIModelForm.name,
  );

  // TODO: remove once external users can see TrainingSets
  const canSeeTrainingSets = checkIfUserHavePermission(
    keycloak,
    "accessTrainingSets",
  );

  useEffect(() => {
    fetchAnnotationRun(
      {
        annotationRunID: annotationRun.id,
      },
      dispatch,
    ).then((response) => {
      setCurrentAnnotationRun(response);
    });

    if (currentAnnotationRun === null) return;

    if (
      currentAnnotationRun.nodes?.length === 0 ||
      currentAnnotationRun.nodes?.[0].goliat_node_id === null
    ) {
      return;
    }
    // fetchAttributeMetaData({
    //   attributeID: currentAnnotationRun.nodes?.[0].goliat_node_id,
    //   datasetID: currentAnnotationRun.dataset_id,
    // }).then((response) => {
    //   setSelectedAttribute(response);
    // });
  }, []);

  const renderAttributeInfo = () => {
    return (
      <div>
        <div className="text-lg font-bold">Attribute information</div>
        <div className="mt-2">
          <div className="text-sm text-paletteGray-7">Attribute name</div>
          <div className="text-base text-paletteBlack-2">
            {selectedAttribute?.name}
          </div>
        </div>
        <div className="mt-2">
          <div className="text-sm text-paletteGray-7">Attribute type</div>
          <div className="text-base text-paletteBlack-2">
            {selectedAttribute?.timestamp}
          </div>
        </div>
      </div>
    );
  };

  const renderAIModelNameInput = () => {
    return (
      <TextInput
        field={aiModelName}
        value={aiModelName.value}
        handleOnChange={(e) =>
          setAiModelName({
            ...aiModelName,
            value: e.target.value,
          })
        }
      />
    );
  };

  const renderActions = () => {
    return (
      <div className="w-full flex justify-end gap-x-2 pt-6">
        <button
          className="button-layer bg-transparent"
          onClick={onClose}
          disabled={isLoading}
        >
          Close
        </button>
        <button
          className="button-dark-gray-layer w-fit bg-paletteBlack-2"
          onClick={handleCreateNewAIModel}
          disabled={checkIfDisabled()}
        >
          Create AI model
        </button>
      </div>
    );
  };

  const handleCreateNewAIModel = () => {
    if (
      !currentAnnotationRun ||
      currentAnnotationRun.nodes?.length === 0 ||
      currentAnnotationRun.nodes?.[0].goliat_node_id === null
    )
      return;

    postCreateMLAnnotationModel(
      {
        name: aiModelName.value,
        reference_set_annotation_run_id: currentAnnotationRun?.id,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      onClose();
      history.push("/ai-manager");
    });
  };

  const checkIfDisabled = () => {
    return aiModelName.value === "" || isLoading;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="p-6 overflow-auto h-full flex flex-col gap-y-2">
        <div className="flex justify-between">
          <div className="text-xl text-paletteBlack-2">New AI model</div>
          <button className="button-layer p-1" onClick={onClose}>
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </button>
        </div>

        <div
          className="h-full mt-6 px-4 py-3 bg-palettePurple/5 text-palettePurple-dark rounded-2xl 
            flex flex-col gap-y-1 overflow-auto"
        >
          <p>
            Create and train a new AI model from the current annotation run.
          </p>
          {canSeeTrainingSets && (
            <p>
              In order to create a new AI model we will create a new training
              set from the current annotation run.
            </p>
          )}
        </div>

        {selectedAttribute && renderAttributeInfo()}

        <div className="mt-6 flex flex-col gap-y-1">
          <div className="font-medium">AI model information</div>
          {renderAIModelNameInput()}
        </div>

        {renderActions()}
      </div>
    </Dialog>
  );
};

export default AiModelDialog;
