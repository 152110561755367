import { APIPostWithBodyAxios } from "components/UtilComponents/Auth";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { AppDispatch } from "store/index";
import { History } from "history";
import { allUsersRoutes } from "routes";
import { getExplorationMediaPageRoute } from "routes/routesHelper";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { fetchActiveDataset } from "helpers/functions/datasets/datasetHelpers";
import { VisibilityStatus } from "models/global.model";
import _ from "lodash";
import { matchPath } from "react-router-dom";
import { ExplorationScreenRouteModel } from "models/routes.model";
import {
  DataUpdateMessageModel,
  UserNotificationMessageModel,
  WebSocketConnection,
  WebsocketEventEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import { DatasetModel } from "models/dataset.model";
import { handleUserNotificationMessage } from "helpers/websockets/userNotificationsHelper";
import { handleDataUpdateMessage } from "helpers/websockets/dataUpdateHelper";
import { useWebsocket } from "helpers/contexts/WebsocketContext";

const webSocketUrl =
  process.env.REACT_APP_WEBSOCKET_URL ??
  alert("Environment variable 'REACT_APP_WEBSOCKET_URL' is undefined");

/**
 * An endpoint to create a websocket connection entity
 * @param userID The ID of the user to create a websocket for
 * @returns A websocket connection entity
 */
export const postWebSocketConnectionCreate = async (
  userID: string,
): Promise<WebSocketConnection> => {
  const response = await APIPostWithBodyAxios(`/websocketConnections`, {
    user_id: userID,
  });
  return response?.data;
};

/**
 * A helper function to create a websocket object
 * @param userID The user ID of the user to create a websocket for
 * @param dispatch The AppDispatch object
 * @returns The websocket object
 */
export const openWebsocketConnection = async (
  userID: string,
  dispatch: AppDispatch,
  history: History,
) => {
  try {
    const response = await postWebSocketConnectionCreate(userID);
    return connectWebsocket(userID, response, dispatch, history);
  } catch (error) {
    console.error("WebSocket connection creation failed");
    return await Promise.reject();
  }
};

const connectWebsocket = (
  userID: string,
  wsConnection: WebSocketConnection,
  dispatch: AppDispatch,
  history: History,
): WebSocket => {
  const ws = new WebSocket(
    `${webSocketUrl}/?user_id=${userID}&id=${wsConnection.id}`,
  );

  ws.onmessage = (event: MessageEvent<string>) => {
    handleDefaultOnMessageWebsocket(event, dispatch, history);
  };

  ws.onopen = () => {
    // Send a keep-alive message every 5 minutes
    setInterval(
      () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "keep-alive" }));
        }
      },
      60 * 5 * 1000,
    );
  };

  ws.onerror = () => {
    //
  };

  ws.onclose = () => {
    //
  };

  return ws;
};

export const handleDefaultOnMessageWebsocket = (
  event: MessageEvent<string>,
  dispatch: AppDispatch,
  history: History,
) => {
  const webSocketMessage = JSON.parse(event.data) as WebSocketMessageModel;
  handleUserNotificationMessage(webSocketMessage);
  handleDataUpdateMessage(webSocketMessage, dispatch, history);
};
