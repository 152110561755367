import { ReactComponent as InfoIcon } from "assets/info.svg";

const NewAIModelHelperText = () => {
  return (
    <div
      className="h-full px-4 py-3 bg-palettePurple/5 text-palettePurple-dark rounded-2xl 
            flex flex-col gap-y-4 overflow-auto"
    >
      {/* Title */}
      <div className="flex gap-x-2 text-lg items-center text-palettePurple-dark">
        <InfoIcon className="w-4 h-4 text-palettePurple" />
        <span>AI model</span>
      </div>

      {/* Section 1 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-thin">
          To efficiently tackle Nano-Tasks on a large scale, we employ an AI
          model trained on human annotators' responses. The objective is to
          mimic annotators' behavior and apply it to new data points. Following
          training, we assess the model's performance on a subset of the data,
          gauging prediction accuracy against varying automation levels.
        </div>
      </div>
    </div>
  );
};

export default NewAIModelHelperText;
