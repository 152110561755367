import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import AIModelManagerTable from "Pages/AIManager/AIModelManager/AIModelManagerTable";
import AIModelOverview from "Pages/AIManager/AIModelManager/AIModelOverview";
import CreateNewAIModelDialog from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog";
import { useState } from "react";

export interface AIModelManagerOverviewDialogState {
  show: boolean;
  mlAnnotationModel: MLAnnotationModel | null;
}

function AIModelManager() {
  const [overviewDialogState, setOverviewDialogState] =
    useState<AIModelManagerOverviewDialogState>({
      show: false,
      mlAnnotationModel: null,
    });

  return (
    <>
      <AIModelManagerTable
        setOverviewMlAnnotationModel={(mlAnnotationModel: MLAnnotationModel) =>
          setOverviewDialogState({
            show: true,
            mlAnnotationModel: mlAnnotationModel,
          })
        }
      />
      <CreateNewAIModelDialog />
      {overviewDialogState.show && (
        <AIModelOverview
          isOpen={overviewDialogState.show}
          onClose={() => {
            setOverviewDialogState({
              show: false,
              mlAnnotationModel: null,
            });
          }}
          mlAnnotationModel={overviewDialogState.mlAnnotationModel}
        />
      )}
    </>
  );
}

export default AIModelManager;
