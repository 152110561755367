import { Dialog } from "@mui/material";
import {
  MLAnnotationModel,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { TrainingSetModel } from "models/trainingSet.model";
import { fetchTrainingSet } from "helpers/apis/trainingSet";
import { useAppDispatch } from "store/hooks";
import TrainingSetOverview from "components/UtilComponents/TrainingSetOverview";
import useTrainingSetOverview from "helpers/hooks/useTrainingSetOverview";
import StatusLabelFilled from "components/Internal/Labels/StatusLabelFilled";
import { snakeCaseToText } from "components/utilFunctions";
import {
  AIMangerViewEnum,
  setAIMangerView,
  setIsCreatingNewAIAnnotationRunModalOpen,
  setNewAIAnnotationRunMLAnnotationModel,
} from "store/aiManagerSlice";
import AIModelOverviewComponent from "components/UtilComponents/AIModelOverviewComponent";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mlAnnotationModel: MLAnnotationModel | null;
};

const AIModelOverview = ({ isOpen, onClose, mlAnnotationModel }: Props) => {
  const dispatch = useAppDispatch();

  const { keycloak } = useKeycloak();

  const [trainingSet, setTrainingSet] = useState<TrainingSetModel | null>(null);

  const {
    selectedTrainingSetAttributesMetaData,
    selectedTrainingSetDataset,
    selectedTrainingSetSubsets,
  } = useTrainingSetOverview(trainingSet);

  // TODO: remove once external users can see TrainingSets
  const canSeeTrainingSets = checkIfUserHavePermission(
    keycloak,
    "accessTrainingSets",
  );

  useEffect(() => {
    if (mlAnnotationModel && mlAnnotationModel.training_set_id) {
      fetchTrainingSet(
        {
          training_set_id: mlAnnotationModel.training_set_id,
        },
        dispatch,
      ).then((res) => {
        setTrainingSet(res);
      });
    }
  }, [mlAnnotationModel]);

  // This function is used to render the status label of the model
  const renderStatusLabel = () => {
    let label = "Not started";
    if (mlAnnotationModel !== null) {
      label = mlAnnotationModel?.status;
    }

    let circleColor;
    switch (mlAnnotationModel?.status) {
      case MLAnnotationModelStatus.TRAINING:
      case MLAnnotationModelStatus.CREATED:
        circleColor = "bg-paletteYellow";
        break;
      case MLAnnotationModelStatus.TRAINING_FAILED:
        circleColor = "bg-paletteRed";
        break;
      case MLAnnotationModelStatus.TRAINING_DONE:
        circleColor = "bg-paletteGreen";
        break;
      default:
        circleColor = "bg-white";
    }
    return (
      <StatusLabelFilled
        label={snakeCaseToText(label)}
        circleColor={circleColor}
      />
    );
  };

  const handleCreateAIAnnotationRunFromAIModel = () => {
    if (mlAnnotationModel === null) return;

    dispatch(setAIMangerView(AIMangerViewEnum.AIAnnotationRuns));
    dispatch(setIsCreatingNewAIAnnotationRunModalOpen(true));
    dispatch(setNewAIAnnotationRunMLAnnotationModel(mlAnnotationModel));
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <div className="p-6 min-w-[500px] flex flex-col gap-y-2">
        {/* Header */}
        <div className="flex justify-between">
          <div className="text-xl text-paletteBlack-2">AI model overview</div>
          <button className="p-1 cursor-pointer" onClick={onClose}>
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </button>
        </div>

        {/* Description */}
        <div className="flex justify-between">
          <div className="label-layer w-fit">{mlAnnotationModel?.name}</div>
          <div>{renderStatusLabel()}</div>
        </div>

        {/* Model information */}
        {mlAnnotationModel?.automation_correctness_curve && (
          <AIModelOverviewComponent aiModel={mlAnnotationModel} />
        )}

        {/* Training set */}
        {trainingSet !== null && (
          <div className="">
            <div className="py-2 font-medium">
              {canSeeTrainingSets ? "Training set" : "Training data"}
            </div>
            <TrainingSetOverview
              trainingSet={trainingSet}
              attributesMetaData={selectedTrainingSetAttributesMetaData}
              dataset={selectedTrainingSetDataset}
              subsets={selectedTrainingSetSubsets}
            />
          </div>
        )}

        <div className="w-full flex justify-end pt-6">
          <button
            className="button-layer"
            onClick={handleCreateAIAnnotationRunFromAIModel}
            disabled={
              mlAnnotationModel?.status !==
              MLAnnotationModelStatus.TRAINING_DONE
            }
          >
            Create annotation run from this AI model
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AIModelOverview;
