import { ReactComponent as InfoIcon } from "assets/info.svg";

const NewAIAnnotationRunHelperText = () => {
  return (
    <div
      className="h-full px-4 py-3 bg-palettePurple/5 text-palettePurple-dark rounded-2xl 
            flex flex-col gap-y-4 overflow-auto"
    >
      {/* Title */}
      <div className="flex gap-x-2 text-lg items-center text-palettePurple-dark">
        <InfoIcon className="w-4 h-4 text-palettePurple" />
        <span>AI annotation run</span>
      </div>

      {/* Section 1 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-thin">
          Each point on the automation correctness curve represents a threshold
          choice dictating prediction confidence. Higher confidence thresholds
          typically yield greater model accuracy but lower automation rates.
          Conversely, lower thresholds sacrifice some accuracy for increased
          automation potential. Depending on desired automation levels (and
          corresponding thresholds), we determine which examples the model
          reliably annotates and which necessitate human intervention.
        </div>
      </div>
    </div>
  );
};

export default NewAIAnnotationRunHelperText;
