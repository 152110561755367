import { UiSchema } from "@rjsf/utils";

export const ui_schema_video_frame_slider_2d: UiSchema = {
  inputType: {
    BoundingBox2DPainter: {
      "ui:widget": "hidden",
    },
    Viewer3D: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_video_frame_slider_2d = {
  inputType: {
    VideoRenderer: {
      annotationType: "frame",
      playerSettings: {
        playing: false,
        controls: false,
        loop: false,
        muted: true,
      },
    },
    BoundingBox2DPainter: {},
    Viewer3D: {},
  },
  toolbars: [
    {
      type: "navigation",
      settings: {
        withUnsolvableButton: true,
        unsolvableCode: "cant_solve",
        withOptions: false,
      },
    },
  ],
};
