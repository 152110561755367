import { TextFieldModel } from "models/form.model";

const newAIAnnotationRunForm: {
  name: TextFieldModel;
} = {
  name: {
    type: "text",
    key: "name",
    value: "",
    label: "AI annotation run name",
    placeholder: "Name your AI annotation run",
    required: true,
    style: {
      labelAndFieldOnTheSameLine: true,
    },
  },
};

export default newAIAnnotationRunForm;
