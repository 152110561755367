import { MLAnnotationModel } from "models/mlAnnotationModel.model";

import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as LaunchIcon } from "assets/launch.svg";

import { AIModelManagerPopoverConfirmDialogState } from "Pages/AIManager/AIModelManager/AIModelManagerTable";

type Props = {
  mlAnnotationModel: MLAnnotationModel;
  setRenameInputID: (id: string | null) => void;
  setConfirmDialogState: (
    newState: AIModelManagerPopoverConfirmDialogState,
  ) => void;
};

const AIModelManagerTableActions = ({
  mlAnnotationModel,
  setRenameInputID,
  setConfirmDialogState,
}: Props) => {
  return (
    <div className="flex flex-row-reverse gap-x-1 items-center">
      <div
        className="button-select-layer p-1"
        onClick={() => setRenameInputID(mlAnnotationModel?.id)}
      >
        <EditIcon className="text-paletteGray-9 w-[18px]" />
      </div>

      <div
        className="p-1 cursor-pointer"
        onClick={() =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            mlAnnotationModelID: mlAnnotationModel?.id,
          })
        }
      >
        <DeleteIcon className="text-paletteGray-9 w-[18px]" />
      </div>
    </div>
  );
};

export default AIModelManagerTableActions;
