import { useState } from "react";
import _ from "lodash";
import { PipelineNodeModel } from "models/pipelines.model";
import { ReactComponent as ArrowLeftIcon } from "assets/arrow_small_left.svg";
import pipelineNodeSummarySchema from "helpers/functions/pipelines/pipelineNodeSummarySchema";
import {
  getPipelineNodeAnswers,
  getPipelineNodeCanNotSolvedAnswers,
} from "helpers/functions/pipelines/pipelinesHelpers";
import { LegacyUIsEnum, UIsEnum } from "schemas/annotationUIs.model";
import pipelineConfigurationsForm, {
  showShouldWorkpackageIncludeTaskOutputsBasedOnGuiType,
} from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/PipelineConfigurations/PipelineConfigurationsForm";
import { SelectFieldModel } from "models/form.model";

type Props = {
  node: PipelineNodeModel;
  defaultOpen?: boolean;
};

interface PipelineNodeSummaryData {
  title: string;
  items: { key: string; label: string }[];
}

const PipelineNodeSummary = ({ node, defaultOpen = true }: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderHeader = () => {
    return (
      <div
        className="w-full py-2 px-4 flex justify-between items-center 
        cursor-pointer bg-paletteGray-3"
        onClick={() => setIsOpen(!isOpen)}
      >
        Node 1
        <ArrowLeftIcon
          className={`w-3 h-3 transition-all ${
            isOpen ? "-rotate-90" : "rotate-90"
          }`}
        />
      </div>
    );
  };

  const renderBody = () => {
    if (!isOpen) return null;

    const uiAnswers = getPipelineNodeAnswers(
      node?.config?.gui_settings,
      node?.config?.gui_type as LegacyUIsEnum | null,
    );
    const answers = {
      title: "Answers",
      items: _.map(uiAnswers, (answer: { key: string; label: string }) => ({
        key: answer?.key,
        label: answer?.label,
      })),
    };

    const canNotSolveAnswers = getPipelineNodeCanNotSolvedAnswers(
      node?.config?.gui_settings,
      node?.config?.gui_type as LegacyUIsEnum | null,
    );

    const cantSolveAnswers = {
      title: "Can't solve answers",
      items: _.map(
        canNotSolveAnswers,
        (answer: { key: string; label: string }) => ({
          key: answer?.key,
          label: answer?.label,
        }),
      ),
    };

    return (
      <div className="px-4 py-3 flex flex-col gap-y-[14px]">
        {renderSection(
          pipelineNodeSummarySchema.node_settings as PipelineNodeSummaryData,
        )}
        {uiAnswers !== null && renderSection(answers, true)}
        {canNotSolveAnswers !== null && renderSection(cantSolveAnswers, true)}
        {renderSection(
          pipelineNodeSummarySchema.zoom_settings as PipelineNodeSummaryData,
        )}
        {renderSection(
          pipelineNodeSummarySchema.configurations as PipelineNodeSummaryData,
        )}
      </div>
    );
  };

  const renderSection = (
    section: PipelineNodeSummaryData,
    useKeyAsValue: boolean = false,
  ) => {
    return (
      <div
        className="w-full flex flex-col gap-y-2 pb-[14px] 
            border-b-[1px] border-paletteGray-3
            last-of-type:border-b-0 last-of-type:pb-0"
      >
        <div className="text-sm text-paletteGray-13 font-light">
          {section?.title}
        </div>
        {_.map(section?.items, (item) => {
          if (
            item?.key === "config.should_workpackage_include_task_outputs" &&
            showShouldWorkpackageIncludeTaskOutputsBasedOnGuiType(
              node?.config?.gui_type as UIsEnum,
            ) === false
          ) {
            return null;
          }

          return (
            <div className="w-full flex gap-x-2" key={item?.key + "item"}>
              <div className="w-1/2 text-paletteGray-12 font-light">
                {item?.label}
              </div>
              <div className="w-1/2 text-paletteBlack-2 font-light">
                {useKeyAsValue ? item?.key : getValue(item?.key)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getValue = (key: string) => {
    let value = _.get(node, key, "-");

    // Special case for should_workpackage_include_task_outputs
    if (key === "config.should_workpackage_include_task_outputs") {
      const valueObject = _.find(
        (
          pipelineConfigurationsForm.should_workpackage_include_task_outputs as SelectFieldModel
        ).options,
        ["value", value],
      );
      value = valueObject?.label;
    }

    if (_.isNumber(value)) return value;
    if (_.isString(value)) return _.upperFirst(value);
    if (_.isBoolean(value)) return value ? "Active" : "Not active";

    return null;
  };

  return (
    <div className="w-full rounded-lg border-[1px] border-paletteGray-3 shadow-sm">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

export default PipelineNodeSummary;
