import { FormFieldModel } from "models/form.model";
import {
  PipelineNodeConfiguration,
  VisualisationTypeEnum,
} from "models/pipelines.model";
import { UIsEnum } from "schemas/annotationUIs.model";

type PipelineConfigurationKeys = keyof PipelineNodeConfiguration;

const pipelineConfigurationsForm: {
  [key in PipelineConfigurationKeys]: FormFieldModel;
} = {
  min_repeats: {
    type: "number",
    key: "min_repeats",
    value: 3,
    label: "Min. repeats",
    description:
      "Set the minimum and maximum amount of repeats that will be" +
      " performed per task. Once the minimum amount of repeats is reached, tasks will" +
      " be created until the answers converge above the given convergence threshold" +
      " or the maximum number of repeats is reached.",
    required: true,
    settings: {
      step: 1,
      minimumValue: 1,
      maximumValue: 3,
    },
  },
  max_repeats: {
    type: "number",
    key: "max_repeats",
    value: 3,
    label: "Max. repeats",
    description:
      "Set the minimum and maximum amount of repeats that will be" +
      " performed per task. Once the minimum amount of repeats is reached, tasks will" +
      " be created until the answers converge above the given convergence threshold" +
      " or the maximum number of repeats is reached.",
    required: true,
    settings: {
      step: 1,
      minimumValue: 1,
    },
  },
  convergence_threshold: {
    type: "number",
    key: "convergence_threshold",
    value: 0.0,
    label: "Convergence threshold",
    description:
      "Set the convergence threshold (between 0 and 1) for the answers of the repeats" +
      " of one task. Once the distribution of answers converges above the given threshold, no" +
      " new repeats for that task will be created.",
    required: false,
    settings: {
      minimumValue: 0.0,
      maximumValue: 1.0,
    },
  },
  wp_tasks: {
    type: "number",
    key: "wp_tasks",
    value: 10,
    label: "Tasks per work package",
    description:
      "Set the amount of tasks that will be included in a work package. Annotators see one work package at a time.",
    required: true,
    settings: {
      step: 1,
      minimumValue: 0,
    },
  },
  wp_timeout: {
    type: "number",
    key: "wp_timeout",
    value: 60,
    label: "Timeout per work package",
    description:
      "This determines the maximum amount of time that annnotators are allocated to complete a work package in seconds.",
    required: true,
    settings: {
      minimumValue: 0,
    },
  },
  media_type: {
    type: "select",
    key: "media_type",
    value: "",
    label: "Select a media type",
    placeholder: "Select a media type",
    options: [],
    required: true,
  },
  wp_rd_tasks: {
    type: "number",
    key: "wp_rd_tasks",
    value: 0,
    label: "Reference data tasks",
    required: true,
    settings: {
      minimumValue: 0,
    },
  },
  should_workpackage_include_task_outputs: {
    type: "select",
    key: "should_workpackage_include_task_outputs",
    value: "parallel",
    label: "Propagate answers",
    description:
      "Determines whether work packages should include the answers of" +
      " previous repeats of the same task. This only works for geometry annotations.",
    placeholder: "Select Annotation Order...",
    options: [
      {
        label: "Yes",
        value: "sequential", //true
      },
      {
        label: "No",
        value: "parallel", //false
      },
    ],
    required: true,
  },
  auto_annotate: {
    type: "boolean",
    key: "auto_annotate",
    value: false,
    label: "Auto annotate",
    description: "Automatically annotate the tasks with the answers.",
    required: true,
  },
  visualisation_type: {
    type: "select",
    key: "visualisation_type",
    value: VisualisationTypeEnum.DEFAULT,
    label: "Select a visualisation type",
    placeholder: "Select a visualisation type",
    options: [],
    required: true,
  },
};

export default pipelineConfigurationsForm;

export const showShouldWorkpackageIncludeTaskOutputsBasedOnGuiType = (
  gui_type: UIsEnum,
) => {
  return (
    gui_type === UIsEnum.bounding_box_2d || gui_type === UIsEnum.keypoint_2d
  );
};
