import { useAppDispatch, useAppSelector } from "store/hooks";
import { Dialog } from "@mui/material";
import {
  resetNewAIModel,
  setIsCreatingNewAIModelModalOpen,
  setNewAIModelName,
} from "store/aiManagerSlice";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import { useState } from "react";
import SelectTrainingSetDialog from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog/SelectTrainingSetDialog";
import TrainingSetOverview from "components/UtilComponents/TrainingSetOverview";

import NewAIModelAction from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog/NewAIModelAction";
import useTrainingSetOverview from "helpers/hooks/useTrainingSetOverview";
import AsteriskLabel from "components/Internal/Labels/AsteriskLabel";
import NewAIModelHelperText from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog/NewAIModelHelperText";

const CreateNewAIModelDialog = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.aiManagerSlice.isCreateNewAIModelModalOpen,
  );

  const newAIModel = useAppSelector((state) => state.aiManagerSlice.newAIModel);

  const [isSelectTrainingSetDialogOpen, setIsSelectTrainingSetDialogOpen] =
    useState(false);

  const [isCreatingNewAIModel, setIsCreatingNewAIModel] = useState(false);

  const {
    selectedTrainingSetAttributesMetaData,
    selectedTrainingSetDataset,
    selectedTrainingSetSubsets,
  } = useTrainingSetOverview(newAIModel.training_set);

  const renderSelectTrainingSetButton = () => {
    return (
      <button
        className="button-dark-gray-layer w-fit"
        onClick={() => setIsSelectTrainingSetDialogOpen(true)}
      >
        Select a training set
      </button>
    );
  };

  const onClose = () => {
    dispatch(setIsCreatingNewAIModelModalOpen(false));
    dispatch(resetNewAIModel());
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <div className="p-6 min-w-[600px] min-h-[500px] h-full flex gap-x-5">
        <div className="w-[300px]">
          <NewAIModelHelperText />
        </div>
        <div className=" min-w-0 flex-1 flex flex-col justify-between">
          {/* Header */}
          <div className="flex justify-between">
            <div className="text-xl text-paletteBlack-2">New AI model</div>
            <button className="p-1 cursor-pointer" onClick={onClose}>
              <CloseIcon className="w-3 h-3 text-paletteGray-9" />
            </button>
          </div>

          <div className="py-8 flex flex-col gap-y-2">
            <div className=" font-medium">AI model information</div>
            <div className="flex flex-col gap-y-2">
              <TextInput
                field={newAIModel.name}
                value={newAIModel.name.value}
                handleOnChange={(e) =>
                  dispatch(setNewAIModelName(e.target.value))
                }
                autoFocus
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-2 min-h-0 flex-1 overflow-y-auto">
            <div>
              Training set
              <AsteriskLabel />
            </div>
            {newAIModel.training_set === null ? (
              renderSelectTrainingSetButton()
            ) : (
              <TrainingSetOverview
                trainingSet={newAIModel.training_set}
                attributesMetaData={selectedTrainingSetAttributesMetaData}
                dataset={selectedTrainingSetDataset}
                subsets={selectedTrainingSetSubsets}
                handleChangeTrainingSet={() =>
                  setIsSelectTrainingSetDialogOpen(true)
                }
              />
            )}
          </div>
          <NewAIModelAction
            isCreatingNewAIModel={isCreatingNewAIModel}
            setIsCreatingNewAIModel={setIsCreatingNewAIModel}
          />
        </div>
      </div>

      {isSelectTrainingSetDialogOpen && (
        <SelectTrainingSetDialog
          isSelectTrainingSetDialog={isSelectTrainingSetDialogOpen}
          setIsSelectTrainingSetDialog={setIsSelectTrainingSetDialogOpen}
        />
      )}
    </Dialog>
  );
};

export default CreateNewAIModelDialog;
