import { useAppDispatch, useAppSelector } from "store/hooks";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import { useRef, useState } from "react";
import { TrainingSetModel } from "models/trainingSet.model";
import EditName from "components/Internal/Inputs/EditName";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import { deleteTrainingSet, patchTrainingSet } from "helpers/apis/trainingSet";
import { fetchTrainingSetsStore } from "store/aiManagerSlice";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import _ from "lodash";
import TrainingSetManagerTableActions from "Pages/AIManager/TrainingSetManager/TrainingSetManagerTableActions";
import CreateNewTrainingSetDialog from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog";
import TrainingSetOverviewDialog from "Pages/AIManager/TrainingSetManager/TrainingSetOverviewDialog";
import { useKeycloak } from "@react-keycloak/web";
import SharedByTeamLabel from "components/Internal/Labels/SharedByTeamLabel";

export interface TrainingSetManagerPopoverConfirmDialogState {
  show: boolean;
  action: "archive";
  trainingSetID: string | null;
}

export interface TrainingSetManagerOverviewDialogState {
  show: boolean;
  trainingSet: TrainingSetModel | null;
}

const TrainingSetManager = () => {
  const keycloak = useKeycloak();
  const dispatch = useAppDispatch();
  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  const [confirmDialogState, setConfirmDialogState] =
    useState<TrainingSetManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      trainingSetID: null,
    });

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [overviewDialogState, setOverviewDialogState] =
    useState<TrainingSetManagerOverviewDialogState>({
      show: false,
      trainingSet: null,
    });

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        const isTrainingSetSharedByMyTeam =
          trainingSet?.owner !== keycloak?.keycloak?.idTokenParsed?.sub;
        return (
          <div
            className="flex overflow-hidden pr-3"
            onClick={(e) => e.stopPropagation()}
          >
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) =>
                handleRenameTrainingSet(trainingSet?.id, newName)
              }
              withToolTip={true}
              fontSize="text-normal"
            />
            {isTrainingSetSharedByMyTeam && <SharedByTeamLabel />}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        const date = new Date(trainingSet?.created_at);
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <TooltipTruncateEllipsis className="pr-3">
              {date.toLocaleString()}
            </TooltipTruncateEllipsis>
          </div>
        );
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 20,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return (
          <div onClick={(e) => e.stopPropagation()} className="w-full">
            <UserGroupSelection
              selectedUserGroup={trainingSet?.user_group}
              onChanges={(newUserGroup: string) =>
                patchTrainingSet(
                  trainingSet?.id,
                  { user_group: newUserGroup },
                  dispatch,
                  setIsLoading,
                ).then(() => updateTrainingSets())
              }
            />
          </div>
        );
      },
    },

    {
      field: "",
      headerName: "Actions",
      headerClassName: "justify-end",
      sortable: false,
      span: 30,
      cell: (row) => {
        const trainingSet = row as TrainingSetModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex flex-row-reverse justify-start"
          >
            <TrainingSetManagerTableActions
              trainingSet={trainingSet}
              setRenameInputID={setRenameInputID}
              setConfirmDialogState={setConfirmDialogState}
              setOverviewDialogState={setOverviewDialogState}
            />
          </div>
        );
      },
    },
  ];

  const updateTrainingSets = () => {
    dispatch(fetchTrainingSetsStore());
  };

  const handleRenameTrainingSet = (trainingSetID: string, newName: string) => {
    patchTrainingSet(
      trainingSetID,
      { name: newName },
      dispatch,
      setIsLoading,
    ).then(() => updateTrainingSets());
  };

  const handleDeleteAIModel = () => {
    if (!confirmDialogState.trainingSetID) return;

    deleteTrainingSet(
      {
        trainingSetID: confirmDialogState.trainingSetID,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateTrainingSets();
      setConfirmDialogState({ ...confirmDialogState, show: false });
    });
  };

  const renderConfirmationDialog = () => {
    if (!confirmDialogState.trainingSetID) return null;

    const trainingSetName =
      _.find(aiManagerSlice.trainingSets, [
        "id",
        confirmDialogState.trainingSetID,
      ])?.name || "";
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to delete the training set 
                "${trainingSetName}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => handleDeleteAIModel()}
      />
    );
  };

  return (
    <>
      <DataTable
        rows={aiManagerSlice.trainingSets as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        isLoading={aiManagerSlice.loadingTrainingSets}
        isDisabled={isLoading}
        searchValue={aiManagerSlice.searchValue}
        onRowClick={(row) => {
          setOverviewDialogState({
            show: true,
            trainingSet: row as TrainingSetModel,
          });
        }}
      />

      {/* Render confirmation dialog */}
      {renderConfirmationDialog()}

      {/* Render create new training set dialog */}
      {aiManagerSlice.isCreateNewTrainingSetModalOpen && (
        <CreateNewTrainingSetDialog />
      )}

      {/* Render training set overview dialog */}
      {overviewDialogState.show && (
        <TrainingSetOverviewDialog
          isOpen={overviewDialogState.show}
          setIsOpen={setOverviewDialogState}
          trainingSet={overviewDialogState.trainingSet}
        />
      )}
    </>
  );
};

export default TrainingSetManager;
