import _ from "lodash";
import { ReactComponent as PlayIcon } from "assets/play_icon.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AnnotationRunCreate } from "models/annotationRun.model";
import { postAnnotationRun } from "helpers/apis/annotationRun";
import {
  getPipelineDesignerErrorMessages,
  getPipelineNode,
  validateNodeConfigurations,
} from "helpers/functions/pipelines/pipelinesHelpers";
import {
  patchPipeline,
  patchPipelineNode,
  postCreatePipeline,
} from "helpers/apis/pipelines";
import {
  setAnnotationRunName,
  setIsShowingLivePreview,
  setPipeline,
  setPipelineNode,
  setValidations,
} from "store/pipelineDesignerSlice";
import { useHistory } from "react-router-dom";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";
import {
  PipelineModel,
  PipelineNodeModel,
  VisualisationTypeEnum,
} from "models/pipelines.model";
import { useState } from "react";
import LaunchAnnotationRunDialog from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/LaunchAnnotationRunDialog";
import WarningDialog from "components/Internal/Dialogs/WarningDialog";
import { customContractUsersRoutes } from "routes";

const PipelineConfigurationActions = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const pipelineSlice = useAppSelector((state) => state.pipelineDesignerSlice);

  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const renderLaunchAnnotationRunButton = () => {
    return (
      <button
        className="button-dark-gray-layer w-auto"
        data-test="launch_annotation_run_button_pipeline_designer"
        onClick={handleLaunchAnnotationRunClick}
      >
        Launch annotation run
      </button>
    );
  };

  const handleLaunchAnnotationRunClick = () => {
    const validation = validateNodeConfigurations(pipelineSlice);
    const pipelineIsValid = _.every(validation, (v) => v === true);

    if (!pipelineIsValid) {
      dispatch(setValidations(validation));
      setIsWarningDialogOpen(true);
      return;
    }
    setIsConfirmDialogOpen(true);
  };

  const handleLaunchAnnotationRun = () => {
    setIsLoading(true);
    const validation = validateNodeConfigurations(pipelineSlice);
    const pipelineIsValid = _.every(validation, (v) => v === true);
    const annotationRunNameIsValid =
      pipelineSlice.annotationRunName.value?.trim() !== "";
    if (!pipelineIsValid) {
      dispatch(setValidations(validation));
      setIsLoading(false);
      return;
    }
    if (!annotationRunNameIsValid) {
      dispatch(
        setAnnotationRunName({
          ...pipelineSlice.annotationRunName,
          error: annotationRunNameIsValid
            ? null
            : "Annotation run name is required",
        }),
      );
      setIsLoading(false);
      return;
    }

    if (pipelineSlice.pipeline && pipelineSlice.pipelineNode) {
      patchPipelineAndThenLaunchAnnotationRun();
    } else {
      createPipelineAndThenLaunchAnnotationRun();
    }
    setIsConfirmDialogOpen(false);
  };

  const createPipelineAndThenLaunchAnnotationRun = () => {
    postCreatePipeline(
      {
        name: pipelineSlice.pipelineName.value,
        nodes: [getPipelineNode(pipelineSlice)],
      },
      {
        setIsLoading,
      },
    ).then((newPipeline) => {
      if (newPipeline) {
        dispatch(setPipeline(newPipeline));
        if (newPipeline.nodes && newPipeline.nodes.length > 0) {
          dispatch(setPipelineNode(newPipeline.nodes[0]));
        }
        launchAnnotationRun(newPipeline, newPipeline?.nodes ?? []);
        history.push(
          getPipelineDesignerPageRoute({ pipeline_id: newPipeline.id }),
        );
      }
    });
  };

  const patchPipelineAndThenLaunchAnnotationRun = () => {
    const pipelineNode = pipelineSlice.pipelineNode;
    if (
      pipelineSlice.pipeline === null ||
      pipelineNode === null ||
      !pipelineNode?.id
    ) {
      setIsLoading(false);
      return;
    }

    Promise.all([
      patchPipeline(
        pipelineSlice.pipeline?.id,
        { name: pipelineSlice.pipelineName.value },
        dispatch,
      ),
      patchPipelineNode(pipelineNode.id, getPipelineNode(pipelineSlice)),
    ]).then(([newPipeline, newPipelineNode]) => {
      if (newPipeline && newPipelineNode) {
        dispatch(setPipeline(newPipeline));
        dispatch(setPipelineNode(newPipelineNode));
        launchAnnotationRun(newPipeline, [newPipelineNode]);
      }
    });
  };

  const launchAnnotationRun = (
    pipeline: PipelineModel,
    nodes: PipelineNodeModel[],
  ) => {
    if (!pipelineSlice.dataset?.id || !pipelineSlice.subset?.id) {
      setIsLoading(false);
      return;
    }
    const visualizationType = pipelineSlice.configurationsForm
      ?.visualisation_type?.value as VisualisationTypeEnum;
    const visualisationConfigId =
      visualizationType === VisualisationTypeEnum.CROP
        ? pipelineSlice.visualisationConfig?.id ?? null
        : null;

    const annotationRun: AnnotationRunCreate = {
      config: {
        color_map: pipelineSlice.colorMap ?? null,
      },
      dataset_id: pipelineSlice.dataset?.id ?? null,
      subset_id: pipelineSlice.subset?.id ?? null,
      pipeline_id: pipeline?.id,
      name: pipelineSlice.annotationRunName.value,
      nodes: [
        {
          pipeline_node_id: nodes?.[0]?.id ?? "",
          config: {
            visualisation_config_id: visualisationConfigId,
            reference_data_id: pipelineSlice.referenceData?.id ?? null,
            initial_attribute_id: pipelineSlice.initialAttribute?.id ?? null,
          },
        },
      ],
    };
    postAnnotationRun(annotationRun, dispatch, setIsLoading).then(
      (annotationRun) => {
        if (annotationRun) {
          history.push(customContractUsersRoutes.annotationRunManagerPage.path);
        }
      },
    );
  };

  const renderPreviewButton = () => {
    const {
      isShowingLivePreview,
      dataset,
      subset,
      colorMap,
      uiType,
      guiFormData,
      configurationsForm,
    } = pipelineSlice;

    const visualizationType = configurationsForm?.visualisation_type
      ?.value as VisualisationTypeEnum;

    const isDisabled =
      dataset === null ||
      subset === null ||
      colorMap === null ||
      uiType === null ||
      guiFormData === null ||
      !visualizationType;
    return (
      <button
        className={`${
          isShowingLivePreview
            ? "button-dark-gray-layer w-auto"
            : "button-layer w-auto opacity-70"
        }`}
        onClick={() => dispatch(setIsShowingLivePreview(!isShowingLivePreview))}
        disabled={isDisabled}
        title={isDisabled ? "Please select data and an UI" : ""}
      >
        <PlayIcon width={16} height={16} />
        {isShowingLivePreview ? "Hide preview" : "Show preview"}
      </button>
    );
  };

  return (
    <div className="p-1 flex justify-between">
      {renderPreviewButton()}
      {renderLaunchAnnotationRunButton()}

      <WarningDialog
        title="Annotation run setup incomplete"
        subtitle="Please make sure that all required fields are filled in before launching an annotation run."
        errorMessageList={getPipelineDesignerErrorMessages(pipelineSlice)}
        openDialog={isWarningDialogOpen}
        setOpenDialog={setIsWarningDialogOpen}
      />
      <LaunchAnnotationRunDialog
        viewMode="viewAndLaunch"
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        pipeline={{
          name: pipelineSlice.pipelineName.value,
          nodes: [getPipelineNode(pipelineSlice) as PipelineNodeModel],
        }}
        subset={pipelineSlice.subset}
        handleOnConfirm={handleLaunchAnnotationRun}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PipelineConfigurationActions;
