import snackbarHelper from "components/Helpers/snackbarHelperFn";
import SimpleSelect from "components/Internal/Inputs/Form/SimpleSelect";
import _ from "lodash";
import { AttributeMetadataModel } from "models/attributes.model";
import { FilterModel } from "models/filter.model";
import { FormModel, SelectFieldModel } from "models/form.model";
import { AnnotatableEnum } from "models/global.model";
import { useAppDispatch, useAppSelector } from "store/hooks";

type Props = {
  attributeSelection: SelectFieldModel;
  setAttributeSelection: (attributeSelection: SelectFieldModel) => void;
  attributesList: {
    [key: string]: FormModel;
  };
  setAttributesList: (attributesList: { [key: string]: FormModel }) => void;
};

const ThresholdAnalysisSelect = ({
  attributeSelection,
  setAttributeSelection,
  attributesList,
  setAttributesList,
}: Props) => {
  const dispatch = useAppDispatch();

  const objectAttributes = useAppSelector(
    (state) =>
      state.datasetSlice.attributes?.filter(
        (attribute) =>
          attribute.annotatable_type === AnnotatableEnum.MediaObject,
      ),
  );
  const objectFilters = useAppSelector(
    (state) => state.filterDataSlice.annotationFilterData,
  );

  // Add new attribute to the attributes list and create a new form for it
  const addNewAttribute = (attributeID: string) => {
    const attribute = _.find(objectAttributes, { id: attributeID });

    const valueHistogram = _.find(objectFilters, {
      id: attributeID,
      filter_name: "value",
    });
    const ambiguityHistogram = _.find(objectFilters, {
      id: attributeID,
      filter_name: "ambiguity",
    });

    const validateAttributeSelectionResult = validateAttributeSelection(
      attribute,
      valueHistogram,
      ambiguityHistogram,
    );
    if (
      !attribute ||
      !valueHistogram ||
      !ambiguityHistogram ||
      validateAttributeSelectionResult === null
    ) {
      return;
    }

    const newAttributeForm = createNewForm(
      attribute,
      valueHistogram,
      ambiguityHistogram,
    );

    if (newAttributeForm !== null) {
      setAttributesList({
        ...attributesList,
        [attributeID]: newAttributeForm,
      });

      // Remove the selected attribute from the list
      const newAttributeSelectionOptions = _.filter(
        attributeSelection?.options,
        (option) => option?.value !== attributeID,
      );
      setAttributeSelection({
        ...attributeSelection,
        options: newAttributeSelectionOptions,
        value: attributeID,
      });
    }
  };

  const validateAttributeSelection = (
    attribute: AttributeMetadataModel | undefined,
    valueHistogram: FilterModel | undefined,
    ambiguityHistogram: FilterModel | undefined,
  ) => {
    if (!attribute) {
      snackbarHelper("Could not find the selected attribute!", "error");
      return null;
    }

    if (!_.includes(["CATEGORICAL", "BINARY"], attribute?.attribute_type)) {
      snackbarHelper(
        "The selected attribute is not categorical or binary!",
        "error",
      );
      return null;
    }

    if (!valueHistogram) {
      snackbarHelper(
        "Could not find value histogram for the selected attribute!",
        "error",
      );
      return null;
    }

    if (!ambiguityHistogram) {
      snackbarHelper(
        "Could not find ambiguity histogram for the selected attribute!",
        "error",
      );
      return null;
    }
  };

  const createNewForm = (
    attribute: AttributeMetadataModel,
    valueHistogram: FilterModel,
    ambiguityHistogram: FilterModel,
  ): FormModel | null => {
    if (
      _.isUndefined(ambiguityHistogram?.lower) ||
      _.isNull(ambiguityHistogram?.lower)
    ) {
      snackbarHelper(
        "Ambiguity histogram for the selected attribute has no lower bound!",
        "error",
      );
      return null;
    }

    const newForm: FormModel = {
      attribute: {
        type: "text",
        key: "attribute",
        value: attribute?.name || "",
        label: "Attribute",
        required: true,
        disabled: true,
      },
      ambiguity: {
        type: "single_slider",
        key: "ambiguity",
        value: ambiguityHistogram?.lower,
        label: "Ambiguity Threshold",
        min: ambiguityHistogram.lower || 0,
        max: ambiguityHistogram?.upper || 1,
        required: true,
        settings: {
          renderNumberInput: true,
        },
      },
      answer: {
        type: "select",
        key: "answer",
        options: _.map(valueHistogram?.buckets, (value) => ({
          value: value?.[0],
          label: value?.[0] + " (" + value?.[1] + ")",
        })),
        value: "",
        label: "Answer indication correctness",
        required: true,
      },
    };

    return newForm;
  };

  return (
    <SimpleSelect
      field={attributeSelection}
      value={attributeSelection?.value}
      handleOnChange={(e) => addNewAttribute(e.target.value)}
    />
  );
};

export default ThresholdAnalysisSelect;
