import AIAnnotationRunManagerTable from "Pages/AIManager/AIAnnotationRunManager/AIAnnotationRunManagerTable";
import CreateNewAIAnnotationRunDialog from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog";

const AIAnnotationRunManager = () => {
  return (
    <div className="h-full">
      <AIAnnotationRunManagerTable />
      <CreateNewAIAnnotationRunDialog />
    </div>
  );
};

export default AIAnnotationRunManager;
