import snackbarHelper from "components/Helpers/snackbarHelperFn";
import {
  UserNotificationMessageModel,
  WebsocketEventEnum,
  WebSocketMessageModel,
} from "models/websockets.model";
import userNotificationToaster from "components/UtilComponents/CustomToaster/userNotificationToaster";

// A helper function to handle user notification messages.
// Should only display a snackbar message.
export const handleUserNotificationMessage = (
  webSocketMessage: WebSocketMessageModel,
) => {
  const userNotificationMessage =
    webSocketMessage as UserNotificationMessageModel;

  if (webSocketMessage.type !== "user_notification_message") return;

  const messageContent = userNotificationMessage.content;
  switch (messageContent?.event_type) {
    case WebsocketEventEnum.SUBSET_CREATION: {
      const msg = messageContent?.details
        .replace("<b>", "'")
        .replace("</b>", "'");
      snackbarHelper(msg, messageContent?.severity);
      break;
    }
    default: {
      const msg = messageContent?.details
        .replace("<b>", "'")
        .replace("</b>", "'");

      // Remove link in a tag
      const msgWithoutLink = msg.replace(/<a\b[^>]*>(.*?)<\/a>/i, "$1");

      userNotificationToaster[messageContent?.severity](
        messageContent?.details,
      );
      return;
    }
  }
};
