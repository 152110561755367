import { TextFieldModel } from "models/form.model";

const newTrainingSetForm: {
  name: TextFieldModel;
} = {
  name: {
    type: "text",
    key: "name",
    value: "",
    label: "Training set name",
    placeholder: "Name your training set",
    required: true,
    style: {
      labelAndFieldOnTheSameLine: true,
    },
  },
};

export default newTrainingSetForm;
