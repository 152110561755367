import { useAppDispatch } from "store/hooks";
import BasicButton from "components/Internal/Buttons/BasicButton";

import { postGrafanaExport } from "helpers/apis/transformations";

interface Props {
  datasetID: string;
  subsetID: string;
}

const GrafanaExportTab = ({ datasetID, subsetID }: Props) => {
  const dispatch = useAppDispatch();

  const renderSubmitButton = () => {
    const disabled = datasetID === "";
    return (
      <div className="flex justify-end mt-4">
        <BasicButton label="Send" onClick={handleSubmit} disabled={disabled} />
      </div>
    );
  };

  const handleSubmit = () => {
    postGrafanaExport(
      { datasetID: datasetID },
      { subsetID: subsetID },
      dispatch,
    );
  };

  return <div className="w-1/3 my-2">{renderSubmitButton()}</div>;
};

export default GrafanaExportTab;
