import { AxiosError, AxiosResponse } from "axios";
import {
  APIFetchAxios,
  APIPatchWithBodyAxios,
} from "components/UtilComponents/Auth";
import { UploadJobsModel } from "models/uploadJobs.model";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { Dispatch } from "@reduxjs/toolkit";

/**
 * An endpoint to fetch tags for a given dataset id
 * @param dispatch The dispatch function
 * @returns A list of uploadJobs
 */
export const fetchUploadJobsAPI = async (
  dispatch: Dispatch,
): Promise<AxiosResponse<UploadJobsModel[]>> => {
  return await APIFetchAxios("/uploadJobs")
    .then((response: AxiosResponse) => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError<any>) => {
      snackbarHelper("Error fetching upload jobs", "error");
      return Promise.reject(error);
    });
};

/**
 * An endpoint to update tags for a given dataset id
 * @param dispatch The dispatch function
 * @returns The updated uploadJob
 */
export const updateUploadJobsAPI = async (
  dispatch: Dispatch,
  body: UploadJobsModel,
): Promise<AxiosResponse<UploadJobsModel>> => {
  return await APIPatchWithBodyAxios("/uploadJobs", body)
    .then((response: AxiosResponse) => {
      snackbarHelper("Upload jobs updated successfully!");
      return Promise.resolve(response);
    })
    .catch((error: AxiosError<any>) => {
      snackbarHelper(
        `Error occured when updating upload job ${body.name}`,
        "error",
      );
      return Promise.reject(error);
    });
};
