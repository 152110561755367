import { ReactElement, useState, useRef, useEffect } from "react";
import _ from "lodash";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import { DatasetModel } from "models/dataset.model";
import { ReactComponent as Close } from "assets/close_small.svg";
import { ReactComponent as PlusIcon } from "assets/plus_small.svg";
import { ReactComponent as Collapse } from "assets/collapse.svg";
import { ReactComponent as Expand } from "assets/expand.svg";
import { useAppDispatch } from "store/hooks";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { TagModel, tagsColors, TagTypeModel } from "models/global.model";
import { fetchTags } from "store/datasetSlice";
import { putCreateTag, postUpdateTag } from "helpers/apis/tags";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  items: { [name: string]: TagModel };
  selectedItems: { [name: string]: TagModel };
  handleAddTag: (tag: TagModel) => void;
  handleRemoveTag: (tag: TagModel) => void;
  enableCreateNewTag?: {
    type: TagTypeModel;
    datasetID: string;
    onAddNewItemSuccess?: (newTagID: string) => void;
  };
  disabled?: boolean;
  addLabel?: string;
  compactMode?: boolean;
  compactModeDefaultExpanded?: boolean;
  placeholder?: string;
}

const TagsSelector = ({
  items,
  selectedItems,
  handleAddTag,
  handleRemoveTag,
  enableCreateNewTag,
  disabled = false,
  addLabel,
  compactMode = false,
  compactModeDefaultExpanded = false,
  placeholder,
}: Props): ReactElement => {
  const dispatch = useAppDispatch();
  const addButtonRef = useRef<HTMLDivElement>(null);
  const smallAddButtonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const screenSize = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);

  const [isExpandedLocal, setIsExpandedLocal] = useState<boolean>(
    !!compactModeDefaultExpanded || false,
  );

  // show close for tag removal
  const [showClose, setShowClose] = useState("");

  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    position: "down" | "up";
  }>({
    top: 0,
    left: 0,
    position: "down",
  });

  const [newlyCreatedTagID, setNewlyCreatedTagID] = useState<null | string>(
    null,
  );
  const [showDropdownColorPicker, setShowDropdownColorPicker] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [dropdownWidth, setDropdownWidth] = useState(336);

  const dropdownHeight = !showDropdownColorPicker ? 176 : 183;
  const dropdownSearchHeight = 56;
  const dropdownAddHeight = 34;

  // Handle width of dropdown menu
  useEffect(() => {
    if (addButtonRef?.current) {
      const buttonWidth = addButtonRef?.current?.offsetWidth;
      buttonWidth === 0 ? setDropdownWidth(336) : setDropdownWidth(buttonWidth);
    }
  }, []);

  // Handle click away or scroll to close
  useEffect(() => {
    function handleClickOrScrollOutside(event: any) {
      if (dropdownRef?.current) {
        const temp = dropdownRef as any;
        if (
          !temp?.current?.contains(event.target) &&
          !addButtonRef?.current?.contains(event.target) &&
          !smallAddButtonRef?.current?.contains(event.target)
        ) {
          setShowDropdown(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOrScrollOutside);
    document.addEventListener("wheel", handleClickOrScrollOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOrScrollOutside);
      document.removeEventListener("wheel", handleClickOrScrollOutside);
    };
  }, []);

  // ---------------------------------------------- Renders ---------------------------------------------------------
  const renderTags = () => {
    return (
      <div
        className={`flex flex-wrap content-start
          overflow-y-auto gap-x-1
          ${(isLoading || disabled) && "bg-paletteGray-3"}`}
      >
        {_.map(selectedItems, (tag) => renderTag(tag, showClose))}
      </div>
    );
  };

  const renderCompactModeContent = () => {
    return (
      <div
        ref={smallAddButtonRef}
        className="relative flex gap-2 items-center
                 bg-transparent text-black  cursor-pointer py-2"
      >
        <span>Tags</span>
        <div
          className="flex justify-center w-fit pl-1 pr-1 h-[20px] text-[13px] 
                      text-white bg-black rounded-[4px] cursor-pointer"
        >
          {`${_.size(selectedItems)}`}
        </div>
        <div
          id="tags-selector-add-button"
          className="absolute top-0 right-6 p-2 rounded-[4px] cursor-pointer"
          onClick={() => {
            handleOpenDropdown(smallAddButtonRef);
          }}
        >
          {!disabled && <PlusIcon width="20px" height="20px" />}
        </div>
        <div className="absolute inset-y-0 right-0 pt-3 pb-2 pl-2 pr-1">
          {isExpandedLocal ? (
            <Collapse
              width="16px"
              height="16px"
              onClick={() => setIsExpandedLocal(false)}
            />
          ) : (
            <Expand
              width="16px"
              height="16px"
              onClick={() => setIsExpandedLocal(true)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderAddButton = () => {
    return (
      <div ref={addButtonRef} className="w-full h-[28] flex items-center">
        <div
          id="tags-selector-add-button"
          className="w-full py-2 pl-4 space-x-2 text-sm bg-paletteGray-3 hover:bg-paletteGray-5
              rounded-[8px] inline-flex items-center cursor-pointer"
          data-test="select_tags_dropdown_button"
          onClick={() => {
            handleOpenDropdown(addButtonRef);
          }}
        >
          <PlusIcon width="16px" height="16px" />
          <span>{addLabel ? addLabel : "Add a tag"}</span>
        </div>
      </div>
    );
  };

  const renderDropdown = () => {
    // Filter tags that are already assigned
    const selectedItemsKeys = _.keys(selectedItems);
    let unselectedItems: { [key: string]: DatasetModel | TagModel } = {};
    _.map(items, (tag, key) => {
      if (!_.includes(selectedItemsKeys, key)) {
        unselectedItems = {
          ...unselectedItems,
          [key]: tag,
        };
      }
    });

    // Filter tags based on the search input
    let filteredItems: { [key: string]: DatasetModel | TagModel } = {};
    if (_.size(searchValue) > 0) {
      _.map(unselectedItems, (tag, key) => {
        if (_.includes(_.toLower(tag.name), _.toLower(searchValue))) {
          filteredItems = { ...filteredItems, [key]: tag };
        }
      });
    } else {
      filteredItems = { ...unselectedItems };
    }

    return (
      <>
        {renderSearchInput()}
        <div
          className={`pt-4 pb-2 px-4 flex flex-wrap content-start gap-x-1 overflow-y-scroll
            ${isLoading || (disabled && "bg-paletteGray-3")}`}
          data-test="tag_selector_area"
          style={{
            height:
              !_.isUndefined(enableCreateNewTag) && _.size(searchValue) > 0
                ? `${
                    dropdownHeight -
                    dropdownSearchHeight -
                    dropdownAddHeight -
                    2
                  }px`
                : `${dropdownHeight - dropdownSearchHeight - 2}px`,
          }}
        >
          {_.map(filteredItems, (tag: DatasetModel, key) => {
            return (
              <div
                key={key}
                className="cursor-pointer"
                onClick={() => !isLoading && !disabled && handleAddTag(tag)}
              >
                {renderTag(tag)}
              </div>
            );
          })}
        </div>
        {!_.isUndefined(enableCreateNewTag) && _.size(searchValue) > 0 && (
          <div
            className="px-4 text-sm flex flex-wrap items-center"
            style={{
              height: `${dropdownAddHeight}px`,
            }}
          >
            <div className="flex items-center gap-x-2 text-black">
              <PlusIcon
                width="12px"
                height="12px"
                className={`${isLoading ? "animate-spin" : "cursor-pointer"}`}
                onClick={() => !isLoading && handleAddNewTag()}
              />
              <div>Create new tag</div>
            </div>
            <div className="text-black font-medium pl-2">
              {_.truncate(searchValue, { length: 12 })}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <div className="relative">
        <input
          autoFocus
          className={`w-full h-[${dropdownSearchHeight}px] px-4 py-[18px] text-[15px] 
                  focus:ring-0 focus:border-transparent outline-none focus:outline-none
                  bg-white disabled:bg-paletteGray-3 border-b-[1px] border-gray1 rounded-t-[8px]`}
          data-test="tags_search_input"
          value={searchValue}
          onChange={(event) => setSearchValue(event?.target?.value)}
          disabled={isLoading}
          placeholder={placeholder || "Search"}
        />
        <div
          className="absolute flex justify-center items-center h-[24px] w-[24px] rounded-[4px] pl-1 pr-1 
                  top-4 right-4 bg-paletteGray-3 text-gray4 cursor-pointer"
          onClick={() => setShowDropdown(false)}
        >
          {<Close width="12px" height="12px" />}
        </div>
      </div>
    );
  };

  const renderDropdownColorPicker = () => {
    return (
      <div className="px-7 py-3 flex flex-wrap justify-center gap-x-3 gap-y-2">
        {_.map(tagsColors, (color, key) => {
          return (
            <button
              key={key}
              className="w-[24px] h-[24px] rounded-[4px] cursor-pointer
                 hover:border-2 active:border-4"
              style={{ backgroundColor: color }}
              onClick={() => handleChangeColor(color)}
            />
          );
        })}
      </div>
    );
  };

  // Sub-components ------------------------------

  const renderTag = (
    tag: DatasetModel | TagModel,
    showClose?: string,
    truncateLength: number = 26,
  ) => {
    const label = _.truncate(tag?.name, { length: truncateLength });
    const count = _.isNumber(tag?.count) ? `(${tag?.count})` : "";

    return (
      <div
        key={tag?.id}
        className="text-sm relative flex items-center h-[24px] rounded-[8px] pl-1 pr-3 mb-2 bg-paletteGray-3"
        onMouseEnter={() => setShowClose(label)}
        onMouseLeave={() => setShowClose("")}
      >
        <div>{renderColoredBox(tag?.color)}</div>
        <div className="pl-2">
          {_.size(label) >= truncateLength ? (
            <Tooltip title={`${tag?.name} ${count}`} arrow>
              <div>{`${label} ${count}`}</div>
            </Tooltip>
          ) : (
            `${label} ${count}`
          )}
        </div>
        {showClose === label && (
          <div
            className="absolute inset-y-0 right-0 flex justify-center items-center h-[24px] w-[24px] 
                    rounded-[8px] pl-1 pr-1 bg-paletteGray-3 text-paletteGray-10 cursor-pointer"
            onClick={() => !isLoading && !disabled && handleRemoveTag(tag)}
          >
            {_.size(tag?.name) > 0 && <Close width="8px" height="8px" />}
          </div>
        )}
      </div>
    );
  };

  const renderColoredBox = (color: string, border?: boolean) => {
    return (
      <div
        className={`w-[16px] h-[16px] rounded-[4px] ${
          border && "border-r-[1px]"
        }`}
        style={{
          backgroundColor: color,
        }}
      />
    );
  };

  // Functions ----------------------------------------

  const handleOpenDropdown = (clickedRef: React.RefObject<HTMLDivElement>) => {
    if (!disabled) {
      if (!showDropdown) {
        if (clickedRef.current) {
          const newPosition = clickedRef?.current?.getBoundingClientRect();
          const left = newPosition?.left;
          setShowDropdown(true);

          // Render dropdown under select
          if (
            newPosition?.top + newPosition?.height + dropdownHeight <
            screenSize?.height
          ) {
            setDropdownPosition({
              top: newPosition?.top + newPosition?.height,
              left: left,
              position: "down",
            });
          }
          // Render dropdown above select
          else {
            setDropdownPosition({
              top: newPosition?.top - 16 - dropdownHeight,
              left: left,
              position: "up",
            });
          }
        }
      } else setShowDropdown(false);
    }
  };

  const handleAddNewTag = () => {
    if (
      !_.isUndefined(enableCreateNewTag) &&
      !_.isUndefined(enableCreateNewTag?.type) &&
      !_.isUndefined(enableCreateNewTag?.datasetID)
    ) {
      // Tags as subsets (for attributes)
      putCreateTag(
        enableCreateNewTag?.datasetID,
        searchValue,
        enableCreateNewTag.type,
      ).then((response) => {
        setSearchValue("");
        setNewlyCreatedTagID(response.data);
        setShowDropdownColorPicker(true);
        if (enableCreateNewTag?.onAddNewItemSuccess) {
          enableCreateNewTag?.onAddNewItemSuccess(response.data);
        }
      });
    }
  };

  const handleChangeColor = (color: string) => {
    if (newlyCreatedTagID && enableCreateNewTag) {
      postUpdateTag(
        {
          datasetID: enableCreateNewTag?.datasetID,
          tagID: newlyCreatedTagID,
          color: color,
        },
        dispatch,
      )
        .then(() => {
          setNewlyCreatedTagID(null);
          setShowDropdownColorPicker(false);
          dispatch(
            fetchTags({
              query: {
                parentDataSetID: enableCreateNewTag?.datasetID,
              },
            }),
          );
        })
        .catch(() => {
          setNewlyCreatedTagID(null);
          setShowDropdownColorPicker(false);
        });
    }
  };

  // Render the main body
  const renderBody = () => {
    // Render compact mode
    if (compactMode) {
      return (
        <div>
          {_.size(selectedItems) > 0
            ? renderCompactModeContent()
            : renderAddButton()}
          {isExpandedLocal && renderTags()}
        </div>
      );
    }

    // Render normal mode
    return (
      <div>
        {renderTags()}
        {renderAddButton()}
      </div>
    );
  };

  // -------------------------------------------- Component Render -------------------------------------------------
  return (
    <div className="w-full">
      {/* Main body */}
      {renderBody()}

      {/* Dropdown */}
      {showDropdown && (
        <div
          id="dropdown-tags-selector"
          ref={dropdownRef}
          className="fixed bg-white shadow-[0_0px_20px_rgba(0,0,0,0.2)] rounded-[8px] mt-2 mb-4"
          style={{
            width: `${dropdownWidth}px`,
            height: `${dropdownHeight}px`,
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            zIndex: 999,
          }}
        >
          {!showDropdownColorPicker
            ? renderDropdown()
            : renderDropdownColorPicker()}
        </div>
      )}
    </div>
  );
};

export default TagsSelector;
