import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import {
  TrainingSetAttribute,
  TrainingSetModel,
} from "models/trainingSet.model";
import { fetchMLAnnotationModels } from "helpers/apis/mlAnnotationModel";
import {
  fetchTrainingSets,
  getTrainingSetSubsetQuery,
} from "helpers/apis/trainingSet";
import { TextFieldModel } from "models/form.model";
import newTrainingSetForm from "Pages/AIManager/TrainingSetManager/CreateNewTrainingSetDialog/NewTrainingSetForm";
import { DatasetModel } from "models/dataset.model";
import _ from "lodash";
import newAIModelForm from "Pages/AIManager/AIModelManager/CreateNewAIModelDialog/NewAIModelForm";
import { AIAnnotationRunModel } from "models/aiAnnotationRun.model";
import { fetchAIAnnotationRuns } from "helpers/apis/aiAnnotationRun";
import newAIAnnotationRunForm from "Pages/AIManager/AIAnnotationRunManager/CreateNewAIAnnotationRunDialog/NewAIAnnotationRunForm";

export const fetchAIModelsStore = createAsyncThunk(
  "aiManager/fetchAIModelsStore",
  async () => {
    const response = await fetchMLAnnotationModels();
    return { data: response };
  },
);

export const fetchTrainingSetsStore = createAsyncThunk(
  "aiManager/fetchTrainingSetsStore",
  async () => {
    const response = await fetchTrainingSets();
    return { data: response };
  },
);

export const fetchAIAnnotationRunsStore = createAsyncThunk(
  "aiManager/fetchAIAnnotationRunsStore",
  async () => {
    const response = await fetchAIAnnotationRuns();
    return { data: response };
  },
);

export enum AIMangerViewEnum {
  TrainingSets = "Training sets",
  AIModels = "AI models",
  AIAnnotationRuns = "AI annotation runs",
}

export interface aiManagerSliceStateTypes {
  view: AIMangerViewEnum;
  // AI Models
  aiModels: MLAnnotationModel[] | null;
  isCreateNewAIModelModalOpen: boolean;
  newAIModel: {
    name: TextFieldModel;
    training_set: TrainingSetModel | null;
  };
  loadingAIModels: boolean;
  // Training Sets
  trainingSets: TrainingSetModel[] | null;
  isCreateNewTrainingSetModalOpen: boolean;
  newTrainingSet: {
    name: TextFieldModel;
    dataset: DatasetModel | null;
    training_attributes: TrainingSetAttribute[];
  };
  loadingTrainingSets: boolean;
  // AI Annotation Runs
  aiAnnotationRuns: AIAnnotationRunModel[] | null;
  loadingAIAnnotationRuns: boolean;
  isCreateNewAIAnnotationRunModalOpen: boolean;
  newAIAnnotationRun: {
    name: TextFieldModel;
    dataset: DatasetModel | null;
    subset: DatasetModel | null;
    ml_annotation_model: MLAnnotationModel | null;
  };
  // Misc
  searchValue: string;
  error: { message: string };
}

const initialState = {
  view: AIMangerViewEnum.TrainingSets,
  // AI Models
  aiModels: null,
  isCreateNewAIModelModalOpen: false,
  newAIModel: {
    name: newAIModelForm.name,
    training_set: null,
  },
  loadingAIModels: false,
  // Training Sets
  trainingSets: null,
  isCreateNewTrainingSetModalOpen: false,
  loadingTrainingSets: false,
  newTrainingSet: {
    name: newTrainingSetForm.name,
    dataset: null,
    training_attributes: [],
  },
  // AI Annotation Runs
  aiAnnotationRuns: null,
  isCreateNewAIAnnotationRunModalOpen: false,
  newAIAnnotationRun: {
    name: newAIAnnotationRunForm.name,
    dataset: null,
    subset: null,
    ml_annotation_model: null,
  },
  loadingAIAnnotationRuns: false,
  // Misc
  searchValue: "",
  error: { message: "" },
} as aiManagerSliceStateTypes;

export const aiManagerSlice = createSlice({
  name: "aiManager",
  initialState,
  reducers: {
    setAIMangerView: (state, action: PayloadAction<AIMangerViewEnum>) => {
      state.searchValue = "";
      state.view = action?.payload;
    },
    setAIModels: (state, action: PayloadAction<MLAnnotationModel[]>) => {
      state.aiModels = action?.payload;
    },
    setTrainingSets: (state, action: PayloadAction<TrainingSetModel[]>) => {
      state.trainingSets = action?.payload;
    },

    // AI Models creation
    setIsCreatingNewAIModelModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isCreateNewAIModelModalOpen = action?.payload;
    },
    setNewAIModelName: (state, action: PayloadAction<string>) => {
      state.newAIModel.name.value = action?.payload;
    },
    setNewAIModelTrainingSet: (
      state,
      action: PayloadAction<TrainingSetModel | null>,
    ) => {
      state.newAIModel.training_set = action?.payload;
    },

    // Training Set creation
    setIsCreatingNewTrainingSetModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isCreateNewTrainingSetModalOpen = action?.payload;
    },
    setNewTrainingSetName: (state, action: PayloadAction<string>) => {
      state.newTrainingSet.name.value = action?.payload;
    },
    setNewTrainingSetDataset: (
      state,
      action: PayloadAction<DatasetModel | null>,
    ) => {
      state.newTrainingSet.dataset = action?.payload;
    },
    setNewTrainingSetTrainingAttributes: (
      state,
      action: PayloadAction<TrainingSetAttribute[]>,
    ) => {
      state.newTrainingSet.training_attributes = action?.payload;
    },
    addNewTrainingSetTrainingAttribute: (
      state,
      action: PayloadAction<TrainingSetAttribute>,
    ) => {
      state.newTrainingSet.training_attributes = [
        ...state.newTrainingSet.training_attributes,
        action?.payload,
      ];
    },
    removeNewTrainingSetTrainingAttribute: (
      state,
      action: PayloadAction<TrainingSetAttribute>,
    ) => {
      state.newTrainingSet.training_attributes =
        state.newTrainingSet.training_attributes.filter(
          (attribute) =>
            attribute.attribute_id !== action?.payload.attribute_id,
        );
    },
    addNewTrainingSetTrainingAttributeSubset: (
      state,
      action: PayloadAction<{ attributeID: string; subset: DatasetModel }>,
    ) => {
      const attributeIndex = state.newTrainingSet.training_attributes.findIndex(
        (attribute) => attribute.attribute_id === action?.payload.attributeID,
      );

      if (attributeIndex === -1) return;

      const attribute =
        state.newTrainingSet.training_attributes[attributeIndex];

      const subsetIDsQuery = getTrainingSetSubsetQuery(attribute);
      const subsetIDs = subsetIDsQuery?.value as string[];

      if (_.isUndefined(subsetIDs)) {
        state.newTrainingSet.training_attributes[attributeIndex].query = [
          {
            attribute: "subset_ids",
            query_operator: "in",
            value: [action?.payload.subset.id],
          },
        ];
      } else {
        state.newTrainingSet.training_attributes[attributeIndex].query = [
          {
            attribute: "subset_ids",
            query_operator: "in",
            value: [...subsetIDs, action?.payload.subset.id],
          },
        ];
      }
    },
    removeNewTrainingSetTrainingAttributeSubset: (
      state,
      action: PayloadAction<{ attributeID: string; subsetID: string }>,
    ) => {
      const attributeIndex = state.newTrainingSet.training_attributes.findIndex(
        (attribute) => attribute.attribute_id === action?.payload.attributeID,
      );

      if (attributeIndex === -1) return;

      const attribute =
        state.newTrainingSet.training_attributes[attributeIndex];

      const subsetIDsQuery = getTrainingSetSubsetQuery(attribute);
      const subsetIDs = subsetIDsQuery?.value as string[];

      if (_.isUndefined(subsetIDs)) return;

      state.newTrainingSet.training_attributes[attributeIndex].query = [
        {
          attribute: "subset_ids",
          query_operator: "in",
          value: subsetIDs.filter(
            (subsetID) => subsetID !== action?.payload.subsetID,
          ),
        },
      ];
    },

    // AI Annotation Runs
    setAIAnnotationRuns: (
      state,
      action: PayloadAction<AIAnnotationRunModel[]>,
    ) => {
      state.aiAnnotationRuns = action?.payload;
    },
    setIsCreatingNewAIAnnotationRunModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isCreateNewAIAnnotationRunModalOpen = action?.payload;
    },
    setNewAIAnnotationRunName: (state, action: PayloadAction<string>) => {
      state.newAIAnnotationRun.name.value = action?.payload;
    },
    setNewAIAnnotationRunDatasetAndSubset: (
      state,
      action: PayloadAction<{ dataset: DatasetModel; subset: DatasetModel }>,
    ) => {
      state.newAIAnnotationRun.dataset = action?.payload.dataset;
      state.newAIAnnotationRun.subset = action?.payload.subset;
    },
    setNewAIAnnotationRunMLAnnotationModel: (
      state,
      action: PayloadAction<MLAnnotationModel>,
    ) => {
      state.newAIAnnotationRun.ml_annotation_model = action?.payload;
    },

    // Misc
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action?.payload;
    },
    resetNewAIModel: (state) => {
      state.newAIModel = initialState.newAIModel;
    },
    resetNewTrainingSet: (state) => {
      state.newTrainingSet = initialState.newTrainingSet;
    },
    resetNewTrainingSetDataset: (state) => {
      state.newTrainingSet.dataset = null;
      state.newTrainingSet.training_attributes = [];
    },
    resetNewAIAnnotationRun: (state) => {
      state.newAIAnnotationRun = initialState.newAIAnnotationRun;
    },
    resetAIManagerSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchAIModelsStore reducer
    builder.addCase(
      fetchAIModelsStore.pending,
      (state: aiManagerSliceStateTypes) => {
        state.loadingAIModels = true;
      },
    );
    builder.addCase(
      fetchAIModelsStore.fulfilled,
      (state: aiManagerSliceStateTypes, action) => {
        if (action?.payload?.data) {
          state.aiModels = action.payload.data;
        }
        state.loadingAIModels = false;
      },
    );
    builder.addCase(
      fetchAIModelsStore.rejected,
      (state: aiManagerSliceStateTypes, action) => {
        state.loadingAIModels = false;
        state.error.message =
          action.error.message || "Error loading AI models!";
      },
    );

    // fetchTrainingSetsStore reducer
    builder.addCase(
      fetchTrainingSetsStore.pending,
      (state: aiManagerSliceStateTypes) => {
        state.loadingTrainingSets = true;
      },
    );
    builder.addCase(
      fetchTrainingSetsStore.fulfilled,
      (state: aiManagerSliceStateTypes, action) => {
        if (action?.payload?.data) {
          state.trainingSets = action.payload.data;
        }
        state.loadingTrainingSets = false;
      },
    );
    builder.addCase(
      fetchTrainingSetsStore.rejected,
      (state: aiManagerSliceStateTypes, action) => {
        state.loadingTrainingSets = false;
        state.error.message =
          action.error.message || "Error loading training sets!";
      },
    );

    // fetchAIAnnotationRunsStore reducer
    builder.addCase(
      fetchAIAnnotationRunsStore.pending,
      (state: aiManagerSliceStateTypes) => {
        state.loadingAIAnnotationRuns = true;
      },
    );
    builder.addCase(
      fetchAIAnnotationRunsStore.fulfilled,
      (state: aiManagerSliceStateTypes, action) => {
        if (action?.payload?.data) {
          state.aiAnnotationRuns = action.payload.data;
        }
        state.loadingAIAnnotationRuns = false;
      },
    );
    builder.addCase(
      fetchAIAnnotationRunsStore.rejected,
      (state: aiManagerSliceStateTypes, action) => {
        state.loadingAIAnnotationRuns = false;
        state.error.message =
          action.error.message || "Error loading AI annotation runs!";
      },
    );
  },
});

export const {
  setAIMangerView,
  setAIModels,
  setTrainingSets,
  setIsCreatingNewAIModelModalOpen,
  setNewAIModelName,
  setNewAIModelTrainingSet,
  setIsCreatingNewTrainingSetModalOpen,
  setNewTrainingSetName,
  setNewTrainingSetDataset,
  setNewTrainingSetTrainingAttributes,
  addNewTrainingSetTrainingAttribute,
  removeNewTrainingSetTrainingAttribute,
  addNewTrainingSetTrainingAttributeSubset,
  removeNewTrainingSetTrainingAttributeSubset,
  setAIAnnotationRuns,
  setIsCreatingNewAIAnnotationRunModalOpen,
  setNewAIAnnotationRunName,
  setNewAIAnnotationRunDatasetAndSubset,
  setNewAIAnnotationRunMLAnnotationModel,
  setSearchValue,
  resetNewAIModel,
  resetNewTrainingSet,
  resetNewTrainingSetDataset,
  resetNewAIAnnotationRun,
  resetAIManagerSlice,
} = aiManagerSlice.actions;
export default aiManagerSlice.reducer;
