import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { getDataset } from "helpers/apis/datasets";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import { AttributeMetadataModel } from "models/attributes.model";
import { DatasetModel } from "models/dataset.model";
import { TrainingSetModel } from "models/trainingSet.model";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";

export const useTrainingSetOverview = (
  trainingSet: TrainingSetModel | null,
) => {
  const dispatch = useAppDispatch();

  const [
    selectedTrainingSetAttributesMetaData,
    setSelectedTrainingSetAttributesMetaData,
  ] = useState<AttributeMetadataModel[]>([]);
  const [selectedTrainingSetDataset, setSelectedTrainingSetDataset] =
    useState<DatasetModel | null>(null);
  const [selectedTrainingSetSubsets, setSelectedTrainingSetSubsets] = useState<
    DatasetModel[] | null
  >(null);

  useEffect(() => {
    if (trainingSet === null) return;

    fetchAttributesMetaData(
      {
        datasetID: trainingSet.training_attributes[0].dataset_id,
      },
      dispatch,
    ).then((data) => {
      setSelectedTrainingSetAttributesMetaData(data);
    });

    getDataset(
      { datasetID: trainingSet.training_attributes[0].dataset_id },
      dispatch,
    ).then((data) => {
      setSelectedTrainingSetDataset(data);
    });

    getSubsetsForDataset(
      trainingSet.training_attributes[0].dataset_id,
      dispatch,
    ).then((data) => {
      setSelectedTrainingSetSubsets(data);
    });
  }, [trainingSet]);

  return {
    selectedTrainingSetAttributesMetaData,
    selectedTrainingSetDataset,
    selectedTrainingSetSubsets,
  };
};

export default useTrainingSetOverview;
