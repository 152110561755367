import ActionsBar from "components/DetailsScreen/ActionBar";
import { InstanceDetailsScreenRouteModel } from "models/routes.model";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { InstanceModel } from "models/exploration.model";
import _ from "lodash";
import { AnnotatableEnum } from "models/global.model";
import HermesMediaViewer from "components/DetailsScreen/HermesMediaViewer";
import InfoPanel from "components/UtilComponents/InfoPanel";
import { fetchTags } from "store/datasetSlice";

import isURLStillValid from "helpers/functions/isURLStillValid";
import {
  fetchActiveDataset,
  fetchDatasetSliceData,
} from "helpers/functions/datasets/datasetHelpers";

import {
  fetchInstancesDataScroll,
  fetchOneInstanceAPI,
} from "helpers/apis/instances";
import { setInstanceExplorationCurrentInstance } from "store/explorationInstancesSlice";
import selectInstanceURL from "helpers/functions/hermes2dPainter/selectInstanceURL";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

const InstanceDetailsScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params: InstanceDetailsScreenRouteModel = useParams();

  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const explorationInstanceData = useAppSelector(
    (state) => state.explorationInstanceSlice,
  );
  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.instancesCount?.total_count,
  );
  const instanceFilterData = useAppSelector(
    (state) => state.filterDataSlice.activeInstanceFilter,
  );
  const instanceSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeInstances,
  );
  const selectedVisualization = useAppSelector(
    (state) => state.detailsScreenSlice.selectedVisualization.Instance,
  );

  const tags = useAppSelector((state) => state.datasetSlice?.tags);

  const [currentInstance, setCurrentInstance] = useState<InstanceModel>();
  const [currentInstanceIndex, setCurrentInstanceIndex] = useState(
    _.findIndex(
      explorationInstanceData?.data,
      (media) => media?.id === explorationInstanceData.currentInstance?.id,
    ) || 0,
  );

  const [hoveredBBID, setHoveredBBID] = useState("");
  const [showLabel, setShowLabel] = useState(true);

  useEffect(() => {
    fetchActiveDataset(dispatch, {
      datasetId: params?.id,
      subset_id: params?.subset_id,
    });
  }, [params?.id, params?.subset_id]);

  useEffect(() => {
    fetchDatasetSliceData(dispatch, dataSetData);
  }, [dataSetData?.activeDataSet]);

  // Set the new media when currentMedia change
  // (when assign/unassign a tag)
  useEffect(() => {
    if (explorationInstanceData.currentInstance) {
      setCurrentInstance(explorationInstanceData.currentInstance);
    }
  }, [explorationInstanceData.currentInstance]);

  //Initialize tags
  useEffect(() => {
    if (_.isEmpty(tags))
      dispatch(fetchTags({ query: { parentDataSetID: params?.id } }));
  }, []);

  // Fetch instance if no currentInstance.
  useEffect(() => {
    setCurrentInstance(undefined);

    if (!_.isNull(explorationInstanceData.currentInstance)) {
      setCurrentInstance(explorationInstanceData.currentInstance);
    } else {
      fetchInstanceByID();
    }
  }, [params.id, params.instance_id]);

  const fetchInstanceByID = () => {
    fetchOneInstanceAPI(params?.instance_id, params?.id)
      .then((response) => {
        setCurrentInstance(response);
      })
      .catch((error) =>
        snackbarHelper(error?.detail || "Instance fetching failed!", "error"),
      );
  };

  // Update when currentInstanceIndex change.
  // Push a new url with the the next instance name.
  // Check if the is the last index, if so fetch the next batch of instances.
  useEffect(() => {
    if (
      // If the current instance is found in the list of instances
      // (when we refresh the page we don't want to fetch instances)
      !_.isUndefined(currentInstanceIndex) &&
      currentInstanceIndex !== -1 &&
      // If the current instance is the last instance in the list
      currentInstanceIndex >= explorationInstanceData.data?.length - 1
    ) {
      fetchInstancesDataScroll(
        dispatch,
        explorationInstanceData,
        totalCount,
        params?.id,
        params?.subset_id,
        instanceFilterData,
        instanceSortData,
      );
    }
    const newInstance = explorationInstanceData.data?.[currentInstanceIndex];
    if (newInstance?.id) {
      dispatch(setInstanceExplorationCurrentInstance(newInstance));
      history.push(
        `/main/${params?.id}/${params?.subset_id}/instance/${newInstance?.id}`,
      );
    }
  }, [currentInstanceIndex]);

  useEffect(() => {
    if (!currentInstance) return;
    const url = selectInstanceURL(
      currentInstance,
      dataSetData.VisualisationConfiguration,
      dataSetData?.activeDataSet?.id,
      selectedVisualization,
    );
    if (url === undefined) {
      return;
    }

    if (isURLStillValid(url)) {
      return;
    } else {
      fetchInstanceByID();
    }
  }, [params?.instance_id]);

  const renderViewer = () => {
    if (!currentInstance) return;

    const url = selectInstanceURL(
      currentInstance,
      dataSetData.VisualisationConfiguration,
      dataSetData?.activeDataSet?.id,
      selectedVisualization,
    );
    if (url === undefined) {
      return (
        <div className="w-full h-full flex justify-center items-center text-xl">
          No visualisations available!
        </div>
      );
    }

    return (
      <HermesMediaViewer
        media_url={url}
        mediaObjectGeometries={[]}
        allGeometries={[]}
        baseShapeGeometries={[]}
        hoveredBBID={hoveredBBID}
        setHoveredBBID={setHoveredBBID}
        setShowLabel={setShowLabel}
      />
    );
  };

  if (!currentInstance) return <div>Loading...</div>;

  return (
    <div className="w-full h-full pt-3 flex bg-white">
      {/* Main body */}
      <div className="h-full min-w-0 flex-1 flex flex-col">
        {/* Top action bar */}
        <ActionsBar
          currentItemIndex={currentInstanceIndex}
          setCurrentItemIndex={setCurrentInstanceIndex}
          currentItem={currentInstance}
        />

        {/* Media viewer */}
        <div className="w-full min-h-0 flex-1">{renderViewer()}</div>
      </div>

      {/* Info Panel */}
      <InfoPanel
        showMediaOrMediaObject={AnnotatableEnum.Instance}
        currentInstance={currentInstance}
        currentMedia={undefined}
        currentMediaObject={undefined}
      />
    </div>
  );
};

export default InstanceDetailsScreen;
